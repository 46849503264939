import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const IbmplexsansMediumTuna16px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumPaleSky16px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansNormalTuna14px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansNormalTuna16px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansSemiBoldTuna16px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansMediumWhite16px = css`
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumPaleSky14px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansSemiBoldWhite16px = css`
  color: var(--domtruewhite);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansNormalPaleSky16px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansSemiBoldTuna12px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xs);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansBoldTuna24px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const IbmplexsansNormalPaleSky14px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansMediumTuna24px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xxl);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumPaleSky12px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumPaleSky24px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xxl);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansBoldTuna34px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const IbmplexsansSemiBoldPaleSky16px = css`
  color: var(--contentsecondary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansSemiBoldTuna20px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansMediumLoblolly16px = css`
  color: var(--contentlight);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumTuna14px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansSemiBoldAlabaster16px = css`
  color: var(--dommain);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansSemiBoldLoblolly16px = css`
  color: var(--contentlight);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansSemiBoldTuna14px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const IbmplexsansMediumWhite14px = css`
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumCuriousBlue14px = css`
  color: var(--accentblue);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansNormalCuriousBlue16px = css`
  color: var(--accentblue);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansNormalAlabaster12px = css`
  color: var(--dommain);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansNormalTuna12px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansMediumTuna20px = css`
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansNormalWhite16px = css`
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const IbmplexsansMediumCuriousBlue16px = css`
  color: var(--accentblue);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumBlack16px = css`
  color: var(--black);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const IbmplexsansMediumRed14px = css`
  color: var(--accentred);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;
