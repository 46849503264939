import React from "react";
import Header from "../Header";


function TestCom(props) {
    const {headerContentProps1} = props
    return (

        <Header headerContentProps={headerContentProps1}/>

    )
}

export default TestCom