import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalTuna16px } from "../../styledMixins";


function Row(props) {
  const { text253, text254, text255, className } = props;

  return (
    <Row1 className={`row ${className || ""}`}>
      <X1 className="x1-9">
        <Text257 className="text-257">{text253}</Text257>
      </X1>
      <X1 className="x2-7">
        <Text258 className="text-258">{text254}</Text258>
      </X1>
      <X1 className="x3-6">
        <Text259 className="text-259">{text255}</Text259>
      </X1>
    </Row1>
  );
}

const Row1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  background-color: var(--domsecondary);
  border-radius: 8px 8px 0px 0px;
  border: 1px none;

  &.row.row-1 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-2 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-3 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-4 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-5 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-6 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-7 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-8 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-9 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-10 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-11 {
    background-color: unset;
    border-radius: unset;
  }

  &.row.row-12 {
    background-color: unset;
    border-radius: unset;
  }
`;

const X1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  border: 1px none;
`;

const Text257 = styled.div`
  ${IbmplexsansNormalTuna16px}
  width: 27px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text258 = styled.div`
  ${IbmplexsansNormalTuna16px}
  width: 299px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text259 = styled.div`
  ${IbmplexsansNormalTuna16px}
  width: 138px;
  margin-top: -1px;
  text-align: right;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Row;
