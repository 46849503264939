import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldTuna14px } from "../../styledMixins";


function Label2() {
  return (
    <Label>
      <TextLabel>Акция июль-декабрь 2022</TextLabel>
    </Label>
  );
}

const Label = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 4px 8px;
  background-color: var(--accentyellow);
  border-radius: 16px;
  border: 1px none;
`;

const TextLabel = styled.div`
  ${IbmplexsansSemiBoldTuna14px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default Label2;
