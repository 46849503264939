import React from "react";
import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client';
import "./globals.css"
import "./styleguide.css"
import './i18n';
import App from "./App";
import ThemeProvider from "react-bootstrap/ThemeProvider";


const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(

        <App tab="home"/>

);


//ReactDOM.render(<App />, document.getElementById("app"));