import React from "react";
import Button2 from "../Button2";
import styled from "styled-components";
import { IbmplexsansMediumTuna24px } from "../../styledMixins";


function CardBottom(props) {
  const { text8, buttonProps } = props;

  return (
    <CardBottom1>
      <Text385>{text8}</Text385>
      <Button2 className={buttonProps.className}>{buttonProps.children}</Button2>
    </CardBottom1>
  );
}

const CardBottom1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  padding: 12px 24px 24px;
  position: relative;
  background-color: var(--dommain);
  border-radius: 0px 0px 24px 24px;
  border: 1px none;
`;

const Text385 = styled.div`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

export default CardBottom;
