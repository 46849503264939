import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldTuna16px } from "../../styledMixins";


function Button6(props) {
  const { className } = props;

  return (
    <Button className={`button-46 ${className || ""}`}>
      <Button1 className="button-47">Все новости</Button1>
      <Icon24chevronRight1
        className="icon24chevron-right-1"
        src="/img/icon-24-chevron-right-1@2x.svg"
        alt="Icon/24/chevron-right 1"
      />
    </Button>
  );
}

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 12px 0px 12px 22px;
  border-radius: 36px;
  border: 1px none;
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Icon24chevronRight1 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Icon24chevronRight11 = styled.img`
  .button-46.button-48 & {
    margin-top: -3305px;
    margin-left: -17032px;
  }
`;

export default Button6;
