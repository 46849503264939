import {React, useState} from "react";
import Button7 from "../Button7";
import Button2 from "../Button2";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormRequest from "../FormRequest";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CTAButtons(props) {
    const {button7Props, button2Props} = props;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);


    return (
        <CTAButtons1>
            <a href="https://cabinet.sas-telecom.kz" target="_blank"><Button7>{button7Props.children}</Button7></a>
     <a href='#' onClick={handleShow}><Button2>{button2Props.children}</Button2></a>
            <FormRequest active={show} setActive={setShow}/>
            {/*<Modal show={show} onHide={handleClose} centered>*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>Modal heading</Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>*/}
            {/*    <Modal.Footer>*/}
            {/*        <Button variant="danger" onClick={handleClose}>*/}
            {/*            Save Changes*/}
            {/*        </Button>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}
        </CTAButtons1>
    );
}

const CTAButtons1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons6 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons8 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons10 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CTAButtons12 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

export default CTAButtons;
