import React from "react";
import Pagination22 from "../Pagination22";
import styled from "styled-components";
import { IbmplexsansNormalTuna16px } from "../../styledMixins";


function Feature(props) {
  const { text216, className } = props;

  return (
    <Feature1 className={`feature ${className || ""}`}>
      <Pagination22 />
      <Text158 className="text-158">{text216}</Text158>
    </Feature1>
  );
}

const Feature1 = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 0px;
  position: relative;
  align-self: stretch;
  border: 1px none;

  &.feature.feature-1 {
    //width: 552px;
    //height: 40px;
    align-self: unset;
  }
`;

const Text158 = styled.p`
  ${IbmplexsansNormalTuna16px}
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  //white-space: nowrap;
`;

export default Feature;
