import React from "react";
import Frame72 from "../Frame72";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky16px } from "../../styledMixins";


function Frame7(props) {
  const { text253, frame72Props } = props;

  return (
    <Frame71>
      <Text290>{text253}</Text290>
      <Frame72>{frame72Props.children}</Frame72>
    </Frame71>
  );
}

const Frame71 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text290 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  //width: 148px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Frame7;
