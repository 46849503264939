import React from "react";
import styled from "styled-components";
import {
  IbmplexsansNormalPaleSky16px,
  IbmplexsansMediumTuna16px,
  IbmplexsansMediumPaleSky16px,
} from "../../styledMixins";


function Frame8(props) {
  const { x0900 } = props;

  return (
    <Frame81>
      <Text295>График работы:</Text295>
      <Frame7>
        <Text296>пн-пт:</Text296>
        <X0900>{x0900}</X0900>
        <Text296>
          <span className="ibmplexsans-normal-pale-sky-16px">(обед в 13:00-14:00)</span>
          <span className="ibmplexsans-medium-pale-sky-16px"></span>
        </Text296>
      </Frame7>
      <Frame7>
        <Text296>сб:</Text296>
        <X0900>10:00-14:00</X0900>
      </Frame7>
      <Frame7>
        <Text296>вс:</Text296>
        <X0900>Выходной</X0900>
      </Frame7>
    </Frame81>
  );
}

const Frame81 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text295 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  width: 148px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Frame7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text296 = styled.div`
  ${IbmplexsansNormalPaleSky16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X0900 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Frame8;
