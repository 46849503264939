import React, {useState} from "react";
import Button2 from "../Button2";
import Slider from "../Slider";
import styled from "styled-components";
import {IbmplexsansBoldTuna34px, IbmplexsansNormalTuna16px} from "../../styledMixins";
import FormRequest from "../FormRequest";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from "react-i18next";
function MainBg(props) {
    const {className, buttonProps} = props;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const { t, i18n } = useTranslation();
    return (
        <MainBg1 className={`main_bg ${className || ""}`}>
            <Carousel controls={false} variant="dark">
                <Carousel.Item>
                    {/*<FlexRow className="flex-row-2">*/}
                    <FlexRow className="row">
                        <Frame14 className="frame-14">
                            <Text94 className="text-94">{t("slider.text1")}</Text94>
                            <X500wifi1 className="x">
                                {t("slider.text2")}
                            </X500wifi1>
                            <Button2>{buttonProps.children}</Button2>
                        </Frame14>

                        <X209458511 className="x20945851-1" src="/img/20945851-1@2x.png" alt="20945851 1"/>
                    </FlexRow>
                </Carousel.Item>
                {/*<Carousel.Item>*/}
                {/*    /!*<FlexRow className="flex-row-2">*!/*/}
                {/*        <FlexRow className="row">*/}
                {/*        <Frame14 className="frame-14">*/}
                {/*            <Text94 className="text-94">Хотите подключиться к <br/> оптоволоконной сети*/}
                {/*                Интернет?</Text94>*/}
                {/*            <X500wifi1 className="x">*/}
                {/*                Такой, каким он должен быть — скорость до 500 Мбит/с, <br/>*/}
                {/*                Wi-Fi ловит по всей квартире, а сеть готова к нагрузкам до 1 Гбит/с*/}
                {/*            </X500wifi1>*/}
                {/*            <Button2>{buttonProps.children}</Button2>*/}
                {/*        </Frame14>*/}

                {/*        <X209458511 className="x20945851-1" src="/img/slider2.svg" alt="slider2.svg"/>*/}
                {/*    </FlexRow>*/}
                {/*</Carousel.Item>*/}
                {/*<Carousel.Item>*/}
                {/*    /!*<FlexRow className="flex-row-2">*!/*/}
                {/*        <FlexRow className="row">*/}
                {/*        <Frame14 className="frame-14">*/}
                {/*            <Text94 className="text-94">Ваш новый Интернет за 5 000 тг</Text94>*/}
                {/*            <X500wifi1 className="x">*/}
                {/*                Такой, каким он должен быть — скорость до 500 Мбит/с, <br/>*/}
                {/*                Wi-Fi ловит по всей квартире, а сеть готова к нагрузкам до 1 Гбит/с*/}
                {/*            </X500wifi1>*/}
                {/*            <Button2>{buttonProps.children}</Button2>*/}
                {/*        </Frame14>*/}

                {/*        <X209458511 className="x20945851-1" src="/img/slider3.svg" alt="slider3.svg"/>*/}
                {/*    </FlexRow>*/}
                {/*</Carousel.Item>*/}
            </Carousel>
            {/*<Slider/>*/}
        </MainBg1>
    )
        ;
}

const MainBg1 = styled.div`
  //width: 1440px;
  //height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  align-items: center;
  gap: 32px;
  background-color: var(--domdop);
  border: 1px none;

  &.main_bg.main_bg-1 {
    position: absolute;
    top: 112px;
    left: 2px;
  }
`;

const FlexRow = styled.div`
  margin-top: 37px;
  display: flex;
  align-items: center;
  //min-width: 1152px;
  gap: 24px;
`;

const Frame14 = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 1px;
  flex-direction: column;
  //width: 564px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text94 = styled.p`
  $ {
    IbmplexsansBoldTuna34px
  }
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const X500wifi1 = styled.p`
  $ {
    IbmplexsansNormalTuna16px
  }
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  color: var(--contentprimary);
  font-family: var(--font-family-ibm_plex_sans);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-letter-spacing: 0;
  -moz-letter-spacing: 0;
  -ms-letter-spacing: 0;
  letter-spacing: 0;
  line-height: 20px;
`;

const X209458511 = styled.img`
  //width: 564px;
  height: 377px;
  object-fit: cover;
`;

export default MainBg;
