import React from "react";
import Frame29 from "../Frame29";
import styled from "styled-components";


function Frame32(props) {
  const { frame29Props } = props;

  return (
    <Frame321>
      <a target="_blank" href="https://wa.me/+77058838649"><Frame29 iconImg="/img/icon-32-bxl-whatsapp-1@2x.svg" /></a>
      <a href="https://taplink.cc/sastelecom" target="_blank"><Frame29 iconImg="/img/icon-32-bxl-telegram-1@2x.svg"/></a>
      <a href="https://instagram.com/sastelecom.kz/" target="_blank"><Frame29 iconImg="/img/icon-32-bxl-instagram@2x.svg" className={frame29Props.className} /></a>
    </Frame321>
  );
}

const Frame321 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame322 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame323 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame324 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame325 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame326 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame327 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame328 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame329 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame3210 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame3211 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Frame3212 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

export default Frame32;
