import React from "react";
import Footer3 from "../Footer3";
import Request2 from "../Request2";
import CheckConnection from "../CheckConnection";
import CardNews24 from "../CardNews24";
import Breadcrumbs2 from "../Breadcrumbs2";
import FooterMobile from "../FooterMobile";
import Language from "../Language";
import HeaderMobile from "../HeaderMobile";
import styled from "styled-components";
import {
  IbmplexsansMediumTuna14px,
  IbmplexsansMediumRed14px,
  IbmplexsansMediumBlack16px,
  IbmplexsansMediumPaleSky24px,
} from "../../styledMixins";
import "./Organisms.css";

function Organisms(props) {
  const {
    footerDesktop,
    text455,
    httpsWwwInstagra,
    popups,
    popup_Request1,
    popup_Request2,
    checkCoverage,
    news,
    services,
    breadcrumb,
    text454,
    footerMobile,
    sliders,
    communication,
    connectService,
    cardContent,
    language1,
    icon24Globe,
    text417,
    icon24Check12,
    language2,
    text418,
    headerDesktop,
    headerMobile,
    cards,
    footer3Props,
    request2Props,
    checkConnectionProps,
    cardNews24Props,
    breadcrumbs2Props,
    footerMobileProps,
    languageProps,
    headerMobileProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="organisms ibmplexsans-medium-pale-sky-24px screen">
        <FlexCol>
          <Footer>
            <FooterDesktop>{footerDesktop}</FooterDesktop>
            <Footer3
              text439={footer3Props.text439}
              text443={footer3Props.text443}
              text444={footer3Props.text444}
              text445={footer3Props.text445}
              frame3Props={footer3Props.frame3Props}
              frame32Props={footer3Props.frame32Props}
            />
          </Footer>
          <Frame58>
            <Text455>{text455}</Text455>
            <Httpswwwinstagra>{httpsWwwInstagra}</Httpswwwinstagra>
          </Frame58>
          <Popups>
            <FooterDesktop>{popups}</FooterDesktop>
            <Frame54>
              <PopupRequest src={popup_Request1} alt="Popup_Request" />
              <Request2
                text363={request2Props.text363}
                input31Props={request2Props.input31Props}
                input32Props={request2Props.input32Props}
                button4Props={request2Props.button4Props}
              />
            </Frame54>
            <Frame55>
              <PopupRequest src={popup_Request2} alt="Popup_Request" />
              <Frame57>
                <ChooseCity src="/img/choose-city@1x.svg" alt="Choose city" />
              </Frame57>
              <City src="/img/city@2x.svg" alt="City" />
            </Frame55>
          </Popups>
          <CheckCoverage>
            <FooterDesktop>{checkCoverage}</FooterDesktop>
            <CheckConnection {...checkConnectionProps} />
          </CheckCoverage>
          <NewsImages>
            <News>{news}</News>
            <ImagesNews src="/img/images-news@1x.svg" alt="Images_news" />
            <CardNews24
              cardNews221Props={cardNews24Props.cardNews221Props}
              cardNews222Props={cardNews24Props.cardNews222Props}
              cardNews223Props={cardNews24Props.cardNews223Props}
              cardNews224Props={cardNews24Props.cardNews224Props}
            />
          </NewsImages>
          <Services>
            <News>{services}</News>
            <Service src="/img/service@1x.svg" alt="Service" />
          </Services>
          <NewsImages>
            <News>{breadcrumb}</News>
            <Breadcrumbs2
              x1={breadcrumbs2Props.x1}
              x2={breadcrumbs2Props.x2}
              x3={breadcrumbs2Props.x3}
              className={breadcrumbs2Props.className}
            />
          </NewsImages>
          <Services>
            <News>{text454}</News>
            <Gramoty src="/img/gramoty@1x.svg" alt="Gramoty" />
          </Services>
        </FlexCol>
        <FlexRow>
          <FooterMobile1>
            <FooterDesktop>{footerMobile}</FooterDesktop>
            <FooterMobile
              button6Props={footerMobileProps.button6Props}
              button32Props={footerMobileProps.button32Props}
              frame32Props={footerMobileProps.frame32Props}
            />
          </FooterMobile1>
          <FlexCol1>
            <FlexRow1>
              <Frame57>
                <News>{sliders}</News>
                <Slider src="/img/slider@2x.svg" alt="Slider" />
              </Frame57>
              <Communication>
                <News>{communication}</News>
                <Chat src="/img/chat@2x.svg" alt="Chat" />
              </Communication>
            </FlexRow1>
            <Communication>
              <News>{connectService}</News>
              <Request src="/img/request@1x.svg" alt="Request" />
            </Communication>
          </FlexCol1>
          <CardContent>
            <News>{cardContent}</News>
            <Technology src="/img/technology@2x.svg" alt="Technology" />
            <Connection src="/img/connection@2x.svg" alt="Connection" />
          </CardContent>
        </FlexRow>
        <FlexRow2>
          <FlexCol2>
            <MenuName src="/img/menu-name@2x.svg" alt="Menu name" />
            <Language2>
              <Language className={languageProps.className} />
              <Language3>
                <Content>
                  <Language4>
                    <Icon24check1 src="/img/icon-24-check-1@2x.svg" alt="Icon/24/check 1" />
                    <Language1>{language1}</Language1>
                  </Language4>
                  <Language4>
                    <Icon24check1 src={icon24Globe} alt="Icon/24/globe" />
                    <Text417>{text417}</Text417>
                  </Language4>
                </Content>
              </Language3>
              <Language3>
                <Content>
                  <Language4>
                    <Icon24check1 src={icon24Check12} alt="Icon/24/check 1" />
                    <Text417>{language2}</Text417>
                  </Language4>
                  <Language4>
                    <Icon24check1 src="/img/icon-24-check-1@2x.svg" alt="Icon/24/check 1" />
                    <Language1>{text418}</Language1>
                  </Language4>
                </Content>
              </Language3>
            </Language2>
          </FlexCol2>
          <HeaderDesktop>
            <News>{headerDesktop}</News>
            <Header src="/img/header@1x.svg" alt="Header" />
          </HeaderDesktop>
          <HeaderMobile1>
            <News>{headerMobile}</News>
            <HeaderMobile sas_0606MailRu={headerMobileProps.sas_0606MailRu} />
          </HeaderMobile1>
        </FlexRow2>
        <Cards>
          <News>{cards}</News>
          <Card2 src="/img/card-2@1x.svg" alt="Card_2" />
        </Cards>
      </div>
    </div>
  );
}

const FlexCol = styled.div`
  position: absolute;
  width: 1614px;
  top: 662px;
  left: 159px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 4466px;
`;

const Footer = styled.div`
  display: flex;
  position: relative;
  align-self: flex-end;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const FooterDesktop = styled.div`
  ${IbmplexsansMediumPaleSky24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const News = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Frame58 = styled.div`
  ${IbmplexsansMediumBlack16px}
  display: flex;
  align-self: flex-end;
  margin-top: 11px;
  margin-right: 107px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 4px;
  border: 1px none;
`;

const Text455 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Httpswwwinstagra = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Language1 = styled.div`
  ${IbmplexsansMediumRed14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Text417 = styled.div`
  ${IbmplexsansMediumTuna14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Popups = styled.div`
  display: flex;
  margin-top: 35px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Frame54 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const PopupRequest = styled.img`
  min-width: 792px;
  height: 588px;
`;

const Frame55 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Frame57 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const ChooseCity = styled.img`
  min-width: 563px;
  height: 824px;
`;

const City = styled.img`
  min-width: 84px;
  height: 155px;
`;

const CheckCoverage = styled.div`
  display: flex;
  position: relative;
  margin-top: 143px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const NewsImages = styled.div`
  display: flex;
  position: relative;
  margin-top: 167px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const ImagesNews = styled.img`
  min-width: 1164px;
  height: 185px;
`;

const Services = styled.div`
  display: flex;
  margin-top: 167px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Service = styled.img`
  min-width: 739px;
  height: 208px;
`;

const Gramoty = styled.img`
  min-width: 1214px;
  height: 444px;
`;

const FlexRow = styled.div`
  position: absolute;
  height: 1065px;
  top: 121px;
  left: 2485px;
  display: flex;
  align-items: flex-start;
  min-width: 2035px;
`;

const FooterMobile1 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const FlexCol1 = styled.div`
  width: 713px;
  align-self: flex-end;
  margin-left: 396px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 1042px;
  gap: 71px;
`;

const FlexRow1 = styled.div`
  height: 291px;
  display: flex;
  align-items: flex-start;
  min-width: 627px;
  gap: 191px;
`;

const Slider = styled.img`
  min-width: 223px;
  height: 124px;
`;

const Communication = styled.div`
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Chat = styled.img`
  min-width: 213px;
  height: 234px;
`;

const Request = styled.img`
  min-width: 702px;
  height: 626px;
`;

const CardContent = styled.div`
  display: flex;
  margin-left: 169px;
  margin-top: 26px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Technology = styled.img`
  min-width: 329px;
  height: 108px;
`;

const Connection = styled.img`
  min-width: 329px;
  height: 340px;
`;

const FlexRow2 = styled.div`
  position: absolute;
  height: 474px;
  top: 129px;
  left: 111px;
  display: flex;
  align-items: flex-start;
  min-width: 2249px;
`;

const FlexCol2 = styled.div`
  width: 131px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 392px;
  gap: 81px;
`;

const MenuName = styled.img`
  width: 122px;
  height: 143px;
`;

const Language2 = styled.div`
  width: 131px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 168px;
  gap: 10px;
  border: 1px none;
`;

const Language3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 16px;
  background-color: var(--domdop);
  border-radius: 8px;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 4px;
  border: 1px none;
`;

const Language4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24check1 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const HeaderDesktop = styled.div`
  display: flex;
  align-self: flex-end;
  margin-left: 91px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Header = styled.img`
  min-width: 1480px;
  height: 416px;
`;

const HeaderMobile1 = styled.div`
  display: flex;
  position: relative;
  margin-left: 119px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  top: 1428px;
  left: 1841px;
  border: 1px none;
`;

const Card2 = styled.img`
  min-width: 3033px;
  height: 3402px;
`;

export default Organisms;
