import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import CardNews24 from "../CardNews24";
import Pagination from "../Pagination";
import Footer4 from "../Footer4";
import styled from "styled-components";
import { IbmplexsansBoldTuna34px } from "../../styledMixins";
import "./News.css";
import Container from "react-bootstrap/Container";

function News(props) {
  const {
    text338,
    headerProps,
    breadcrumbsProps,
    cardNews241Props,
    cardNews242Props,
    cardNews243Props,
    paginationProps,
    footer4Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="news screen">
        <Container><Header headerContentProps={headerProps.headerContentProps} /></Container>
        <Container><Breadcrumbs x3={breadcrumbsProps.x3} className={breadcrumbsProps.className} /></Container>
        <Container><Text338>{text338}</Text338></Container>
        <Container><News1>
          <CardNews24
            cardNews221Props={cardNews241Props.cardNews221Props}
            cardNews222Props={cardNews241Props.cardNews222Props}
            cardNews223Props={cardNews241Props.cardNews223Props}
            cardNews224Props={cardNews241Props.cardNews224Props}
          />
          <CardNews24
            cardNews221Props={cardNews242Props.cardNews221Props}
            cardNews222Props={cardNews242Props.cardNews222Props}
            cardNews223Props={cardNews242Props.cardNews223Props}
            cardNews224Props={cardNews242Props.cardNews224Props}
          />
          <CardNews24
            cardNews221Props={cardNews243Props.cardNews221Props}
            cardNews222Props={cardNews243Props.cardNews222Props}
            cardNews223Props={cardNews243Props.cardNews223Props}
            cardNews224Props={cardNews243Props.cardNews224Props}
          />
          <Pagination
            pagination31Props={paginationProps.pagination31Props}
            pagination31Props2={paginationProps.pagination31Props2}
            pagination32Props={paginationProps.pagination32Props}
            pagination33Props={paginationProps.pagination33Props}
            pagination34Props={paginationProps.pagination34Props}
            pagination2Props={paginationProps.pagination2Props}
          />
        </News1></Container>
        <Container><Footer4 button32Props={footer4Props.button32Props} button32Props2={footer4Props.button32Props2} /></Container>
      </div>
    </div>
  );
}

const Text338 = styled.div`
  ${IbmplexsansBoldTuna34px}
  margin-top: 35px;
  margin-left: 5%;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const News1 = styled.div`
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 27px;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 36px;
  border: 1px none;
`;

export default News;
