import React from "react";
import Frame17 from "../Frame17";
import CardBottom from "../CardBottom";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansMediumTuna20px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function CardOld(props) {
  const { text192, text193, icon32Speedometer031, text194, frame17Props, cardBottomProps } = props;

  return (
    <CardOld1>
      <CardTop>
        <Text380>{text192}</Text380>
        <Text381>{text193}</Text381>
        <Speed>
          <Icon32speedometer031 src={icon32Speedometer031} alt="Icon/32/speedometer-03 1" />
          <Text382>{text194}</Text382>
        </Speed>
        <Frame17 frame151Props={frame17Props.frame151Props} frame152Props={frame17Props.frame152Props} />
      </CardTop>
      <CardBottom text8={cardBottomProps.text8} buttonProps={cardBottomProps.buttonProps} />
    </CardOld1>
  );
}

const CardOld1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 301px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 12px;
  position: relative;
  background-color: var(--domextralight);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const Text380 = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text381 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

const Speed = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 11px;
  align-self: stretch;
  background-color: var(--contentextralight);
  border-radius: 16px;
  border: 1px none;
`;

const Icon32speedometer031 = styled.img`
  min-width: 26px;
  height: 26px;
  margin-top: -2444px;
  margin-left: -16206px;
`;

const Text382 = styled.div`
  ${IbmplexsansMediumTuna20px}
  width: 231px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default CardOld;
