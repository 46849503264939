import React from "react";
import styled from "styled-components";


function Slider() {
  return (
    <Slider1>
      <X1></X1>
      <X2></X2>
      <X2></X2>
      <X2></X2>
    </Slider1>
  );
}

const Slider1 = styled.div`
  display: flex;
  margin-right: 1px;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const X1 = styled.div`
  min-width: 57px;
  height: 6px;
  background-color: var(--contentsecondary);
  border-radius: 8px;
  border: 1px none;
`;

const X2 = styled.div`
  min-width: 30px;
  height: 6px;
  background-color: var(--domlight);
  border-radius: 8px;
  border: 1px none;
`;

const Slider2 = styled.div`
  display: flex;
  margin-right: 1px;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const X11 = styled.div`
  min-width: 57px;
  height: 6px;
  background-color: var(--contentsecondary);
  border-radius: 8px;
  border: 1px none;
`;

const X21 = styled.div`
  min-width: 30px;
  height: 6px;
  background-color: var(--domlight);
  border-radius: 8px;
  border: 1px none;
`;

export default Slider;
