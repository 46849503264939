import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumTuna16px } from "../../styledMixins";


function Input(props) {
  const { className } = props;

  return (
    <Input1 className={`input ${className || ""}`}>
      <Button className="button-35">
        <Button1 className="button-36">Ақтау қ.</Button1>
        <Icon24markerPin011
          className="icon24marker-pin-01-1-1"
          src="/img/icon-24-marker-pin-01-1@2x.svg"
          alt="Icon/24/marker-pin-01 1"
        />
      </Button>
    </Input1>
  );
}

const Input1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 235px;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;

  &.input.input-1 {
    width: 265px;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 12px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentsecondary);
`;

const Button1 = styled.div`
  ${IbmplexsansMediumTuna16px}
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Icon24markerPin011 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Icon24markerPin0111 = styled.img`
  .input.input-2 & {
    margin-top: -3043.5px;
    margin-left: -16307px;
  }
`;

export default Input;
