import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumTuna16px } from "../../styledMixins";


function Email2() {
  return (
    <Email>
      <IconMail src="/img/icon-24-mail-01-1@2x.svg" alt="icon-mail" />
      <Sas0606mailru>sas_0606@mail.ru</Sas0606mailru>
    </Email>
  );
}

const Email = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail1 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru1 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail2 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru2 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail3 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru3 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail4 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru4 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email6 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail5 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru5 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail6 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru6 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email8 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail7 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru7 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail8 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru8 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email10 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail9 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru9 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail10 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru10 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Email12 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const IconMail11 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Sas0606mailru11 = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Email2;
