import React from "react";
import Menu from "../Menu";
import CTAButtons from "../CTAButtons";
import styled from "styled-components";
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function HeaderContent(props) {
  const { menuName3Props, menuName6Props } = props;

  return (
    <HeaderContent1>
        <Container>
            <Row>
                <Col xs lg="2">
      <Link to="/"><Logo src="/img/logo@2x.svg" alt="Logo" /></Link></Col>
                <Col md="auto">
      <Menu
        menuName1Props={menuName3Props.menuName1Props}
        menuName2Props={menuName3Props.menuName2Props}
        menuName3Props={menuName3Props.menuName3Props}
        menuName4Props={menuName3Props.menuName4Props}
        menuName5Props={menuName3Props.menuName5Props}
        menuName6Props={menuName3Props.menuName6Props}
      /></Col>
                <Col  md={{ span: 2, offset: 1 }}>
      <CTAButtons button7Props={menuName6Props.button7Props} button2Props={menuName6Props.button2Props} /></Col>
                </Row>
            </Container>
    </HeaderContent1>
  );
}

const HeaderContent1 = styled.div`
  display: flex;
  position: relative;
  //width: fit-content;
  width: 100%;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent2 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo1 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent3 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo2 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent4 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo3 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent5 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo4 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent6 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo5 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent7 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo6 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent8 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo7 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent9 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo8 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent10 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo9 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent11 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo10 = styled.img`
  min-width: 125px;
  height: 37px;
`;

const HeaderContent12 = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
  gap: 90px;
  border: 1px none;
`;

const Logo11 = styled.img`
  min-width: 125px;
  height: 37px;
`;

export default HeaderContent;
