import React from "react";
import Services22 from "../Services22";
import Frame3 from "../Frame3";
import X1 from "../X1";
import Contacts from "../Contacts";
import Email2 from "../Email2";
import Frame32 from "../Frame32";
import Bottom from "../Bottom";
import styled from "styled-components";
import {IbmplexsansMediumPaleSky16px, IbmplexsansSemiBoldTuna16px} from "../../styledMixins";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer2(props) {
    const {text87, text91, text92, frame3Props, frame32Props} = props;

    return (
        <Footer>
            <Container>
                <FlexRow>
                    <Row>
                        <Col>
                    <ServicesCTA>
                        <Services22/>
                        <Frame3 button32Props={frame3Props.button32Props} button6Props={frame3Props.button6Props}/>
                    </ServicesCTA>
                        </Col><Col>
                    <Company>
                        <Text129>{text87}</Text129>
                        <Content>
                            <X1/>
                            <X2>
                                <Text133>{text91}</Text133>
                                <Text134>{text92}</Text134>
                            </X2>
                        </Content>
                    </Company>
                    </Col><Col>
                    <Contacts1>
                        <Frame33>
                            <Contacts/>
                            <Email2/>
                        </Frame33>
                        {/*<Frame32 frame29Props={frame32Props.frame29Props} />*/}

                    </Contacts1>
                        </Col>
                </Row>
                </FlexRow>
            </Container>
            <Rectangle9></Rectangle9>
            <Bottom/>
        </Footer>
    );
}

const Footer = styled.div`
  //width: 1440px;
  //height: 359px;
  position: relative;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  align-items: center;
  background-color: var(--contentextralight);
  border: 1px none;
`;

const FlexRow = styled.div`
  //height: 230px;
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  //min-width: 1152px;
`;

const ServicesCTA = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 30px;
  border: 1px none;
`;

const Company = styled.div`
  display: flex;
  //margin-left: 119px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text129 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text133 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 58px;
  position: relative;
  border: 1px none;
`;

const X2 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text134 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts1 = styled.div`
  display: flex;
  position: relative;
  //margin-left: 154px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Frame33 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Rectangle9 = styled.div`
  //width: 1152px;
  height: 1px;
  margin-top: 30px;
  background-color: var(--domlight);
  border: 1px none;
`;

export default Footer2;
