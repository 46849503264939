import React from "react";
import Speed from "../Speed";
import Services2 from "../Services2";
import CardBottom3 from "../CardBottom3";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function Card27(props) {
  const { speedProps, services2Props, cardBottom3Props } = props;

  return (
    <Card2>
      <CardTop>
        <C>c.Батыр, мкр.Хазар</C>
        <Text86>Тарифный план на предоставление доступа к сети интернет для села Батыр</Text86>
        <Speed text56={speedProps.text56} />
        <Services2 technologyProps={services2Props.technologyProps} connectionProps={services2Props.connectionProps} />
      </CardTop>
      <CardBottom3 address={cardBottom3Props.address} button2Props={cardBottom3Props.button2Props} />
    </Card2>
  );
}

const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 311px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 16px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const C = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text86 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Card27;
