import React from "react";
import Select from "../Select";
import Phone from "../Phone";
import HeaderContent from "../HeaderContent";
import styled from "styled-components";
import {IbmplexsansMediumPaleSky14px} from "../../styledMixins";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Header(props) {
    const {className, headerContentProps} = props;

    return (

          <Header1 className={`header ${className || ""}`}>
            {/*<Container className={`header ${className || ""}`}>*/}

            <TopContent className="top_content">
                {/*<Row className="top_content">*/}
                <Container>
                <Row>
                    <Col md={4}>
                <Select/>
                        </Col>
                    <Col md={{ span: 4, offset: 4 }}>
                <Contacts className="contacts">
                    <Phone/>
                    <Email className="email">
                        <IconMail className="icon-mail" src="/img/icon-24-mail-01-1@2x.svg" alt="icon-mail"/>
                        <a href="mailto:sas_0606@mail.ru" target="_blank">
                            <Sas0606mailru className="sas_0606mailru">sas_0606@mail.ru</Sas0606mailru>
                        </a>
                    </Email>

                </Contacts>
                    </Col>
                    {/*</Row>*/}
                    </Row>
                    </Container>
            </TopContent>

            <HeaderContent
                menuName3Props={headerContentProps.menuName3Props}
                menuName6Props={headerContentProps.menuName6Props}
            />
            {/*</Container>*/}

         </Header1>

    );
}

const Header1 = styled.div`
  //width: 1440px;
  //height: 112px;
  position: relative;
  display: flex;
  flex-direction: column;
  //padding: 12px 144px;
  //padding: 12px 0px 12px 144px;
  padding: 12px 0px;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--dommain);
  border: 1px none;
  box-shadow: 0px 4px 20px #2b36420d;

  &.header.header-2 {
    position: absolute;
    top: 0;
    left: 2px;
  }

`;


const TopContent = styled.div`
  display: flex;
  position: relative;
  margin-top: 4px;
  // width: fit-content;
  width: 100%;
  align-items: flex-start;
  gap: 636px;
  border: 1px none;
`;

const Contacts = styled.div`
  display: -webkit-box;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const Email = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconMail = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Sas0606mailru = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;
`;

export default Header;
