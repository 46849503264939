import React from "react";
import ImagesNews from "../ImagesNews";
import Content from "../Content";
import styled from "styled-components";


function CardNews22(props) {
  const { imagesNewsProps, imagesNewsProps2 } = props;

  return (
    <CardNews2>
      <Image>
        <ImagesNews src={imagesNewsProps.src} className={imagesNewsProps.className} />
      </Image>
      <Content text21={imagesNewsProps2.text21} text22={imagesNewsProps2.text22} />
    </CardNews2>
  );
}

const CardNews2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Image = styled.div`
  position: relative;
  min-width: 270px;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px none;
`;

const CardNews21 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Image1 = styled.div`
  position: relative;
  min-width: 270px;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px none;
`;

const CardNews23 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Image2 = styled.div`
  position: relative;
  min-width: 270px;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px none;
`;

export default CardNews22;
