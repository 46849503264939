import React from "react";
import Button62 from "../Button62";
import Button3 from "../Button3";
import Content22 from "../Content22";
import Email2 from "../Email2";
import Frame32 from "../Frame32";
import styled from "styled-components";
import {
  IbmplexsansNormalPaleSky14px,
  IbmplexsansSemiBoldTuna16px,
  IbmplexsansMediumPaleSky16px,
} from "../../styledMixins";


function FooterMobile(props) {
  const { button6Props, button32Props, frame32Props } = props;

  return (
    <FooterMobile1>
      <CTA>
        <Button62 className={button6Props.className}>{button6Props.children}</Button62>
        <Button3 className={button32Props.className}>{button32Props.children}</Button3>
      </CTA>
      <Content>
        <Text>
          <Services>
            <Text419>Услуги</Text419>
            <Content1>
              <Text420>Интернет по кабелю</Text420>
              <Text421>Интернет по радио каналу</Text421>
              <Text421>VPN</Text421>
            </Content1>
          </Services>
          <Services>
            <Text419>Компания</Text419>
            <Content2>
              <X1>
                <Text420>О нас</Text420>
                <Text421>Тарифы</Text421>
                <Text421>Проверка адреса</Text421>
                <Content1>
                  <Text420>Новости</Text420>
                  <Text421>Контакты</Text421>
                </Content1>
              </X1>
            </Content2>
          </Services>
        </Text>
        <Divider></Divider>
        <Text>
          <Contacts>
            <Contacts>
              <Contacts>
                <Text419>Телефон техподдержки:</Text419>
                <Content22 />
              </Contacts>
              <Email2 />
            </Contacts>
            <Frame32 frame29Props={frame32Props.frame29Props} />
          </Contacts>
          <Divider></Divider>
          <Bottom>
            <X2022SASTelecom>© 2022 SAS Telecom. Все права защищены</X2022SASTelecom>
            <Text429>Политика конфиденциальности</Text429>
          </Bottom>
        </Text>
      </Content>
    </FooterMobile1>
  );
}

const FooterMobile1 = styled.div`
  position: relative;
  min-width: 428px;
  height: 828px;
  background-color: var(--contentextralight);
  border: 1px none;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  width: 332px;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 36px;
  left: 48px;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 172px;
  left: 0;
  border: 1px none;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 16px;
  border: 1px none;
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Content1 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Text419 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text420 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text421 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 58px;
  border: 1px none;
`;

const X1 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px none;
`;

const Divider = styled.div`
  min-width: 428px;
  height: 1px;
  background-color: var(--domlight);
  border: 1px none;
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Bottom = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border: 1px none;
`;

const X2022SASTelecom = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text429 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

export default FooterMobile;
