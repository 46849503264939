import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalTuna12px, ValignTextMiddle } from "../../styledMixins";


function Label22() {
  return (
    <Label>
      <TextLabel>Оплата</TextLabel>
    </Label>
  );
}

const Label = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 10px;
  padding: 4px 8px;
  background-color: var(--domlight);
  border-radius: 16px;
  border: 1px none;
`;

const TextLabel = styled.div`
  ${ValignTextMiddle}
  ${IbmplexsansNormalTuna12px}
            width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

export default Label22;
