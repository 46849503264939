import React from "react";
import Header from "../Header";
import Breadcrumbs2 from "../Breadcrumbs2";
import X2 from "../X2";
import Button3 from "../Button3";
import Footer4 from "../Footer4";
import styled from "styled-components";
import { IbmplexsansBoldTuna34px, IbmplexsansNormalTuna16px } from "../../styledMixins";
import "./BlogPageGPON.css";

function BlogPageGPON(props) {
  const {
    gpon,
    text_Label,
    onu1,
    headerProps,
    breadcrumbs2Props,
    x21Props,
    x22Props,
    button3Props,
    footer4Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="blog-page-gpon screen">
        <Header headerContentProps={headerProps.headerContentProps} />
        <Breadcrumbs2 x1={breadcrumbs2Props.x1} x2={breadcrumbs2Props.x2} x3={breadcrumbs2Props.x3} />
        <X1>
          <GPON>{gpon}</GPON>
          <X11>
            <TextLabel>{text_Label}</TextLabel>
            <Onu1 src={onu1} alt="onu 1" />
          </X11>
        </X1>
        <X2 gpon1={x21Props.gpon1} gpon2={x21Props.gpon2} />
        <X2 gpon1={x22Props.gpon1} gpon2={x22Props.gpon2} className={x22Props.className} />
        <Button3 className={button3Props.className}>{button3Props.children}</Button3>
        <Footer4
          className={footer4Props.className}
          button32Props={footer4Props.button32Props}
          button32Props2={footer4Props.button32Props2}
        />
      </div>
    </div>
  );
}

const X1 = styled.div`
  display: flex;
  margin-top: 36px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const GPON = styled.p`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const X11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 100px;
  border: 1px none;
`;

const TextLabel = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: 662px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Onu1 = styled.img`
  min-width: 390px;
  height: 185px;
  object-fit: cover;
`;

export default BlogPageGPON;
