import React from "react";
import styled from "styled-components";
import {
  IbmplexsansNormalPaleSky16px,
  IbmplexsansNormalCuriousBlue16px,
  IbmplexsansSemiBoldTuna16px,
} from "../../styledMixins";


function Service(props) {
  const { text31, text32 } = props;

  return (
    <Service1>
      <Heading>
        <Icon32modem021 src="/img/icon-32-modem-02-1@2x.svg" alt="Icon/32/modem-02 1" />
        <Text124>{text31}</Text124>
      </Heading>
      <Text125>{text32}</Text125>
      {/*<Text126>Подробнее</Text126>*/}
    </Service1>
  );
}

const Service1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border: 1px none;
`;

const Icon32modem021 = styled.img`
  min-width: 32px;
  height: 32px;
`;

const Text124 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: 261px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Text125 = styled.p`
  ${IbmplexsansNormalPaleSky16px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
`;

const Text126 = styled.div`
  ${IbmplexsansNormalCuriousBlue16px}
  width: 335px;
  margin-right: -8px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Service2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Heading1 = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border: 1px none;
`;

const Icon32modem0211 = styled.img`
  min-width: 32px;
  height: 32px;
`;

const Text409 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: 261px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Text410 = styled.p`
  ${IbmplexsansNormalPaleSky16px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
`;

const Text411 = styled.div`
  ${IbmplexsansNormalCuriousBlue16px}
  width: 335px;
  margin-right: -8px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Service;
