import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldTuna16px } from "../../styledMixins";


function Button7(props) {
  const { children } = props;

  return (
    <Button>
      <Button1>{children}</Button1>
    </Button>
  );
}

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button3 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button5 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button6 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button8 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button10 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button12 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button13 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button14 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button15 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button16 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button17 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button18 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button19 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button20 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button21 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button22 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button23 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const Button24 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Button7;
