import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import Frame7 from "../Frame7";
import Frame8 from "../Frame8";
import Content222 from "../Content222";
import Footer4 from "../Footer4";
import styled from "styled-components";
import {
    IbmplexsansBoldTuna34px,
    IbmplexsansMediumCuriousBlue16px,
    IbmplexsansBoldTuna24px,
    IbmplexsansMediumPaleSky16px,
    IbmplexsansNormalTuna16px,
    IbmplexsansMediumTuna16px,
} from "../../styledMixins";
import "./Contacts2.css";
import {Map, YMaps, Placemark} from "react-yandex-maps";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Contacts2(props) {
    const {
        text287,
        text288,
        whatsapp,
        phone1,
        text289,
        phone2,
        phone3,
        phone4,
        eMail,
        sas_0606MailRu,
        text292,
        text293,
        text294,
        phone5,
        phone6,
        image231,
        text300,
        text301,
        image232,
        text308,
        text309,
        image233,
        text316,
        text317,
        image234,
        headerProps,
        breadcrumbsProps,
        frame71Props,
        frame72Props,
        frame8Props,
        content2221Props,
        content2222Props,
        content2223Props,
        footer4Props,
    } = props;

    return (
        <div className="container-center-horizontal">
            <div className="contacts screen">
                <Container><Header headerContentProps={headerProps.headerContentProps}/></Container>
                <Container><Breadcrumbs x3={breadcrumbsProps.x3} className={breadcrumbsProps.className}/></Container>
                <Container><Text287>{text287}</Text287></Container>
                <Container><Contacts>
                    <Contacts1>
                        <Text288>{text288}</Text288>
                        <Content>
                            <Frame5>
                                <WhatsApp>{whatsapp}</WhatsApp>
                                <Phone>{phone1}</Phone>
                            </Frame5>
                            <Frame5>
                                <WhatsApp>{text289}</WhatsApp>
                                <Frame71>
                                    <Phone1>{phone2}</Phone1>
                                    <Phone2>{phone3}</Phone2>
                                    <Phone2>{phone4}</Phone2>
                                </Frame71>
                            </Frame5>
                            <Frame7 text253={frame71Props.text253} frame72Props={frame71Props.frame72Props}/>
                            <Frame7 text253={frame72Props.text253} frame72Props={frame72Props.frame72Props}/>
                            <Frame5>
                                <WhatsApp>{eMail}</WhatsApp>
                                <Frame72>
                                    <a href="mailto:sas_0606@mail.ru" target="_blank">
                                        <Sas0606mailru>{sas_0606MailRu}</Sas0606mailru>
                                    </a>
                                </Frame72>
                            </Frame5>
                        </Content>
                    </Contacts1>
                </Contacts></Container>
                <Container><Frame13>
                    <Office1><Row><Col>
                        <Contacts3>
                            <Contacts1>
                                <Text288>{text292}</Text288>
                                <Text293>{text293}</Text293>
                                <Content1>
                                    <Frame72>
                                        <WhatsApp>{text294}</WhatsApp>
                                        <Frame71>
                                            <Phone1>{phone5}</Phone1>
                                            <Phone2>{phone6}</Phone2>
                                        </Frame71>
                                    </Frame72>
                                    <Frame8 x0900={frame8Props.x0900}/>
                                </Content1>
                            </Contacts1>
                        </Contacts3></Col><Col>
                        {/*<Image23 src={image231} alt="image 23" />*/}
                        <YMaps>
                            <Map  defaultState={{center: [43.619040, 51.214293], zoom: 12}}>
                                <Placemark geometry={[43.619040, 51.214293]}/>
                            </Map>
                        </YMaps></Col>
                    </Row></Office1>
                    <Office1><Row><Col>
                        <Contacts3>
                            <Contacts4>
                                <Text288>{text300}</Text288>
                                <Text293>{text301}</Text293>
                                <Content222 frame72Props={content2221Props.frame72Props}
                                            frame8Props={content2221Props.frame8Props}/>
                            </Contacts4>
                        </Contacts3></Col><Col>
                        <YMaps>
                            <Map  defaultState={{center: [43.661537, 51.206199], zoom: 12}}>
                                <Placemark geometry={[43.661537, 51.206199]}/>
                            </Map>
                        </YMaps></Col>
                        {/*<Image23 src={image232} alt="image 23" />*/}
                    </Row></Office1>
                    <Office1><Row><Col>
                        <Contacts3>
                            <Contacts4>
                                <Text288>{text308}</Text288>
                                <Text293>{text309}</Text293>
                                <Content222 frame72Props={content2222Props.frame72Props}
                                            frame8Props={content2222Props.frame8Props}/>
                            </Contacts4>
                        </Contacts3></Col><Col>
                        <YMaps>
                            <Map  defaultState={{center: [43.326951, 52.893550], zoom: 12}}>
                                <Placemark geometry={[43.326951, 52.893550]}/>
                            </Map>
                        </YMaps></Col>
                        {/*<Image23 src={image233} alt="image 23" />*/}
                    </Row></Office1>
                    <Office1><Row><Col>
                        <Contacts3>
                            <Contacts4>
                                <Text288>{text316}</Text288>
                                <Text293>{text317}</Text293>
                                <Content222 frame72Props={content2223Props.frame72Props}
                                            frame8Props={content2223Props.frame8Props}/>
                            </Contacts4>
                        </Contacts3></Col><Col>
                        <YMaps>
                            <Map  defaultState={{center: [42.309727, 69.582487], zoom: 12}}>
                                <Placemark geometry={[42.309727, 69.582487]}/>
                            </Map>
                        </YMaps></Col>
                        {/*<Image23 src={image234} alt="image 23" />*/}
                    </Row></Office1>
                </Frame13></Container>
                <Container><Footer4 button32Props={footer4Props.button32Props}
                                    button32Props2={footer4Props.button32Props2}/></Container>
            </div>
        </div>
    );
}

const Text287 = styled.div`
  ${IbmplexsansBoldTuna34px}
  margin-top: 35px;
  margin-left: 5%;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Contacts = styled.div`
  display: flex;
  margin-top: 27px;
  margin-left: 5%;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Contacts1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text288 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

const Frame5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const WhatsApp = styled.div`
  ${IbmplexsansMediumPaleSky16px} //width: 148px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone1 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Frame71 = styled.div`
  ${IbmplexsansMediumTuna16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 4px;
  border: 1px none;
`;

const Phone2 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text293 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Frame72 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Sas0606mailru = styled.div`
  ${IbmplexsansMediumCuriousBlue16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
`;

const Frame13 = styled.div`
  display: flex;
  align-self: center;
  margin-top: 37px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Office1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Contacts3 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 564px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const Image23 = styled.img`
  //min-width: 564px;
  //height: 338px;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Contacts4 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

export default Contacts2;
