import React from "react";
import MenuName from "../MenuName";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Navbar} from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import "./Menu.css"

function Menu(props) {
    const {menuName1Props, menuName2Props, menuName3Props, menuName4Props, menuName5Props, menuName6Props} = props;

    return (
        // <Menu1>
        //   <Link to="/"><MenuName>{menuName1Props.children}</MenuName></Link>
        //   <Link to="/services"><MenuName>{menuName2Props.children}</MenuName></Link>
        //   <Link to="/tariff-page-optics"><MenuName>{menuName3Props.children}</MenuName></Link>
        //   <Link to="/news"><MenuName>{menuName4Props.children}</MenuName></Link>
        //   <Link to="/about-company"><MenuName className={menuName5Props.className}>{menuName5Props.children}</MenuName></Link>
        //   <Link to="/contacts"><MenuName>{menuName6Props.children}</MenuName></Link>
        // </Menu1>
        <Navbar bg="light" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto nav-custom">
                    <Link to="/"><MenuName>{menuName1Props.children}</MenuName></Link>
                    <Link to="/services"><MenuName>{menuName2Props.children}</MenuName></Link>
                    <Link to="/tariff-page-optics"><MenuName>{menuName3Props.children}</MenuName></Link>
                    <Link to="/news"><MenuName>{menuName4Props.children}</MenuName></Link>
                    <Link to="/about-company"><MenuName
                        className={menuName5Props.className}>{menuName5Props.children}</MenuName></Link>
                    <Link to="/contacts"><MenuName>{menuName6Props.children}</MenuName></Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
        ;
}

const Menu1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu6 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu8 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu10 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Menu12 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

export default Menu;
