import React from "react";
import Label from "../Label";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Frame15(props) {
  const { text6, className, labelProps } = props;

  return (
    <Frame151 className={`frame-15 ${className || ""}`}>
      <Text383 className="text-383">{text6}</Text383>
      <Label className={labelProps.className}>{labelProps.children}</Label>
    </Frame151>
  );
}

const Frame151 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 14px;
  position: relative;
  border: 1px none;

  &.frame-15.frame-16 {
    gap: 99px;
  }
`;

const Text383 = styled.p`
  ${IbmplexsansNormalTuna14px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Frame15;
