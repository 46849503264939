import React from "react";
import ImagesNews from "../ImagesNews";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function X12(props) {
  const { imagesNews1Props, imagesNews2Props, imagesNews3Props, imagesNews4Props } = props;

  return (
    <X1><Row>
      <Col><ImagesNews src={imagesNews1Props.src} className={imagesNews1Props.className} /></Col>
      <Col><ImagesNews src={imagesNews2Props.src} className={imagesNews2Props.className} /></Col>
      <Col><ImagesNews src={imagesNews3Props.src} className={imagesNews3Props.className} /></Col>
      <Col><ImagesNews src={imagesNews4Props.src} className={imagesNews4Props.className} /></Col>
    </Row></X1>
  );
}

const X1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

export default X12;
