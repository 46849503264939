import React, {useState} from "react";
import styled from "styled-components";
import {IbmplexsansSemiBoldWhite16px} from "../../styledMixins";
import FormRequest from "../FormRequest";


function Button2(props) {
    const {children, className} = props;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <a href="#" onClick={handleShow}><Button className={`button-2 ${className || ""}`}>
                <Button1 className="button-3">{children}</Button1>

            </Button></a>
            <FormRequest active={show} setActive={setShow}/></div>
    );
}

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--accentred);
  border-radius: 36px;
  border: 1px none;

  &.button-2.button-4 {
    width: 289px;
  }

  &.button-2.button-5 {
    width: 289px;
  }

  &.button-2.button-6 {
    width: 289px;
  }

  &.button-2.button-7 {
    width: 289px;
  }

  &.button-2.button-8 {
    width: 289px;
  }

  &.button-2.button-9 {
    width: 289px;
  }

  &.button-2.button-10 {
    width: 289px;
  }

  &.button-2.button-11 {
    width: 289px;
  }

  &.button-2.button-12 {
    width: 289px;
  }

  &.button-2.button-13 {
    width: 289px;
  }

  &.button-2.button-14 {
    width: 289px;
  }

  &.button-2.button-15 {
    width: 289px;
  }

  &.button-2.button-16 {
    width: 289px;
  }

  &.button-2.button-17 {
    width: 289px;
  }

  &.button-2.button-18 {
    width: 289px;
  }

  &.button-2.button-20 {
    width: 289px;
  }

  &.button-2.button-21 {
    width: 289px;
  }

  &.button-2.button-22 {
    width: 289px;
  }

  &.button-2.button-23 {
    width: 289px;
  }

  &.button-2.button-24 {
    width: 289px;
  }

  &.button-2.button-25 {
    width: 289px;
  }
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Button3 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-4  & {
    text-align: center;
  }
`;

const Button4 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-5  & {
    text-align: center;
  }
`;

const Button5 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-6  & {
    text-align: center;
  }
`;

const Button6 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-7  & {
    text-align: center;
  }
`;

const Button7 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-8  & {
    text-align: center;
  }
`;

const Button8 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-9  & {
    text-align: center;
  }
`;

const Button9 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-10  & {
    text-align: center;
  }
`;

const Button10 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-11  & {
    text-align: center;
  }
`;

const Button11 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-12  & {
    text-align: center;
  }
`;

const Button12 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-13  & {
    text-align: center;
  }
`;

const Button13 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-14  & {
    text-align: center;
  }
`;

const Button14 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-15  & {
    text-align: center;
  }
`;

const Button15 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-16  & {
    text-align: center;
  }
`;

const Button16 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-17  & {
    text-align: center;
  }
`;

const Button17 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-18  & {
    text-align: center;
  }
`;

const Button18 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-20  & {
    text-align: center;
  }
`;

const Button19 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-21  & {
    text-align: center;
  }
`;

const Button20 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-22  & {
    text-align: center;
  }
`;

const Button21 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-23  & {
    text-align: center;
  }
`;

const Button22 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-24  & {
    text-align: center;
  }
`;

const Button23 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}

  .button-2.button-25  & {
    text-align: center;
  }
`;

export default Button2;
