import React from "react";
import MainBg from "../MainBg";
import Checkbox3 from "../Checkbox3";
import CardOld from "../CardOld";
import CardOld2 from "../CardOld2";
import CardOld3 from "../CardOld3";
import Button3 from "../Button3";
import Services6 from "../Services6";
import Header from "../Header";
import CheckConnection from "../CheckConnection";
import Button6 from "../Button6";
import CardNews232 from "../CardNews232";
import Heading2 from "../Heading2";
import NamePhone from "../NamePhone";
import Input4 from "../Input4";
import Input3 from "../Input3";
import Checkbox from "../Checkbox";
import Button4 from "../Button4";
import HowToConnect from "../HowToConnect";
import styled from "styled-components";
import { IbmplexsansBoldTuna34px } from "../../styledMixins";
import "./RequestPopupEnabled.css";

function RequestPopupEnabled(props) {
  const {
    icon24ChevronLeft1,
    icon24ChevronLeft2,
    text379,
    popup_Request,
    text397,
    mainBgProps,
    checkbox31Props,
    checkbox32Props,
    checkbox33Props,
    cardOldProps,
    cardOld2Props,
    cardOld3Props,
    button3Props,
    services6Props,
    headerProps,
    checkConnectionProps,
    button6Props,
    cardNews232Props,
    namePhoneProps,
    input4Props,
    input3Props,
    checkboxProps,
    button4Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="request-popup-enabled screen">
        <OverlapGroup>
          <MainBg className={mainBgProps.className} buttonProps={mainBgProps.buttonProps} />
          <Tariff>
            <Icon24chevronLeft1 src={icon24ChevronLeft1} alt="Icon/24/chevron-left 1" />
            <Icon24chevronLeft2 src={icon24ChevronLeft2} alt="Icon/24/chevron-left 2" />
            <FlexCol>
              <Text379>{text379}</Text379>
              <CheckboxContainer>
                <Checkbox3 checkboxText={checkbox31Props.checkboxText} />
                <Checkbox3 checkboxText={checkbox32Props.checkboxText} className={checkbox32Props.className} />
                <Checkbox3 checkboxText={checkbox33Props.checkboxText} className={checkbox33Props.className} />
              </CheckboxContainer>
              <Cards>
                <CardOld
                  text192={cardOldProps.text192}
                  text193={cardOldProps.text193}
                  icon32Speedometer031={cardOldProps.icon32Speedometer031}
                  text194={cardOldProps.text194}
                  frame17Props={cardOldProps.frame17Props}
                  cardBottomProps={cardOldProps.cardBottomProps}
                />
                <CardOld2 frame152Props={cardOld2Props.frame152Props} cardBottomProps={cardOld2Props.cardBottomProps} />
                <CardOld3 frame153Props={cardOld3Props.frame153Props} cardBottomProps={cardOld3Props.cardBottomProps} />
              </Cards>
              <Button3 className={button3Props.className}>{button3Props.children}</Button3>
            </FlexCol>
          </Tariff>
          <Services6
            className={services6Props.className}
            service1Props={services6Props.service1Props}
            service2Props={services6Props.service2Props}
            service3Props={services6Props.service3Props}
          />
          <Header className={headerProps.className} headerContentProps={headerProps.headerContentProps} />
          <PopupRequest src={popup_Request} alt="Popup_Request" />
        </OverlapGroup>
        <CheckConnection {...checkConnectionProps} />
        <News2>
          <Heading>
            <Text397>{text397}</Text397>
            <Button6 className={button6Props.className} />
          </Heading>
          <CardNews232 {...cardNews232Props} />
        </News2>
        <Connect>
          <Request>
            <Heading2 />
            <Inputs>
              <Inputs1>
                <NamePhone input31Props={namePhoneProps.input31Props} input32Props={namePhoneProps.input32Props} />
                <TariffAddress>
                  <Input4 className={input4Props.className} />
                  <Input3>{input3Props.children}</Input3>
                </TariffAddress>
              </Inputs1>
            </Inputs>
            <CTA>
              <Checkbox className={checkboxProps.className} />
              <Button4 className={button4Props.className}>{button4Props.children}</Button4>
            </CTA>
          </Request>
          <HowToConnect />
        </Connect>
      </div>
    </div>
  );
}

const OverlapGroup = styled.div`
  width: 1442px;
  height: 1857px;
  position: relative;
  margin-right: 2px;
`;

const Tariff = styled.div`
  position: absolute;
  width: 1440px;
  height: 773px;
  top: 1084px;
  left: 0;
  display: flex;
  align-items: flex-start;
  background-color: var(--domdop);
  overflow: hidden;
  border: 1px none;
`;

const Icon24chevronLeft1 = styled.img`
  width: 47px;
  height: 47px;
  margin-left: -15865px;
  margin-top: -1740px;
`;

const Icon24chevronLeft2 = styled.img`
  width: 47px;
  height: 47px;
  margin-left: 1091px;
  margin-top: -1740px;
`;

const FlexCol = styled.div`
  width: 1107px;
  position: relative;
  align-self: center;
  margin-left: 14822px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 677px;
`;

const Text379 = styled.div`
  ${IbmplexsansBoldTuna34px}
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const CheckboxContainer = styled.div`
  height: 24px;
  position: relative;
  margin-top: 39px;
  display: flex;
  align-items: flex-start;
  min-width: 537px;
`;

const Cards = styled.div`
  display: flex;
  position: relative;
  align-self: flex-end;
  margin-top: 36px;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const PopupRequest = styled.img`
  position: absolute;
  width: 1440px;
  height: 1280px;
  top: 0;
  left: 2px;
`;

const News2 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 36px;
  padding: 36px 144px;
  background-color: var(--domdop);
  border: 1px none;
`;

const Heading = styled.div`
  display: flex;
  width: 1152px;
  align-items: center;
  gap: 862px;
  position: relative;
  border: 1px none;
`;

const Text397 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Connect = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Request = styled.div`
  display: flex;
  flex-direction: column;
  width: 662px;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px;
  border: 1px none;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border: 1px none;
`;

const Inputs1 = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  border: 1px none;
`;

const TariffAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 1;
  border: 1px none;
`;

const CTA = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  align-self: stretch;
  border: 1px none;
`;

export default RequestPopupEnabled;
