import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import Label22 from "../Label22";
import Label3 from "../Label3";
import Label4 from "../Label4";
import CardNews from "../CardNews";
import CardNews3 from "../CardNews3";
import CardNews4 from "../CardNews4";
import Pagination from "../Pagination";
import Footer4 from "../Footer4";
import styled from "styled-components";
import { IbmplexsansBoldTuna34px } from "../../styledMixins";
import "./Blog.css";

function Blog(props) {
  const {
    text219,
    headerProps,
    breadcrumbsProps,
    cardNews1Props,
    cardNews31Props,
    cardNews32Props,
    cardNews41Props,
    cardNews42Props,
    cardNews2Props,
    paginationProps,
    footer4Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="blog screen">
        <Header headerContentProps={headerProps.headerContentProps} />
        <Breadcrumbs x3={breadcrumbsProps.x3} className={breadcrumbsProps.className} />
        <Text219>{text219}</Text219>
        <Filters>
          <Label22 />
          <Label3 />
          <Label4 />
        </Filters>
        <Content>
          <X1>
            <CardNews text54={cardNews1Props.text54} imageProps={cardNews1Props.imageProps} />
            <CardNews3 text55={cardNews31Props.text55} imageProps={cardNews31Props.imageProps} />
            <CardNews3 text55={cardNews32Props.text55} imageProps={cardNews32Props.imageProps} />
          </X1>
          <X1>
            <CardNews4 text57={cardNews41Props.text57} imageProps={cardNews41Props.imageProps} />
            <CardNews4 text57={cardNews42Props.text57} imageProps={cardNews42Props.imageProps} />
            <CardNews text54={cardNews2Props.text54} imageProps={cardNews2Props.imageProps} />
          </X1>
          <Pagination
            pagination31Props={paginationProps.pagination31Props}
            pagination31Props2={paginationProps.pagination31Props2}
            pagination32Props={paginationProps.pagination32Props}
            pagination33Props={paginationProps.pagination33Props}
            pagination34Props={paginationProps.pagination34Props}
            pagination2Props={paginationProps.pagination2Props}
          />
        </Content>
        <Footer4 button32Props={footer4Props.button32Props} button32Props2={footer4Props.button32Props2} />
      </div>
    </div>
  );
}

const Text219 = styled.div`
  ${IbmplexsansBoldTuna34px}
  margin-top: 35px;
  margin-left: 144px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Filters = styled.div`
  display: flex;
  position: relative;
  margin-top: 27px;
  margin-left: 144px;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  position: relative;
  align-self: center;
  margin-top: 24px;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 36px;
  border: 1px none;
`;

const X1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

export default Blog;
