import React from "react";
import styled from "styled-components";
import { IbmplexsansBoldTuna34px, IbmplexsansNormalPaleSky16px } from "../../styledMixins";
import {useTranslation} from "react-i18next";

function Heading() {
    const { t, i18n } = useTranslation();
    return (
    <Heading1>
      <Text123>{t('heading.text1')}</Text123>
      <TextLabel>
        {t('heading.text2')} <br />
        {t('heading.text3')}
      </TextLabel>
    </Heading1>
  );
}

const Heading1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text123 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const TextLabel = styled.p`
  ${IbmplexsansNormalPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
`;

const Heading2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text408 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const TextLabel1 = styled.p`
  ${IbmplexsansNormalPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
`;

export default Heading;
