import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalPaleSky14px } from "../../styledMixins";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Bottom() {
  const { t, i18n } = useTranslation();
  return (
    <Bottom1>
      <Row>
      <Col><X2022SASTelecom>© 2022 SAS Telecom. {t('footer.text9')}</X2022SASTelecom></Col>
      <Col><Text12>{t('footer.text10')}</Text12></Col>
        </Row>
    </Bottom1>
  );
}

const Bottom1 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  //gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text12 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom2 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom1 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text139 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom3 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom2 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text153 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom4 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom3 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text175 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom5 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom4 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text191 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom6 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom5 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text216 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom7 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom6 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text237 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom8 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom7 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text251 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom9 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom8 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text284 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom10 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom9 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text335 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom11 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom10 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text372 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

const Bottom12 = styled.div`
  ${IbmplexsansNormalPaleSky14px}
  display: flex;
  margin-top: 16px;
  width: fit-content;
  align-items: flex-end;
  justify-content: center;
  gap: 659px;
  border: 1px none;
`;

const X2022SASTelecom11 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Text450 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: underline;
`;

export default Bottom;
