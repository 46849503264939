import React from "react";
import Heading from "../Heading";
import Service from "../Service";
import styled from "styled-components";


function Services6(props) {
  const { className, service1Props, service2Props, service3Props } = props;

  return (
    <Services className={`services-5 ${className || ""}`}>
      <Heading />
      <Services1 className="services-6 row">
        <Service text31={service1Props.text31} text32={service1Props.text32} />
        {/*<Divider className="divider"></Divider>*/}
        <Service text31={service2Props.text31} text32={service2Props.text32} />
        {/*<Divider className="divider-1"></Divider>*/}
        <Service text31={service3Props.text31} text32={service3Props.text32} />
      </Services1>
    </Services>
  );
}

const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  padding: 36px;
  //position: absolute;
  top: 37px;
  left: 0;
  background-color: var(--domdop);
  border-radius: 24px;
  border: 1px none;

  &.services-5.services-7 {
    top: 660px;
    left: 146px;
  }
`;

const Services1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px;
  border: 1px none;
`;

const Divider = styled.div`
  align-self: stretch;
  min-width: 1px;
  height: 168px;
  background-color: var(--domextralight);
  border: 1px none;
`;

export default Services6;
