import React from "react";
import Label from "../Label";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Technology(props) {
  const { labelProps } = props;

  return (
    <Technology1>
      <Text25>Технология</Text25>
      <Label>{labelProps.children}</Label>
    </Technology1>
  );
}

const Technology1 = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text25 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 133px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Technology2 = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text99 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 133px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default Technology;
