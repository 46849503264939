import React from "react";
import Services22 from "../Services22";
import Frame3 from "../Frame3";
import X1 from "../X1";
import Contacts from "../Contacts";
import Email2 from "../Email2";
import Frame32 from "../Frame32";
import Bottom from "../Bottom";
import styled from "styled-components";
import {IbmplexsansMediumPaleSky16px, IbmplexsansSemiBoldTuna16px} from "../../styledMixins";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

function Footer4(props) {
    const {className, button32Props, button32Props2} = props;
    const {t, i18n} = useTranslation();
    return (
        <Footer className={`footer ${className || ""}`}>
            <Container>
                <FlexRow className="flex-row">
                    <Row>
                        <Col>
                            <ServicesCTA className="services-cta">
                                <Services22/>
                                <Frame3 button32Props={button32Props.button32Props}
                                        button6Props={button32Props.button6Props}/>
                            </ServicesCTA></Col><Col>
                        <Company className="company">
                            <Text1 className="text-1">{t('footer.text7')}</Text1>
                            <Content className="content-2">
                                <X1/>
                                <X2 className="x2-1">
                                    <Link to="/news"><Text5 className="text-5">{t('menu.title4')}</Text5></Link>
                                    <Link to="/blog"><Text6 className="text-6">{t('footer.text14')}</Text6></Link>
                                    <Link to="/contacts"><Text6 className="text-7">{t('footer.text15')}</Text6></Link>
                                </X2>
                            </Content>
                        </Company></Col><Col>
                        <Contacts1 className="contacts-1">
                            <Frame33 className="frame-33">
                                <Contacts/>
                                <Email2/>
                            </Frame33>
                            <Frame32 frame29Props={button32Props2.frame29Props}/>
                        </Contacts1></Col>
                    </Row>
                </FlexRow>
                <Rectangle9 className="rectangle-9"></Rectangle9>
                <Bottom/>
            </Container>
        </Footer>
    );
}

const Footer = styled.div`
  //width: 1440px;
  //height: 359px;
  position: relative;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  align-items: center;
  background-color: var(--contentextralight);
  border: 1px none;

  &.footer.footer-1 {
    margin-top: 68px;
  }

  &.footer.footer-2 {
    margin-top: 114px;
  }

  &.footer.footer-3 {
    margin-top: 72px;
  }

  &.footer.footer-5 {
    margin-top: 60px;
  }

  &.footer.footer-6 {
    margin-top: 62px;
  }

  &.footer.footer-7 {
    margin-top: 61px;
  }
`;

const FlexRow = styled.div`
  //height: 230px;
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  //min-width: 1152px;
`;

const ServicesCTA = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 30px;
  border: 1px none;
`;

const Company = styled.div`
  display: flex;
  //margin-left: 119px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text1 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text5 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 58px;
  position: relative;
  border: 1px none;
`;

const X2 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text6 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts1 = styled.div`
  display: flex;
  position: relative;
  //margin-left: 154px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Frame33 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Rectangle9 = styled.div`
  //width: 1152px;
  height: 1px;
  margin-top: 30px;
  background-color: var(--domlight);
  border: 1px none;
`;

export default Footer4;
