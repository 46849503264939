import React from "react";
import Phone from "../Phone";
import Menu3 from "../Menu3";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky14px } from "../../styledMixins";


function HeaderMobile(props) {
  const { sas_0606MailRu } = props;

  return (
    <HeaderMobile1>
      <FlexRow>
        <Logo src="/img/logo@2x.svg" alt="Logo" />
        <Contacts>
          <Phone />
          <Email>
            <IconMail src="/img/icon-24-mail-01-1@2x.svg" alt="icon-mail" />
            <a href="mailto:sas_0606@mail.ru" target="_blank">
              <Sas0606mailru>{sas_0606MailRu}</Sas0606mailru>
            </a>
          </Email>
        </Contacts>
        <Menu3 />
      </FlexRow>
    </HeaderMobile1>
  );
}

const HeaderMobile1 = styled.div`
  min-width: 428px;
  height: 90px;
  background-color: var(--dommain);
  border: 1px none;
  box-shadow: 0px 4px 20px #2b36420d;
`;

const FlexRow = styled.div`
  position: relative;
  height: 44px;
  top: 23px;
  left: 24px;
  display: flex;
  align-items: center;
  min-width: 380px;
`;

const Logo = styled.img`
  width: 125px;
  height: 37px;
  margin-bottom: 1px;
`;

const Contacts = styled.div`
  display: flex;
  position: relative;
  margin-left: 46px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 4px;
  border: 1px none;
`;

const Email = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconMail = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Sas0606mailru = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;
`;

export default HeaderMobile;
