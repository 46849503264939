import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldPaleSky16px } from "../../styledMixins";


function Button4(props) {
  const { children, className } = props;

  return (
    <Button className={`button-39 ${className || ""}`}>
      <Button1 className="button-40">{children}</Button1>
    </Button>
  );
}

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--domextralight);
  border-radius: 36px;
  border: 1px none;

  &.button-39.button-41 {
    width: 301px;
    background-color: var(--contentextralight);
  }

  &.button-39.button-43 {
    align-self: stretch;
    width: unset;
  }

  &.button-39.button-44 {
    width: 301px;
    background-color: var(--contentextralight);
  }

  &.button-39.button-45 {
    align-self: stretch;
    width: unset;
  }
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldPaleSky16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Button4;
