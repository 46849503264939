import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky16px } from "../../styledMixins";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function X1() {
  const { t, i18n } = useTranslation();
  return (
    <X11>
      <Link to="/about-company"><Text2>{t('footer.text11')}</Text2></Link>
      <Link to="/tariff-page-optics"><Text3>{t('footer.text12')}</Text3></Link>
      <Link to="/check-coverage"><Text3>{t('footer.text13')}</Text3></Link>
    </X11>
  );
}

const X11 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text2 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text3 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X12 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text130 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text131 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X13 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text143 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text144 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X14 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text165 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text166 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X15 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text181 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text182 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X16 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text206 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text207 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X17 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text227 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text228 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X18 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text241 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text242 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X19 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text274 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text275 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X110 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text325 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text326 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X111 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text362 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text363 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X112 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text440 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text441 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default X1;
