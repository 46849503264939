import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky14px } from "../../styledMixins";


function Phone() {
  return (
    <Phone1>
      <IconCall src="/img/icon-24-phone-1@2x.svg" alt="icon-call" />
      <Phone2>+7 (777) 632-19-19</Phone2>
    </Phone1>
  );
}

const Phone1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone2 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall1 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone4 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall2 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone6 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall3 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone8 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall4 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone10 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall5 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone12 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone13 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall6 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone14 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone15 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall7 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone16 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone17 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall8 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone18 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone19 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall9 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone20 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone21 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall10 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone22 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone23 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall11 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone24 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Phone25 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const IconCall12 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Phone26 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

export default Phone;
