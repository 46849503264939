import React from "react";
import ImagesNews from "../ImagesNews";
import styled from "styled-components";


function Image(props) {
  const { imagesNewsProps } = props;

  return (
    <Image1>
      <ImagesNews src={imagesNewsProps.src} className={imagesNewsProps.className} />
    </Image1>
  );
}

const Image1 = styled.div`
  position: relative;
  min-width: 368px;
  height: 190px;
  border-radius: 16px 16px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

export default Image;
