import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldAlabaster16px } from "../../styledMixins";


function Button62(props) {
  const { children, className } = props;

  return (
    <Button className={`button-31 ${className || ""}`}>
      <Button1 className="button-32">{children}</Button1>
    </Button>
  );
}

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--accentblue);
  border-radius: 36px;
  margin-left: calc(var(--bs-gutter-x) * .5);
  border: 1px none;

  &.button-31.button-34 {
    align-self: stretch;
    width: unset;
    margin-left: calc(var(--bs-gutter-x) * .5);
  }
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldAlabaster16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Button62;
