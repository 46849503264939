import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumTuna16px } from "../../styledMixins";


function MenuName(props) {
  const { children, className } = props;

  return (
    <MenuName1 className={`menu-name ${className || ""}`}>
      <Text13 className="text-13">{children}</Text13>
    </MenuName1>
  );
}

const MenuName1 = styled.div`
  position: relative;
  min-width: 86px;
  height: 21px;
  border: 1px none;

  &.menu-name.menu-name-2 {
    min-width: 112px;
  }

  &.menu-name.menu-name-3 {
    min-width: 112px;
  }

  &.menu-name.menu-name-4 {
    min-width: 112px;
  }

  &.menu-name.menu-name-5 {
    min-width: 112px;
  }

  &.menu-name.menu-name-6 {
    min-width: 112px;
  }

  &.menu-name.menu-name-7 {
    min-width: 112px;
  }

  &.menu-name.menu-name-8 {
    min-width: 112px;
  }

  &.menu-name.menu-name-9 {
    min-width: 112px;
  }

  &.menu-name.menu-name-10 {
    min-width: 112px;
  }

  &.menu-name.menu-name-11 {
    min-width: 112px;
  }

  &.menu-name.menu-name-12 {
    min-width: 112px;
  }

  &.menu-name.menu-name-13 {
    min-width: 112px;
  }
`;

const Text13 = styled.div`
  ${IbmplexsansMediumTuna16px}
  position: absolute;
  //width: 86px;
  top: -1px;
  left: 0;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const TextLabel = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-2  & {
    width: 112px;
  }
`;

const TextLabel1 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-3  & {
    width: 112px;
  }
`;

const TextLabel2 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-4  & {
    width: 112px;
  }
`;

const TextLabel3 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-5  & {
    width: 112px;
  }
`;

const TextLabel4 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-6  & {
    width: 112px;
  }
`;

const TextLabel5 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-7  & {
    width: 112px;
  }
`;

const TextLabel6 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-8  & {
    width: 112px;
  }
`;

const TextLabel7 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-9  & {
    width: 112px;
  }
`;

const TextLabel8 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-10  & {
    width: 112px;
  }
`;

const TextLabel9 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-11  & {
    width: 112px;
  }
`;

const TextLabel10 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-12  & {
    width: 112px;
  }
`;

const TextLabel11 = styled.div`
  ${IbmplexsansMediumTuna16px}

  .menu-name.menu-name-13  & {
    width: 112px;
  }
`;

export default MenuName;
