import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import Feature2 from "../Feature2";
import Footer4 from "../Footer4";
import Footer2 from "../Footer2";
import styled from "styled-components";
import {
    IbmplexsansBoldTuna34px,
    IbmplexsansMediumTuna24px,
    IbmplexsansNormalWhite16px,
    IbmplexsansNormalTuna16px,
} from "../../styledMixins";
import "./Services5.css";
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Services5(props) {
    const {t, i18n} = useTranslation();
    const {
        text194,
        sasTelecom,
        text195,
        sastelecom20042010,
        blueOpticFiberWithEthernetCables1,
        text199,
        dataCenterWithServerRacksCorridorRo,
        text201,
        text204,
        spanText1,
        spanText2,
        headerProps,
        breadcrumbsProps,
        feature21Props,
        feature22Props,
        feature23Props,
        feature24Props,
        feature25Props,
        feature26Props,
        footer4Props,
    } = props;

    return (
        <div className="container-center-horizontal">
            <div className="services screen">
                <Container><Header headerContentProps={headerProps.headerContentProps}/></Container>
                <Container><Breadcrumbs x3={breadcrumbsProps.x3} className={breadcrumbsProps.className}/></Container>
                <Container><Frame44>
                    <Content>
                        <AboutCompany>
                            <Text194>{text194}</Text194>
                            <Frame41>
                                <Row>
                                    <Col>
                                        <AboutCompany>
                                            <SASTelecom>{sasTelecom}</SASTelecom>
                                            <Frame42>
                                                <Text195>{text195}</Text195>
                                                <Features>
                                                    <Feature2 text155={feature21Props.text155}/>
                                                    <Feature2 text155={feature22Props.text155}/>
                                                    <Feature2 text155={feature23Props.text155}/>
                                                </Features>
                                            </Frame42>
                                            <Sastelecom20042010>{sastelecom20042010}</Sastelecom20042010>
                                        </AboutCompany></Col>
                                    <Col><BlueOpticFiberWithEthernetCables1
                                        src={blueOpticFiberWithEthernetCables1}
                                        alt="blue-optic-fiber-with-ethernet-cables 1"
                                    /></Col></Row>
                            </Frame41>
                        </AboutCompany>
                    </Content>
                    <Content1>
                        <AboutCompany>
                            <Text194>{text199}</Text194>
                            <Frame411>
                                <Row>
                                    <Col><DataCenterWithServerRacksCorridorRo
                                        src={dataCenterWithServerRacksCorridorRo}
                                        alt="data-center-with-server-racks-corridor-room-3d-render-digital-data-cloud-technology 1"
                                    /></Col>
                                    <Col>
                                        <Frame43>
                                            <SASTelecom>
                                                {t('service.text5')} <br/> <br/>
                                                Гарантированным называется канал с гарантированной (т.е. постоянной)
                                                скоростью передачи данных. Как
                                                обеспечивается эта гарантированная скорость? <br/> <br/>
                                                Представим себе две точки, соединенные физическим каналом связи. На его
                                                основе с помощью
                                                специального оборудования получается электрический канал связи,
                                                обладающий
                                                определенной пропускной
                                                способностью. В результате получается информационный канал с некоторой
                                                граничной скоростью. Когда
                                                один физический канал используется несколькими пользователями, общий
                                                информационный канал делится на
                                                несколько субканалов. Внутри каждого из этих субканалов гарантированно
                                                поддерживается определенная
                                                скорость. В некоторых случаях граничная скорость внутри субканала может
                                                быть
                                                даже увеличена, если
                                                ресурсы других субканалов свободны. <br/> <br/>
                                                Гарантированный канал, как правило, является симметричным. Это означает,
                                                что
                                                скорость входящего и
                                                исходящего трафика При передаче больших объемов данных в обоих
                                                направлениях
                                                скорость никогда не
                                                снижается.
                                            </SASTelecom>
                                            <Frame42>
                                                <Text195>{text201}</Text195>
                                                <Features1>
                                                    <Feature2 text155={feature24Props.text155}/>
                                                    <Feature2 text155={feature25Props.text155}/>
                                                    <Feature2 text155={feature26Props.text155}
                                                              className={feature26Props.className}/>
                                                </Features1>
                                            </Frame42>
                                        </Frame43>
                                    </Col>
                                </Row>
                            </Frame411>
                        </AboutCompany>
                    </Content1>
                    <Content2>
                        <AboutCompany>
                            <Text194>{text204}</Text194>
                            <Frame41>
                                <Frame431>
                                    <TextLabel>
                                        <span className="ibmplexsans-normal-tuna-16px">{spanText1}</span>
                                        <Span16>{spanText2}</Span16>
                                    </TextLabel>
                                </Frame431>
                            </Frame41>
                        </AboutCompany>
                    </Content2>
                </Frame44></Container>
                <Container><Footer4
                    className={footer4Props.className}
                    button32Props={footer4Props.button32Props}
                    button32Props2={footer4Props.button32Props2}
                /></Container>
            </div>
        </div>
    );
}

const Frame44 = styled.div`
  display: flex;
  align-self: center;
  margin-top: 36px;
  flex-direction: column;
  //width: fit-content;
  align-items: flex-start;
  gap: 48px;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 64px;
  border: 1px none;
`;

const AboutCompany = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text194 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Frame41 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const SASTelecom = styled.p`
  ${IbmplexsansNormalTuna16px} //width: 760px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Frame42 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 760px;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text195 = styled.div`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  //width: 760px;
  //height: 160px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Sastelecom20042010 = styled.p`
  ${IbmplexsansNormalTuna16px} //width: 760px;
  letter-spacing: 0;
  line-height: 20px;
`;

const BlueOpticFiberWithEthernetCables1 = styled.img`
  //min-width: 368px;
  //height: 575px;
  object-fit: cover;
`;

const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  //height: 779px;
  align-items: flex-start;
  gap: 64px;
  border: 1px none;
`;

const Frame411 = styled.div`
  display: flex;
  width: fit-content;
  //height: 711px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const DataCenterWithServerRacksCorridorRo = styled.img`
  //min-width: 368px;
  //height: 711px;
  object-fit: cover;
`;

const Frame43 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  //height: 711px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Features1 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 760px;
  //height: 252px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 1152px;
  align-items: flex-start;
  gap: 64px;
  border: 1px none;
`;

const Frame431 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 1152px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const TextLabel = styled.p`
  ${IbmplexsansNormalWhite16px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Span16 = styled.span`
  font-family: var(--font-family-ibm_plex_sans);
  font-weight: 400;
  color: var(--accentbluedark);
  font-size: var(--font-size-m);
`;

export default Services5;
