import React from "react";
import styled from "styled-components";
import { IbmplexsansBoldTuna24px } from "../../styledMixins";
import {useTranslation} from "react-i18next";

function Heading2() {
  const { t, i18n } = useTranslation();
  return (
    <Heading>
      <Text120>{t('connectservice.text1')}</Text120>
    </Heading>
  );
}

const Heading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border: 1px none;
`;

const Text120 = styled.div`
  ${IbmplexsansBoldTuna24px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Heading1 = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border: 1px none;
`;

const Text405 = styled.div`
  ${IbmplexsansBoldTuna24px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

export default Heading2;
