import React from "react";
import Icon24Empty from "../Icon24Empty";
import Pagination22 from "../Pagination22";
import Inputs from "../Inputs";
import Pagination from "../Pagination";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky24px } from "../../styledMixins";
import "./AtomsMolecules.css";

function AtomsMolecules(props) {
  const {
    icons,
    checkboxes,
    radioButtons,
    labels,
    pagination1,
    tabs1,
    inputs1Props,
    inputs2Props,
    paginationProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="atoms-molecules screen">
        <FlexCol>
          <FlexRow>
            <Icons>
              <Icons1>{icons}</Icons1>
              <Icon24>
                <Icon24Empty />
                <IconLocationPin src="/img/icon-24-marker-pin-01-1@2x.svg" alt="icon-location_pin" />
                <IconLocationPin src="/img/icon-24-phone-1@2x.svg" alt="icon-call" />
                <IconLocationPin src="/img/icon-24-mail-01-1@2x.svg" alt="icon-mail" />
                <IconLocationPin src="/img/icon-24-check-1@2x.svg" alt="icon-check_mark" />
                <IconLocationPin src="/img/icon-24-search-md-1@2x.svg" alt="icon-search" />
                <IconLocationPin src="/img/icon-24-chevron-down-1@2x.svg" alt="Icon/24/chevron-down 1" />
                <IconLocationPin src="/img/icon-24-chevron-up-1@2x.svg" alt="Icon/24/chevron-up 1" />
                <IconLocationPin src="/img/icon-24-chevron-left-1@2x.svg" alt="Icon/24/chevron-left 1" />
                <IconLocationPin src="/img/icon-24-chevron-right-1@2x.svg" alt="Icon/24/chevron-right 1" />
                <IconLocationPin src="/img/icon-24-x-close-1@2x.svg" alt="icon-close" />
                <IconLocationPin src="/img/icon-24-hdd-network-1@2x.svg" alt="Icon/24/hdd-network 1" />
                <IconLocationPin src="/img/icon-24-wiredusb-1@2x.svg" alt="Icon/24/WiredUSB 1" />
                <IconLocationPin src="/img/icon-24-signal-02-1@2x.svg" alt="Icon/24/signal-02 1" />
                <IconLocationPin src="/img/icon-24-menu-01-1@2x.svg" alt="Icon/24/menu-01 1" />
                <IconLocationPin src="/img/icon-24-globe@2x.svg" alt="icon-globe" />
              </Icon24>
              <Icon32>
                <Icon32modem021 src="/img/icon-32-modem-02-1@2x.svg" alt="Icon/32/modem-02 1" />
                <Icon32modem021 src="/img/icon-32-speedometer-03-1@2x.svg" alt="icon-clock" />
                <Icon32modem021 src="/img/icon-32-clock-check-1@2x.svg" alt="icon-clock" />
                <Icon32modem021 src="/img/icon-32-signal-02-1@2x.svg" alt="icon-volume" />
                <Icon32modem021 src="/img/icon-32-hdd-network-1@2x.svg" alt="Icon/32/hdd-network 1" />
                <Icon32modem021 src="/img/icon-32-wiredusb-1@2x.svg" alt="Icon/32/WiredUSB 1" />
                <Pagination22 />
                <Icon32modem021 src="/img/icon-32-message-chat-square-1@2x.svg" alt="Icon/32/message-chat-square 1" />
                <Icon32modem021 src="/img/icon-32-bxl-telegram-1@2x.svg" alt="icon-paper_plane" />
                <Icon32modem021 src="/img/icon-32-bxl-whatsapp-1@2x.svg" alt="Icon/32/bxl-whatsapp 1" />
                <Icon32modem021 src="/img/icon-32-bxl-instagram@2x.svg" alt="icon-instagram" />
                <Icon32modem021 src="/img/icon-32-x-close-1@2x.svg" alt="icon-close" />
              </Icon32>
            </Icons>
            <Logo src="/img/logo@2x.svg" alt="Logo" />
          </FlexRow>
          <Inputs inputs={inputs1Props.inputs} />
          <Inputs inputs={inputs2Props.inputs} className={inputs2Props.className} />
        </FlexCol>
        <FlexRow1>
          <FlexCol1>
            <Checkbox>
              <Icons1>{checkboxes}</Icons1>
              <Frame51>
                <CheckboxAtoms src="/img/checkbox-atoms@2x.svg" alt="Checkbox_atoms" />
                <Checkbox1 src="/img/checkbox@2x.svg" alt="Checkbox" />
              </Frame51>
            </Checkbox>
            <RadioButtons>
              <Icons1>{radioButtons}</Icons1>
              <Frame51>
                <CheckboxAtoms src="/img/radio-atoms@2x.svg" alt="Radio_atoms" />
                <RadioButton src="/img/radio-button@2x.svg" alt="Radio button" />
              </Frame51>
            </RadioButtons>
          </FlexCol1>
          <FlexCol2>
            <FlexRow2>
              <Labels1>
                <Labels>{labels}</Labels>
                <Label src="/img/label@2x.svg" alt="Label" />
                <Label1 src="/img/label-1@2x.svg" alt="Label" />
              </Labels1>
              <Pagination1>
                <Labels>{pagination1}</Labels>
                <Pagination2 src="/img/pagination@2x.svg" alt="Pagination" />
                <Pagination
                  pagination31Props={paginationProps.pagination31Props}
                  pagination31Props2={paginationProps.pagination31Props2}
                  pagination32Props={paginationProps.pagination32Props}
                  pagination33Props={paginationProps.pagination33Props}
                  pagination34Props={paginationProps.pagination34Props}
                  pagination2Props={paginationProps.pagination2Props}
                />
              </Pagination1>
            </FlexRow2>
            <Labels1>
              <Icons1>{tabs1}</Icons1>
              <Frame51>
                <Tab src="/img/tab@2x.svg" alt="Tab" />
                <Tabs src="/img/tabs@2x.svg" alt="Tabs" />
              </Frame51>
            </Labels1>
          </FlexCol2>
        </FlexRow1>
      </div>
    </div>
  );
}

const FlexCol = styled.div`
  width: 1527px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 1642px;
`;

const FlexRow = styled.div`
  height: 156px;
  display: flex;
  align-items: center;
  min-width: 1151px;
  gap: 192px;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 35px;
  border: 1px none;
`;

const Icons1 = styled.div`
  ${IbmplexsansMediumPaleSky24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Labels = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Icon24 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const IconLocationPin = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Icon32 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Icon32modem021 = styled.img`
  min-width: 32px;
  height: 32px;
`;

const Logo = styled.img`
  width: 335px;
  height: 79px;
  margin-bottom: 11px;
`;

const FlexRow1 = styled.div`
  height: 745px;
  margin-left: 3px;
  display: flex;
  align-items: flex-start;
  min-width: 1151px;
  gap: 202px;
`;

const FlexCol1 = styled.div`
  width: 269px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 729px;
  gap: 223px;
`;

const Checkbox = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 30px;
  border: 1px none;
`;

const Frame51 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const CheckboxAtoms = styled.img`
  min-width: 64px;
  height: 108px;
`;

const Checkbox1 = styled.img`
  min-width: 181px;
  height: 240px;
`;

const RadioButtons = styled.div`
  display: flex;
  margin-left: 3px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const RadioButton = styled.img`
  min-width: 140px;
  height: 152px;
`;

const FlexCol2 = styled.div`
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 745px;
  gap: 92px;
`;

const FlexRow2 = styled.div`
  ${IbmplexsansMediumPaleSky24px}
  height: 431px;
  margin-left: 2px;
  display: flex;
  align-items: flex-start;
  min-width: 656px;
  gap: 204px;
`;

const Labels1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Label = styled.img`
  min-width: 174px;
  height: 240px;
`;

const Label1 = styled.img`
  min-width: 204px;
  height: 113px;
`;

const Pagination1 = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Pagination2 = styled.img`
  min-width: 192px;
  height: 259px;
`;

const Tab = styled.img`
  min-width: 222px;
  height: 144px;
`;

const Tabs = styled.img`
  min-width: 434px;
  height: 168px;
`;

export default AtomsMolecules;
