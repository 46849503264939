import React from "react";
import Speed from "../Speed";
import Technology from "../Technology";
import Connection3 from "../Connection3";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function CardTop(props) {
  const { speedProps, technologyProps, connection3Props } = props;

  return (
    <CardTop1>
      <C>c.Бейнеу</C>
      <Text65>Тарифный план на предоставление доступа к сети интернет для села Бейнеу</Text65>
      <Speed text56={speedProps.text56} />
      <Services>
        <Technology labelProps={technologyProps.labelProps} />
        <Connection3 labelProps={connection3Props.labelProps} />
      </Services>
    </CardTop1>
  );
}

const CardTop1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 311px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 16px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const C = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text65 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

export default CardTop;
