import React from "react";
import Speed from "../Speed";
import Services from "../Services";
import CardBottom3 from "../CardBottom3";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function Card23(props) {
  const { text63, text64, speedProps, speedProps2, cardBottom3Props } = props;

  return (
    <Card2>
      <CardTop>
        <Text43>{text63}</Text43>
        <Text44>{text64}</Text44>
        <Speed text56={speedProps.text56} />
        <Services
          text27={speedProps2.text27}
          technologyProps={speedProps2.technologyProps}
          connection32Props={speedProps2.connection32Props}
        />
      </CardTop>
      <CardBottom3 address={cardBottom3Props.address} button2Props={cardBottom3Props.button2Props} />
    </Card2>
  );
}

const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 16px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const Text43 = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text44 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

const Card21 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const CardTop1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 16px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const Text105 = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text106 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Card23;
