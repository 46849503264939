import React from "react";
import Services22 from "../Services22";
import Frame3 from "../Frame3";
import X1 from "../X1";
import Contacts from "../Contacts";
import Email2 from "../Email2";
import Frame32 from "../Frame32";
import Bottom from "../Bottom";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky16px, IbmplexsansSemiBoldTuna16px } from "../../styledMixins";


function Footer3(props) {
  const { text439, text443, text444, text445, frame3Props, frame32Props } = props;

  return (
    <Footer>
      <FlexCol>
        <FlexRow>
          <ServicesCTA>
            <Services22 />
            <Frame3 button32Props={frame3Props.button32Props} button6Props={frame3Props.button6Props} />
          </ServicesCTA>
          <Company>
            <Text439>{text439}</Text439>
            <Content>
              <X1 />
              <X2>
                <Text443>{text443}</Text443>
                <Text444>{text444}</Text444>
                <Text445>{text445}</Text445>
              </X2>
            </Content>
          </Company>
          <Contacts1>
            <Frame33>
              <Contacts />
              <Email2 />
            </Frame33>
            <Frame32 frame29Props={frame32Props.frame29Props} />
          </Contacts1>
        </FlexRow>
        <Rectangle9></Rectangle9>
        <Bottom />
      </FlexCol>
    </Footer>
  );
}

const Footer = styled.div`
  min-width: 1440px;
  height: 359px;
  background-color: var(--contentextralight);
  border: 1px none;
`;

const FlexCol = styled.div`
  position: relative;
  width: 1152px;
  top: 48px;
  left: 144px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 295px;
`;

const FlexRow = styled.div`
  height: 230px;
  display: flex;
  align-items: flex-start;
  min-width: 1152px;
`;

const ServicesCTA = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 32px;
  border: 1px none;
`;

const Company = styled.div`
  display: flex;
  margin-left: 119px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text439 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text443 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 58px;
  position: relative;
  border: 1px none;
`;

const X2 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text444 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
`;

const Text445 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts1 = styled.div`
  display: flex;
  position: relative;
  margin-left: 154px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Frame33 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Rectangle9 = styled.div`
  width: 1152px;
  height: 1px;
  margin-top: 30px;
  background-color: var(--domlight);
  border: 1px none;
`;

export default Footer3;
