import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky16px, IbmplexsansSemiBoldTuna16px } from "../../styledMixins";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Services22() {
  const { t, i18n } = useTranslation();
  return (
    <Services>
      <Text9>{t('footer.text1')}</Text9>
      <Content>
        <Link to="/blog-page-gpon"><Text10>{t('footer.text2')}</Text10></Link>
        <Link to="/blog-page-price-table"><Text11>{t('footer.text3')}</Text11></Link>
        <Link to="/blog"><Text11>{t('footer.text4')}</Text11></Link>
      </Content>
    </Services>
  );
}

const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text9 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text10 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text11 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text136 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text137 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content1 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text138 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text150 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text151 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content2 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text152 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text172 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text173 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content3 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text174 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services4 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text188 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text189 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content4 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text190 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services5 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text213 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text214 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content5 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text215 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services6 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text234 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text235 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content6 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text236 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services7 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text248 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text249 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content7 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text250 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services8 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text281 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text282 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content8 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text283 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services9 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text332 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text333 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content9 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text334 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services10 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text369 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text370 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content10 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text371 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Services11 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text447 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text448 = styled.div`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content11 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text449 = styled.div`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Services22;
