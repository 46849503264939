import React from "react";
import styled from "styled-components";


function RadioAtoms(props) {
  const { className } = props;

  return (
    <RadioAtoms1 className={`radio_atoms ${className || ""}`}>
      <Content className="content-1">
        <Ellipse24 className="ellipse-24"></Ellipse24>
      </Content>
    </RadioAtoms1>
  );
}

const RadioAtoms1 = styled.div`
  min-width: 24px;
  height: 24px;
  border: 1px none;
`;

const Content = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  top: 3px;
  left: 3px;
  display: flex;
  padding: 0 6px;
  align-items: center;
  background-color: var(--contentprimary);
  border-radius: 16px;
  overflow: hidden;
  border: 1px none;
`;

const Ellipse24 = styled.div`
  width: 6px;
  height: 6px;
  background-color: var(--domdop);
  border-radius: 3px;
  border: 1px none;
`;

const Content1 = styled.div`
  .radio_atoms.radio_atoms-1 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content2 = styled.div`
  .radio_atoms.radio_atoms-2 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content3 = styled.div`
  .radio_atoms.radio_atoms-3 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content4 = styled.div`
  .radio_atoms.radio_atoms-4 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content5 = styled.div`
  .radio_atoms.radio_atoms-5 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content6 = styled.div`
  .radio_atoms.radio_atoms-6 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content7 = styled.div`
  .radio_atoms.radio_atoms-7 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content8 = styled.div`
  .radio_atoms.radio_atoms-8 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

const Content9 = styled.div`
  .radio_atoms.radio_atoms-9 & {
    background-color: var(--domdop);
    border: 1px solid;
    border-color: var(--accentbluelight);
  }
`;

export default RadioAtoms;
