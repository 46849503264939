import React from "react";
import styled from "styled-components";


function Frame29(props) {
  const { className, iconImg } = props;

  return (
    <Frame291 className={`frame-29 ${className || ""}`}>
      <Icon32bxlWhatsapp1
        className="icon32bxl-whatsapp-1"
        src={iconImg}
        alt="Icon/32/bxl-whatsapp 1"
      />
    </Frame291>
  );
}

const Frame291 = styled.div`
  position: relative;
  min-width: 34px;
  height: 34px;
  background-color: var(--accentblue);
  border-radius: 36px;
  overflow: hidden;
  border: 1px none;
`;

const Icon32bxlWhatsapp1 = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 5px;
  left: 5px;
`;

const IconPaperPlane = styled.img`
  .frame-29.frame-31 & {
    left: 4px;
  }
`;

const IconPaperPlane1 = styled.img`
  .frame-29.frame-31-1 & {
    left: 4px;
  }
`;

const IconPaperPlane2 = styled.img`
  .frame-29.frame-31-2 & {
    left: 4px;
  }
`;

const IconPaperPlane3 = styled.img`
  .frame-29.frame-31-3 & {
    left: 4px;
  }
`;

const IconPaperPlane4 = styled.img`
  .frame-29.frame-31-4 & {
    left: 4px;
  }
`;

const IconPaperPlane5 = styled.img`
  .frame-29.frame-31-5 & {
    left: 4px;
  }
`;

const IconPaperPlane6 = styled.img`
  .frame-29.frame-31-6 & {
    left: 4px;
  }
`;

const IconPaperPlane7 = styled.img`
  .frame-29.frame-31-7 & {
    left: 4px;
  }
`;

const IconPaperPlane8 = styled.img`
  .frame-29.frame-31-8 & {
    left: 4px;
  }
`;

const IconPaperPlane9 = styled.img`
  .frame-29.frame-31-9 & {
    left: 4px;
  }
`;

const IconPaperPlane10 = styled.img`
  .frame-29.frame-31-10 & {
    left: 4px;
  }
`;

const IconPaperPlane11 = styled.img`
  .frame-29.frame-31-11 & {
    left: 4px;
  }
`;

const IconPaperPlane12 = styled.img`
  .frame-29.frame-31-12 & {
    left: 4px;
  }
`;

export default Frame29;
