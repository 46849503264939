import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function FormRequest(props) {
    const {active, setActive} = props;

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [city, setCity] = useState('');
    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleTel = (e) => {
        setTel(e.target.value)
    }
    const handleCity = (e) => {
        setCity(e.target.value)
    }

    const clk = () => {
        fetch(`https://new.sas-telecom.kz/mailer.php?name=${name}&tel=${tel}&city=${city}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
            });
        setActive(false)
    }

    return (
        <Modal show={active} onHide={() => setActive(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Заявка на подключение</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" onChange={handleName} placeholder="Ваше имя"/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="tel" onChange={handleTel} placeholder="Телефон"/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" onChange={handleTel} placeholder="Населенный пункт"/>
                </Form.Group>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={clk}>
                    Отправить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FormRequest;