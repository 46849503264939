import React from "react";
import RadioAtoms from "../RadioAtoms";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function RadioButton2(props) {
  const { radioText, radioAtomsProps } = props;

  return (
    <RadioButton>
      <RadioAtoms className={radioAtomsProps.className} />
      <RadioText>{radioText}</RadioText>
    </RadioButton>
  );
}

const RadioButton = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const RadioText = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default RadioButton2;
