import React from "react";
import Header from "../Header";
import Breadcrumbs2 from "../Breadcrumbs2";
import ImagesNews from "../ImagesNews";
import Input3 from "../Input3";
import Input4 from "../Input4";
import Checkbox from "../Checkbox";
import Button4 from "../Button4";
import Footer4 from "../Footer4";
import styled from "styled-components";
import { IbmplexsansBoldTuna34px, IbmplexsansNormalWhite16px, IbmplexsansBoldTuna24px } from "../../styledMixins";
import "./NewsPage.css";

function NewsPage(props) {
  const {
    text375,
    spanText1,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    text377,
    headerProps,
    breadcrumbs2Props,
    imagesNewsProps,
    input31Props,
    input32Props,
    button4Props,
    footer4Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="news-page screen">
        <Header headerContentProps={headerProps.headerContentProps} />
        <Breadcrumbs2 x1={breadcrumbs2Props.x1} x2={breadcrumbs2Props.x2} x3={breadcrumbs2Props.x3} />
        <X1>
          <Text375>{text375}</Text375>
          <X11>
            <ImagesNews src={imagesNewsProps.src} className={imagesNewsProps.className} />
            <TextLabel>
              <span className="ibmplexsans-normal-tuna-16px">{spanText1}</span>
              <span className="ibmplexsans-medium-curious-blue-16px">{spanText2}</span>
            </TextLabel>
          </X11>
        </X1>
        <Frame4>
          <Text376>
            <Span015>{spanText3}</Span015>
            <Span115>{spanText4}</Span115>
            <Span22>{spanText5}</Span22>
          </Text376>
          <Request>
            <Frame1>
              <Text377>{text377}</Text377>
            </Frame1>
            <Inputs>
              <Input3>{input31Props.children}</Input3>
              <Input3>{input32Props.children}</Input3>
              <Input4 />
              <Checkbox />
            </Inputs>
            <Button4 className={button4Props.className}>{button4Props.children}</Button4>
          </Request>
        </Frame4>
        <Footer4
          className={footer4Props.className}
          button32Props={footer4Props.button32Props}
          button32Props2={footer4Props.button32Props2}
        />
      </div>
    </div>
  );
}

const X1 = styled.div`
  display: flex;
  margin-top: 36px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text375 = styled.p`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const X11 = styled.div`
  display: flex;
  width: 1152px;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const TextLabel = styled.p`
  ${IbmplexsansNormalWhite16px}
  width: 674px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Frame4 = styled.div`
  display: flex;
  margin-top: 38px;
  margin-right: 1px;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 24px;
  border: 1px none;
`;

const Text376 = styled.p`
  width: 623px;
  margin-top: -1px;
  font-family: var(--font-family-ibm_plex_sans);
  color: transparent;
  font-size: var(--font-size-l);
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Span015 = styled.span`
  font-weight: 400;
  color: var(--contentprimary);
`;

const Span115 = styled.span`
  font-weight: 500;
  color: var(--contentprimary);
`;

const Span22 = styled.span`
  font-weight: 600;
  color: var(--accentblue);
`;

const Request = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  position: relative;
  background-color: var(--contentextralight);
  border-radius: 24px;
  border: 1px none;
`;

const Frame1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text377 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: 330px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  border: 1px none;
`;

export default NewsPage;
