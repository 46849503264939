import React from "react";
import Image from "../Image";
import Label3 from "../Label3";
import styled from "styled-components";
import { IbmplexsansMediumTuna16px } from "../../styledMixins";


function CardNews3(props) {
  const { text55, imageProps } = props;

  return (
    <CardNews>
      <Image imagesNewsProps={imageProps.imagesNewsProps} />
      <Content>
        <TextContent>
          <Label3 />
          <Text221>{text55}</Text221>
        </TextContent>
      </Content>
    </CardNews>
  );
}

const CardNews = styled.div`
  display: flex;
  flex-direction: column;
  width: 368px;
  align-items: flex-start;
  position: relative;
  background-color: var(--domdop);
  border-radius: 16px;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 368px;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border: 1px none;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  align-self: stretch;
  border: 1px none;
`;

const Text221 = styled.p`
  ${IbmplexsansMediumTuna16px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 20px;
`;

export default CardNews3;
