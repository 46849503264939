import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumLoblolly16px } from "../../styledMixins";


function Input2(props) {
  const { children, className } = props;

  return (
    <Input className={`input-4 ${className || ""}`}>
      <Button className="button-37">
        <Button1 className="button-38">{children}</Button1>
      </Button>
    </Input>
  );
}

const Input = styled.div`
  display: flex;
  flex-direction: column;
  width: 235px;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;

  &.input-4.input-5 {
    width: 137px;
  }

  &.input-4.input-6 {
    width: 137px;
  }

  &.input-4.input-7 {
    width: 265px;
  }

  &.input-4.input-8 {
    width: 166px;
  }

  &.input-4.input-9 {
    width: 166px;
  }

  &.input-4.input-11 {
    width: 137px;
  }

  &.input-4.input-12 {
    width: 137px;
  }

  &.input-4.input-13 {
    width: 137px;
  }

  &.input-4.input-14 {
    width: 137px;
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 14px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentextralight);
`;

const Button1 = styled.div`
  ${IbmplexsansMediumLoblolly16px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Input2;
