import React from "react";
import Technology from "../Technology";
import Connection from "../Connection";
import styled from "styled-components";


function Services2(props) {
  const { technologyProps, connectionProps } = props;

  return (
    <Services>
      <Technology labelProps={technologyProps.labelProps} />
      <Connection text7={connectionProps.text7} labelProps={connectionProps.labelProps} />
    </Services>
  );
}

const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

const Services1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

export default Services2;
