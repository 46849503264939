import React from "react";
import ImagesNews from "../ImagesNews";
import Content from "../Content";
import styled from "styled-components";


function CardNews232(props) {
  const {
    content1Props5,
    content1Props2,
    content1Props52,
    content2Props,
    content1Props53,
    content3Props,
    content1Props54,
    content4Props,
  } = props;

  return (
    <CardNews2>
      <CardNews21>
        <Image>
          <ImagesNews src={content1Props5.src} className={content1Props5.className} />
        </Image>
        <Content text21={content1Props2.text21} text22={content1Props2.text22} />
      </CardNews21>
      <CardNews21>
        <Image>
          <ImagesNews src={content1Props52.src} className={content1Props52.className} />
        </Image>
        <Content text21={content2Props.text21} text22={content2Props.text22} />
      </CardNews21>
      <CardNews21>
        <Image>
          <ImagesNews src={content1Props53.src} className={content1Props53.className} />
        </Image>
        <Content text21={content3Props.text21} text22={content3Props.text22} />
      </CardNews21>
      <CardNews21>
        <Image>
          <ImagesNews src={content1Props54.src} className={content1Props54.className} />
        </Image>
        <Content text21={content4Props.text21} text22={content4Props.text22} />
      </CardNews21>
    </CardNews2>
  );
}

const CardNews2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const CardNews21 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Image = styled.div`
  position: relative;
  min-width: 270px;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px none;
`;

export default CardNews232;
