import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Pagination3(props) {
  const { children } = props;

  return (
    <Pagination>
      <Number>{children}</Number>
    </Pagination>
  );
}

const Pagination = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 7px 6px;
  border: 1px none;
`;

const Number = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 20px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Pagination1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 7px 6px;
  border: 1px none;
`;

const Number1 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 20px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Pagination2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 7px 6px;
  border: 1px none;
`;

const Number2 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 20px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default Pagination3;
