import React from "react";
import RadioAtoms from "../RadioAtoms";
import styled from "styled-components";
import { IbmplexsansMediumTuna14px } from "../../styledMixins";


function RadioButton(props) {
  const { radioText } = props;

  return (
    <RadioButton1>
      <RadioAtoms />
      <RadioText>{radioText}</RadioText>
    </RadioButton1>
  );
}

const RadioButton1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const RadioText = styled.div`
  ${IbmplexsansMediumTuna14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default RadioButton;
