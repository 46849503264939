import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky24px } from "../../styledMixins";


function Inputs(props) {
  const { inputs, className } = props;

  return (
    <Inputs1 className={`inputs-8 ${className || ""}`}>
      <Inputs2 className="inputs-9">{inputs}</Inputs2>
      <Input className="input-19" src="/img/input@1x.svg" alt="Input" />
    </Inputs1>
  );
}

const Inputs1 = styled.div`
  display: flex;
  margin-top: 77px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;

  &.inputs-8.buttons {
    margin-top: 73px;
  }
`;

const Inputs2 = styled.div`
  ${IbmplexsansMediumPaleSky24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Input = styled.img`
  min-width: 1527px;
  height: 630px;
`;

const Button = styled.img`
  .inputs-8.buttons & {
    height: 598px;
  }
`;

export default Inputs;
