import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import Input from "../Input";
import Input2 from "../Input2";
import Button4 from "../Button4";
import Footer4 from "../Footer4";
import styled from "styled-components";
import {IbmplexsansBoldTuna34px, IbmplexsansNormalTuna16px} from "../../styledMixins";
import "./CheckCoverage.css";
import {Map, YMaps, Placemark} from "react-yandex-maps";

function CheckCoverage(props) {
    const {
        text178,
        text179,
        map,
        headerProps,
        breadcrumbsProps,
        inputProps,
        input21Props,
        input22Props,
        input23Props,
        button4Props,
        footer4Props,
    } = props;

    return (
        <div className="container-center-horizontal">
            <div className="check-coverage screen">
                <Header headerContentProps={headerProps.headerContentProps}/>
                <Breadcrumbs x3={breadcrumbsProps.x3} className={breadcrumbsProps.className}/>
                <Text178>{text178}</Text178>
                <CheckConnection>
                    <Content>
                        <Content>
                            <Text179>{text179}</Text179>
                        </Content>
                        <Inputs>
                            <Input className={inputProps.className}/>
                            <Input2 className={input21Props.className}>{input21Props.children}</Input2>
                            <Input2 className={input22Props.className}>{input22Props.children}</Input2>
                            <Input2 className={input23Props.className}>{input23Props.children}</Input2>
                            <Button4>{button4Props.children}</Button4>
                        </Inputs>
                    </Content>
                </CheckConnection>
                {/*<Map style={{ backgroundImage: `url(${map})` }}></Map>*/}
                <YMaps>
                    <Map height={574} width={1151} defaultState={{center: [43.619040, 51.214293], zoom: 12}}>
                        <Placemark geometry={[43.619040, 51.214293]}/>
                    </Map>
                </YMaps>
                <Footer4
                    className={footer4Props.className}
                    button32Props={footer4Props.button32Props}
                    button32Props2={footer4Props.button32Props2}
                />
            </div>
        </div>
    );
}

const Text178 = styled.div`
  ${IbmplexsansBoldTuna34px}
  margin-top: 35px;
  margin-left: 144px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const CheckConnection = styled.div`
  display: flex;
  align-self: center;
  margin-top: 27px;
  width: fit-content;
  align-items: center;
  padding: 24px;
  background-color: var(--domdop);
  border-radius: 24px;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text179 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Inputs = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Map = styled.div`
  width: 1151px;
  height: 574px;
  align-self: center;
  margin-top: 16px;
  margin-right: 1px;
  background-size: cover;
  background-position: 50% 50%;
`;

export default CheckCoverage;
