import React from "react";
import Speed from "../Speed";
import Services from "../Services";
import CardBottom3 from "../CardBottom3";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function Card2(props) {
  const { text22, text23, speedProps, servicesProps, cardBottom3Props } = props;

  return (
    <Card21>
      <CardTop>
        <Text22>{text22}</Text22>
        <Text23>{text23}</Text23>
        <Speed text56={speedProps.text56} />
        <Services
          text27={servicesProps.text27}
          technologyProps={servicesProps.technologyProps}
          connection32Props={servicesProps.connection32Props}
        />
      </CardTop>
      <CardBottom3 address={cardBottom3Props.address} button2Props={cardBottom3Props.button2Props} />
    </Card21>
  );
}

const Card21 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 16px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const Text22 = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text23 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Card2;
