import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumTuna24px, IbmplexsansNormalTuna16px } from "../../styledMixins";


function X2(props) {
  const { gpon1, gpon2, className } = props;

  return (
    <X21 className={`x2-6 ${className || ""}`}>
      <GPON className="gpon-1">{gpon1}</GPON>
      <GPON1 className="gpon-2">{gpon2}</GPON1>
    </X21>
  );
}

const X21 = styled.div`
  display: flex;
  margin-top: 38px;
  margin-right: 1px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;

  &.x2-6.x3-5 {
    margin-top: 37px;
    margin-right: unset;
  }
`;

const GPON = styled.p`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const GPON1 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: 1151px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Ontsastelecomwifigponont = styled.p`
  ${IbmplexsansNormalTuna16px}

  .x2-6.x3-5  & {
    width: 1152px;
  }
`;

export default X2;
