import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumTuna16px } from "../../styledMixins";


function Frame72(props) {
  const { children, className } = props;

  return (
    <Frame7 className={`frame-7-3 ${className || ""}`}>
      <Phone className="phone-15">{children}</Phone>
    </Frame7>
  );
}

const Frame7 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;

  &.frame-7-3.frame-10-1 {
    gap: 4px;
  }

  &.frame-7-3.frame-10-2 {
    gap: 4px;
  }

  &.frame-7-3.frame-10-3 {
    gap: 4px;
  }
`;

const Phone = styled.div`
  ${IbmplexsansMediumTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Frame72;
