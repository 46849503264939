import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalPaleSky16px, IbmplexsansBoldTuna24px } from "../../styledMixins";
import {useTranslation} from "react-i18next";

function HowToConnect() {
    const { t, i18n } = useTranslation();
  return (
    <HowToConnect1>

      <Text121>{t('howconnect.text1')}</Text121>
      <Text122>
        {t('howconnect.text2')} <br />{t('howconnect.text3')} <br />{t('howconnect.text4')}
      </Text122>
    </HowToConnect1>
  );
}

const HowToConnect1 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 466px;
  height: 283px;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 24px 48px;
  background-color: var(--domdop);
  border-radius: 24px;
  overflow: hidden;
  border: 1px none;
`;

const Text121 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: 330px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text122 = styled.p`
  ${IbmplexsansNormalPaleSky16px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: normal;
`;

const HowToConnect2 = styled.div`
  display: flex;
  flex-direction: column;
  //width: 466px;
  height: 283px;
  align-items: flex-start;
  gap: 24px;
  padding: 24px 24px 48px;
  background-color: var(--domdop);
  border-radius: 24px;
  overflow: hidden;
  border: 1px none;
`;

const Text406 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: 330px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text407 = styled.p`
  ${IbmplexsansNormalPaleSky16px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: normal;
`;

export default HowToConnect;
