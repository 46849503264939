import React from "react";
import Pagination22 from "../Pagination22";
import Pagination3 from "../Pagination3";
import styled from "styled-components";


function Pagination(props) {
  const {
    pagination31Props,
    pagination31Props2,
    pagination32Props,
    pagination33Props,
    pagination34Props,
    pagination2Props,
  } = props;

  return (
    <Pagination1>
      <Pagination22 className={pagination31Props.className} />
      <Numbers>
        <Pagination3>{pagination31Props2.children}</Pagination3>
        <Pagination3>{pagination32Props.children}</Pagination3>
        <Pagination3>{pagination33Props.children}</Pagination3>
        <Pagination3>{pagination34Props.children}</Pagination3>
      </Numbers>
      <Pagination22 className={pagination2Props.className} />
    </Pagination1>
  );
}

const Pagination1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Numbers = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Pagination2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Numbers1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Pagination4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Numbers2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

export default Pagination;
