import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import RadioButton from "../RadioButton";
import RadioButton2 from "../RadioButton2";
import Card2 from "../Card2";
import Card22 from "../Card22";
import Card23 from "../Card23";
import Card29 from "../Card29";
import Card25 from "../Card25";
import Card26 from "../Card26";
import Card27 from "../Card27";
import Footer4 from "../Footer4";
import styled from "styled-components";
import {IbmplexsansBoldTuna34px, IbmplexsansBoldTuna24px, IbmplexsansSemiBoldTuna14px} from "../../styledMixins";
import "./TariffPageOptics.css";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TariffPageOptics(props) {
    const {
        text15,
        text16,
        text17,
        text18,
        text19,
        text20,
        text21,
        text48,
        text64,
        text85,
        headerProps,
        breadcrumbsProps,
        radioButtonProps,
        radioButton21Props,
        radioButton22Props,
        radioButton23Props,
        radioButton24Props,
        radioButton25Props,
        radioButton26Props,
        radioButton27Props,
        radioButton28Props,
        radioButton29Props,
        card2Props,
        card221Props,
        card222Props,
        card223Props,
        card23Props,
        card291Props,
        card292Props,
        card293Props,
        card251Props,
        card252Props,
        card253Props,
        card261Props,
        card262Props,
        card271Props,
        card272Props,
        footer4Props,
    } = props;

    return (
        <div className="container-center-horizontal">
            <div className="tariff-page-optics screen">
                <Container><Header headerContentProps={headerProps.headerContentProps}/></Container>
                <Container><Breadcrumbs x3={breadcrumbsProps.x3}/></Container>
                <Container><Heading>
                    <Text15>{text15}</Text15>
                </Heading></Container>
                <Container><Frame27>
                    <Filters>
                        <Filters1><Row><Col>
                            <Technology>
                                <Text16>{text16}</Text16>
                                <Radio>
                                    <Link to="/tariff-page-optics"><RadioButton radioText={radioButtonProps.radioText}/></Link>
                                    <Link to="/tariff-page-radio"><RadioButton2
                                        radioText={radioButton21Props.radioText}
                                        radioAtomsProps={radioButton21Props.radioAtomsProps}
                                    /></Link>
                                </Radio>
                            </Technology></Col><Col>
                            <Technology>
                                <Text16>{text17}</Text16>
                                <Radio>
                                    <RadioButton2
                                        radioText={radioButton22Props.radioText}
                                        radioAtomsProps={radioButton22Props.radioAtomsProps}
                                    />
                                    <RadioButton2
                                        radioText={radioButton23Props.radioText}
                                        radioAtomsProps={radioButton23Props.radioAtomsProps}
                                    />
                                </Radio>
                            </Technology></Col><Col>
                            <Technology>
                                <Text16>{text18}</Text16>
                                <Radio>
                                    <RadioButton2
                                        radioText={radioButton24Props.radioText}
                                        radioAtomsProps={radioButton24Props.radioAtomsProps}
                                    />
                                    <RadioButton2
                                        radioText={radioButton25Props.radioText}
                                        radioAtomsProps={radioButton25Props.radioAtomsProps}
                                    />
                                </Radio>
                            </Technology></Col><Col>
                            <Technology>
                                <Text16>{text19}</Text16>
                                <Radio>
                                    <RadioButton2
                                        radioText={radioButton26Props.radioText}
                                        radioAtomsProps={radioButton26Props.radioAtomsProps}
                                    />
                                    <RadioButton2
                                        radioText={radioButton27Props.radioText}
                                        radioAtomsProps={radioButton27Props.radioAtomsProps}
                                    />
                                </Radio>
                            </Technology></Col><Col>
                            <Technology>
                                <Text16>{text20}</Text16>
                                <Radio>
                                    <RadioButton2
                                        radioText={radioButton28Props.radioText}
                                        radioAtomsProps={radioButton28Props.radioAtomsProps}
                                    />
                                    <RadioButton2
                                        radioText={radioButton29Props.radioText}
                                        radioAtomsProps={radioButton29Props.radioAtomsProps}
                                    />
                                </Radio>
                            </Technology></Col>
                        </Row></Filters1>
                    </Filters>
                    <Content>
                        <City>
                            <Text21>{text21}</Text21>
                            <X3><Row><Col>
                                <Card2
                                    text22={card2Props.text22}
                                    text23={card2Props.text23}
                                    speedProps={card2Props.speedProps}
                                    servicesProps={card2Props.servicesProps}
                                    cardBottom3Props={card2Props.cardBottom3Props}
                                /></Col><Col>
                                <Card22
                                    address={card221Props.address}
                                    speedProps={card221Props.speedProps}
                                    technologyProps={card221Props.technologyProps}
                                    connection32Props={card221Props.connection32Props}
                                    button2Props={card221Props.button2Props}
                                /></Col><Col>
                                <Card22
                                    address={card222Props.address}
                                    speedProps={card222Props.speedProps}
                                    technologyProps={card222Props.technologyProps}
                                    connection32Props={card222Props.connection32Props}
                                    button2Props={card222Props.button2Props}
                                /></Col></Row>
                            </X3>
                            <X4><Row><Col>
                                <Card22
                                    address={card223Props.address}
                                    className={card223Props.className}
                                    speedProps={card223Props.speedProps}
                                    technologyProps={card223Props.technologyProps}
                                    connection32Props={card223Props.connection32Props}
                                    button2Props={card223Props.button2Props}
                                /></Col><Col>
                                <Card23
                                    text63={card23Props.text63}
                                    text64={card23Props.text64}
                                    speedProps={card23Props.speedProps}
                                    speedProps2={card23Props.speedProps2}
                                    cardBottom3Props={card23Props.cardBottom3Props}
                                /></Col>
                            </Row></X4>
                        </City>
                        <City>
                            <Text21>{text48}</Text21>
                            <X5><Row><Col>
                                <Card29
                                    text54={card291Props.text54}
                                    text55={card291Props.text55}
                                    speedProps={card291Props.speedProps}
                                    speedProps2={card291Props.speedProps2}
                                    cardBottom3Props={card291Props.cardBottom3Props}
                                /></Col><Col>
                                <Card29
                                    text54={card292Props.text54}
                                    text55={card292Props.text55}
                                    speedProps={card292Props.speedProps}
                                    speedProps2={card292Props.speedProps2}
                                    cardBottom3Props={card292Props.cardBottom3Props}
                                /></Col><Col>
                                <Card29
                                    text54={card293Props.text54}
                                    text55={card293Props.text55}
                                    speedProps={card293Props.speedProps}
                                    speedProps2={card293Props.speedProps2}
                                    cardBottom3Props={card293Props.cardBottom3Props}
                                /></Col>
                            </Row></X5>
                        </City>
                        <City>
                            <Text21>{text64}</Text21>
                            <X5><Row><Col>
                                <Card25 cardTopProps={card251Props.cardTopProps}
                                        cardBottom3Props={card251Props.cardBottom3Props}/></Col><Col>
                                <Card25 cardTopProps={card252Props.cardTopProps}
                                        cardBottom3Props={card252Props.cardBottom3Props}/></Col><Col>
                                <Card25 cardTopProps={card253Props.cardTopProps}
                                        cardBottom3Props={card253Props.cardBottom3Props}/></Col>
                            </Row></X5>
                            <X5><Row><Col>
                                <Card26
                                    address={card261Props.address}
                                    cardTopProps={card261Props.cardTopProps}
                                    button2Props={card261Props.button2Props}
                                /></Col><Col>
                                <Card26
                                    address={card262Props.address}
                                    cardTopProps={card262Props.cardTopProps}
                                    button2Props={card262Props.button2Props}
                                /></Col>
                            </Row></X5>
                        </City>
                        <City1>
                            <Text21>{text85}</Text21>
                            <X31><Row><Col>
                                <Card27
                                    speedProps={card271Props.speedProps}
                                    services2Props={card271Props.services2Props}
                                    cardBottom3Props={card271Props.cardBottom3Props}
                                /></Col><Col>
                                <Card27
                                    speedProps={card272Props.speedProps}
                                    services2Props={card272Props.services2Props}
                                    cardBottom3Props={card272Props.cardBottom3Props}
                                /></Col>
                            </Row></X31>
                        </City1>
                    </Content>
                </Frame27></Container>
                <Container><Footer4 button32Props={footer4Props.button32Props}
                                    button32Props2={footer4Props.button32Props2}/></Container>
            </div>
        </div>
    );
}

const Heading = styled.div`
  height: 2%;
  margin-top: 48px;
  display: flex;
  align-items: flex-start;
  //min-width: 1152px;
  border: 1px none;
`;

const Text15 = styled.h1`
  ${IbmplexsansBoldTuna34px}
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Frame27 = styled.div`
  display: flex;
  margin-top: 36px;
  flex-direction: column;
  //width: fit-content;
  align-items: flex-start;
  gap: 36px;
  border: 1px none;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  background-color: var(--domextralight);
  border-radius: 16px;
  border: 1px none;
`;

const Filters1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 60px;
  border: 1px none;
`;

const Technology = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  border: 1px none;
`;

const Text16 = styled.div`
  ${IbmplexsansSemiBoldTuna14px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const Radio = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 48px;
  border: 1px none;
`;

const City = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text21 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const X3 = styled.div`
  display: flex;
  //width: 1152px;
  //height: 534px;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const X4 = styled.div`
  display: flex;
  //width: 1152px;
  //height: 498px;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const X5 = styled.div`
  display: flex;
  //width: 1152px;
  //height: 475px;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const City1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  //height: 513px;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const X31 = styled.div`
  display: flex;
  //width: 1152px;
  //height: 459px;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

export default TariffPageOptics;
