import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalPaleSky16px, IbmplexsansSemiBoldPaleSky16px } from "../../styledMixins";


function Breadcrumbs(props) {
  const { x3, className } = props;

  return (
    <Breadcrumbs1 className={`breadcrumbs ${className || ""}`}>
      <X1 className="x1-1">Главная</X1>
      <Icon24chevronRight2
        className="icon24chevron-right-2"
        src="/img/icon-24-chevron-right-2@2x.svg"
        alt="Icon/24/chevron-right 2"
      />
      <X3 className="x3-2">{x3}</X3>
    </Breadcrumbs1>
  );
}

const Breadcrumbs1 = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 48px;
  margin-left: 5%;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px none;

  &.breadcrumbs.breadcrumbs-1 {
    align-self: unset;
  }

  &.breadcrumbs.breadcrumbs-2 {
    align-self: unset;
  }

  &.breadcrumbs.breadcrumbs-3 {
    align-self: unset;
  }

  &.breadcrumbs.breadcrumbs-4 {
    align-self: unset;
  }

  &.breadcrumbs.breadcrumbs-5 {
    align-self: unset;
  }

  &.breadcrumbs.breadcrumbs-6 {
    align-self: unset;
  }
`;

const X1 = styled.div`
  ${IbmplexsansNormalPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X3 = styled.div`
  ${IbmplexsansSemiBoldPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Icon24chevronRight2 = styled.img`
  min-width: 24px;
  height: 24px;
`;

export default Breadcrumbs;
