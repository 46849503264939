import React from "react";
import Input3 from "../Input3";
import styled from "styled-components";
import Form from "react-bootstrap/Form";


function NamePhone(props) {
    const {input31Props, input32Props} = props;

    return (
        <NamePhone1>
            {/*<Input3>{input31Props.children}</Input3>*/}
            {/*<Input3>{input32Props.children}</Input3>*/}
            <Form.Control className="sc-byhhpF jbiyCH" type="text" placeholder="Ваше имя"/>
            <Form.Control className="sc-byhhpF jbiyCH" type="text" placeholder="Телефон"/>

        </NamePhone1>
    );
}

const NamePhone1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 1;
  border: 1px none;
`;

const NamePhone2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 1;
  border: 1px none;
`;

export default NamePhone;
