import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalPaleSky16px, IbmplexsansSemiBoldPaleSky16px } from "../../styledMixins";


function Breadcrumbs2(props) {
  const { x1, x2, x3, className } = props;

  return (
    <Breadcrumbs className={`breadcrumbs-7 ${className || ""}`}>
      <X1 className="x1-8">{x1}</X1>
      <Icon24chevronRight1
        className="icon24chevron-right-1-1"
        src="/img/icon-24-chevron-right-2@2x.svg"
        alt="Icon/24/chevron-right 1"
      />
      <X1 className="x2-5">{x2}</X1>
      <Icon24chevronRight1
        className="icon24chevron-right-2-1"
        src="/img/icon-24-chevron-right-2@2x.svg"
        alt="Icon/24/chevron-right 2"
      />
      <X3 className="x3-4">{x3}</X3>
    </Breadcrumbs>
  );
}

const Breadcrumbs = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 48px;
  margin-left: 144px;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px none;

  &.breadcrumbs-7.breadcrumbs-8 {
    align-self: unset;
  }

  &.breadcrumbs-7.breadcrumbs-10 {
    align-self: unset;
    margin-top: unset;
    margin-left: unset;
  }
`;

const X1 = styled.div`
  ${IbmplexsansNormalPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X3 = styled.div`
  ${IbmplexsansSemiBoldPaleSky16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Icon24chevronRight1 = styled.img`
  min-width: 24px;
  height: 24px;
`;

export default Breadcrumbs2;
