import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky12px, IbmplexsansMediumTuna16px } from "../../styledMixins";


function Content(props) {
  const { text21, text22 } = props;

  return (
    <Content1>
      <Text113>{text21}</Text113>
      <Text114>{text22}</Text114>
    </Content1>
  );
}

const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text113 = styled.div`
  ${IbmplexsansMediumPaleSky12px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

const Text114 = styled.p`
  ${IbmplexsansMediumTuna16px}
  width: 270px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Content2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text339 = styled.div`
  ${IbmplexsansMediumPaleSky12px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

const Text340 = styled.p`
  ${IbmplexsansMediumTuna16px}
  width: 270px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Content3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text398 = styled.div`
  ${IbmplexsansMediumPaleSky12px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

const Text399 = styled.p`
  ${IbmplexsansMediumTuna16px}
  width: 270px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Content4 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text432 = styled.div`
  ${IbmplexsansMediumPaleSky12px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

const Text433 = styled.p`
  ${IbmplexsansMediumTuna16px}
  width: 270px;
  letter-spacing: 0;
  line-height: 20px;
`;

export default Content;
