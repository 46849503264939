import React, {useState} from "react";
import styled from "styled-components";
import {IbmplexsansSemiBoldWhite16px} from "../../styledMixins";
import FormRequest from "../FormRequest";


function Button3(props) {
    const {children, className} = props;
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <a href="#" onClick={handleShow}><Button className={`button-26 ${className || ""}`}>
                <Button1 className="button-27">{children}</Button1>
            </Button></a>
            <FormRequest active={show} setActive={setShow}/></div>
    );
}

const Button = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 11px;
  padding: 14px 22px;
  background-color: var(--contentprimary);
  border-radius: 36px;
  border: 1px none;

  &.button-26.button-28 {
    margin-top: 37px;
    margin-left: 1px;
  }

  &.button-26.button-29 {
    align-self: center;
    margin-top: 49px;
    margin-left: 48px;
  }

  &.button-26.button-30 {
    align-self: stretch;
    width: unset;
  }
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldWhite16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Button3;
