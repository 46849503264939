import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumTuna14px } from "../../styledMixins";


function Checkbox3(props) {
  const { checkboxText, className } = props;

  return (
    <Checkbox className={`checkbox-7 ${className || ""}`}>
      <CheckboxAtoms className="checkbox_atoms-1" src="/img/checkbox-atoms@2x.svg" alt="Checkbox_atoms" />
      <CheckboxText className="checkbox-text-1">{checkboxText}</CheckboxText>
    </Checkbox>
  );
}

const Checkbox = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;

  &.checkbox-7.checkbox-8 {
    margin-left: 25px;
  }

  &.checkbox-7.checkbox-9 {
    margin-left: 32px;
  }
`;

const CheckboxAtoms = styled.img`
  min-width: 24px;
  height: 24px;
`;

const CheckboxText = styled.div`
  ${IbmplexsansMediumTuna14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

export default Checkbox3;
