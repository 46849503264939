import React from "react";
import Button2 from "../Button2";
import styled from "styled-components";
import { IbmplexsansMediumTuna24px } from "../../styledMixins";


function CardBottom3(props) {
  const { address, button2Props } = props;

  return (
    <CardBottom>
      <PriceAndSale>
        <Address>{address}</Address>
      </PriceAndSale>
      <Button2 className={button2Props.className}>{button2Props.children}</Button2>
    </CardBottom>
  );
}

const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  padding: 16px 24px 24px;
  position: relative;
  background-color: var(--domtruewhite);
  border-radius: 0px 0px 24px 24px;
  border: 1px none;
`;

const PriceAndSale = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Address = styled.div`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const CardBottom1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  padding: 16px 24px 24px;
  position: relative;
  background-color: var(--domtruewhite);
  border-radius: 0px 0px 24px 24px;
  border: 1px none;
`;

const PriceAndSale1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Address1 = styled.div`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

export default CardBottom3;
