import React from "react";
import styled from "styled-components";


function Menu3() {
  return (
    <Menu>
      <Icon24menu011 src="/img/icon-24-menu-01-1@2x.svg" alt="Icon/24/menu-01 1" />
    </Menu>
  );
}

const Menu = styled.div`
  width: 36px;
  height: 36px;
  margin-left: 17px;
  display: flex;
  padding: 0 6px;
  align-items: center;
  background-color: var(--domdop);
  border-radius: 6px;
  overflow: hidden;
  border: 1px none;
`;

const Icon24menu011 = styled.img`
  width: 24px;
  height: 24px;
`;

export default Menu3;
