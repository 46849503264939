import React, {useState} from "react";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky14px } from "../../styledMixins";
import { useTranslation } from 'react-i18next';
function Language(props) {
  const { className } = props;
  const {t, i18n} = useTranslation()
  const changeLang = (lang) => {
      i18n.changeLanguage(lang)
  }

  return (
    <Language1 className={`language ${className || ""}`}>
      <Icon24globe className="icon24globe" src="/img/icon-24-globe@2x.svg" alt="Icon/24/globe" />
        {
            localStorage.getItem('i18nextLng') == 'ru' ? (
                <a onClick={() => {changeLang('kk')}}><Language2 className="language-1">Казакша</Language2></a>
            ) : (
                <a onClick={() => {changeLang('ru')}}><Language2 className="language-1">Русский</Language2></a>
            )
        }

    </Language1>
  );
}

const Language1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;

  &.language.language-3 {
    margin-right: 18px;
  }
`;

const Icon24globe = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Language2 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

export default Language;
