import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumWhite16px } from "../../styledMixins";


function Content22() {
  return (
    <Content>
      <Phone>
        <span className="ibmplexsans-medium-pale-sky-16px">Актау:</span>
        <span className="ibmplexsans-medium-tuna-16px">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+7 (777) 632-19-19
        </span>
      </Phone>
      <Phone1>
        <span className="ibmplexsans-medium-pale-sky-16px">Жанаозен:</span>
        <span className="ibmplexsans-medium-tuna-16px">&nbsp;&nbsp; +7 (771) 587-19-19</span>
      </Phone1>
      <Phone1>
        <span className="ibmplexsans-medium-pale-sky-16px">Бейнеу:</span>
        <span className="ibmplexsans-medium-tuna-16px">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +7 (705) 466-24-19
        </span>
      </Phone1>
      <Phone1>
        <span className="ibmplexsans-medium-pale-sky-16px">Шымкент:</span>
        <span className="ibmplexsans-medium-tuna-16px">&nbsp;&nbsp;&nbsp;&nbsp;+7 (705) 783-06-80</span>
      </Phone1>
    </Content>
  );
}

const Content = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone1 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content1 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone2 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone3 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content2 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone4 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone5 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content3 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone6 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone7 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content4 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone8 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone9 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content5 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone10 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone11 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content6 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone12 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone13 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content7 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone14 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone15 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content8 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone16 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone17 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content9 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone18 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone19 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content10 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone20 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone21 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Content11 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Phone22 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone23 = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Content22;
