import React from "react";
import CardNews22 from "../CardNews22";
import styled from "styled-components";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CardNews24(props) {
    const {cardNews221Props, cardNews222Props, cardNews223Props, cardNews224Props} = props;

    return (
        <CardNews2>
            <Container fluid="xxl">
                <Row>
                    <Col>
                        <CardNews22
                            imagesNewsProps={cardNews221Props.imagesNewsProps}
                            imagesNewsProps2={cardNews221Props.imagesNewsProps2}
                        /></Col>
                    <Col><CardNews22
                        imagesNewsProps={cardNews222Props.imagesNewsProps}
                        imagesNewsProps2={cardNews222Props.imagesNewsProps2}
                    /></Col>
                    <Col><CardNews22
                        imagesNewsProps={cardNews223Props.imagesNewsProps}
                        imagesNewsProps2={cardNews223Props.imagesNewsProps2}
                    /></Col>
                    <Col><CardNews22
                        imagesNewsProps={cardNews224Props.imagesNewsProps}
                        imagesNewsProps2={cardNews224Props.imagesNewsProps2}
                    /></Col>
                </Row></Container>
        </CardNews2>
    );
}

const CardNews2 = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const CardNews21 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const CardNews23 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

export default CardNews24;
