import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumWhite14px } from "../../styledMixins";


function Checkbox(props) {
  const { className } = props;

  return (
    <Checkbox1 className={`checkbox ${className || ""}`}>
      <CheckboxAtoms className="checkbox_atoms" src="/img/checkbox-atoms@2x.svg" alt="Checkbox_atoms" />
      <CheckboxText className="checkbox-text">
        <span className="span0-4 ibmplexsans-medium-tuna-14px">Я соглашусь на </span>
        <span className="span1-4 ibmplexsans-medium-curious-blue-14px">условия обработки данных</span>
      </CheckboxText>
    </Checkbox1>
  );
}

const Checkbox1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;

  &.checkbox.checkbox-1 {
    flex: 1;
    width: unset;
  }

  &.checkbox.checkbox-4 {
    flex: 1;
    width: unset;
  }
`;

const CheckboxAtoms = styled.img`
  min-width: 24px;
  height: 24px;
`;

const CheckboxText = styled.p`
  ${IbmplexsansMediumWhite14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

const CheckboxText1 = styled.p`
  ${IbmplexsansMediumWhite14px}

  .checkbox.checkbox-1  & {
    flex: 1;
    margin-top: -1px;
    width: unset;
    white-space: unset;
  }
`;

const CheckboxAtoms1 = styled.img`
  .checkbox.checkbox-3 & {
    margin-top: -3171.5px;
    margin-left: -16474px;
  }
`;

const CheckboxAtoms2 = styled.img`
  .checkbox.checkbox-4 & {
    margin-top: -3897px;
    margin-left: -16162px;
  }
`;

const CheckboxText2 = styled.p`
  ${IbmplexsansMediumWhite14px}

  .checkbox.checkbox-4  & {
    flex: 1;
    margin-top: -1px;
    width: unset;
    white-space: unset;
  }
`;

export default Checkbox;
