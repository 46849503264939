import React from "react";
import Header from "../Header";
import MainBg from "../MainBg";
import Services6 from "../Services6";
import Chat from "../Chat";
import Card29 from "../Card29";
import Card222 from "../Card222";
import Card23 from "../Card23";
import Button3 from "../Button3";
import CheckConnection from "../CheckConnection";
import Button6 from "../Button6";
import CardNews24 from "../CardNews24";
import Request from "../Request";
import HowToConnect from "../HowToConnect";
import Footer2 from "../Footer2";
import styled from "styled-components";
import {IbmplexsansBoldTuna34px} from "../../styledMixins";
import "./MainPage.css";
import {Link} from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ThemeProvider from "react-bootstrap/ThemeProvider";

function MainPage(props) {
    const {
        text95,
        text112,
        headerProps,
        mainBgProps,
        services6Props,
        card29Props,
        card222Props,
        card23Props,
        button3Props,
        checkConnectionProps,
        cardNews24Props,
        requestProps,
        footer2Props,
    } = props;

    return (
        // <ThemeProvider
        //     breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        //     minBreakpoint="xxs"
        // >
            <div className="container-center-horizontal">
                <div className="main-page screen">

                    <Container><Header headerContentProps={headerProps.headerContentProps}/></Container>
                    <Container><MainBg buttonProps={mainBgProps.buttonProps}/></Container>
                    <Container><OverlapGroup>
                         <Services6
                            service1Props={services6Props.service1Props}
                            service2Props={services6Props.service2Props}
                            service3Props={services6Props.service3Props}
                        />

                        <a target="_blank" href="https://wa.me/+77058838649"><Chat/></a>
                    </OverlapGroup></Container>
                    <Container><Tariff>
                        <Text95>{text95}</Text95>
                        <FlexRow>
                            {/*<Icon24chevronLeft1 src="/img/icon-24-chevron-left-1@2x.svg" alt="Icon/24/chevron-left 1"/>*/}
                            <Frame18>
                                <Container>

                                <Carousel variant="dark" controls={true}>
                                    <Carousel.Item>

                                        <Cards>
                                            <Row>
                                            <Col>
                                            <Card29
                                                text54={card29Props.text54}
                                                text55={card29Props.text55}
                                                speedProps={card29Props.speedProps}
                                                speedProps2={card29Props.speedProps2}
                                                cardBottom3Props={card29Props.cardBottom3Props}
                                            /></Col>
                                            <Col>
                                            <Card222
                                                c={card222Props.c}
                                                text59={card222Props.text59}
                                                speedProps={card222Props.speedProps}
                                                technology2Props={card222Props.technology2Props}
                                                connection2Props={card222Props.connection2Props}
                                                cardBottom3Props={card222Props.cardBottom3Props}
                                            /></Col>
                                            <Col>
                                            <Card23
                                                text63={card23Props.text63}
                                                text64={card23Props.text64}
                                                speedProps={card23Props.speedProps}
                                                speedProps2={card23Props.speedProps2}
                                                cardBottom3Props={card23Props.cardBottom3Props}
                                            /></Col>
                                            </Row>
                                        </Cards>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Cards>
                                            <Row>
                                                <Col>
                                            <Card29
                                                text54={card29Props.text54}
                                                text55={card29Props.text55}
                                                speedProps={card29Props.speedProps}
                                                speedProps2={card29Props.speedProps2}
                                                cardBottom3Props={card29Props.cardBottom3Props}
                                            /></Col><Col>
                                            <Card222
                                                c={card222Props.c}
                                                text59={card222Props.text59}
                                                speedProps={card222Props.speedProps}
                                                technology2Props={card222Props.technology2Props}
                                                connection2Props={card222Props.connection2Props}
                                                cardBottom3Props={card222Props.cardBottom3Props}
                                            /></Col><Col>
                                            <Card23
                                                text63={card23Props.text63}
                                                text64={card23Props.text64}
                                                speedProps={card23Props.speedProps}
                                                speedProps2={card23Props.speedProps2}
                                                cardBottom3Props={card23Props.cardBottom3Props}
                                            /></Col>
                                            </Row>
                                        </Cards>
                                    </Carousel.Item>
                                </Carousel>

                                    </Container>
                                <Link to="tariff-page-optics"><Button3>{button3Props.children}</Button3></Link>
                            </Frame18>

                            {/*<Icon24chevronLeft2 src="/img/icon-24-chevron-left-2@2x.svg" alt="Icon/24/chevron-left 2"/>*/}
                        </FlexRow>
                    </Tariff></Container>
                    <Container><CheckConnection {...checkConnectionProps} /></Container>
                    <Container><News2>
                        <Heading>
                            <Text112>{text112}</Text112>
                            <Button6/>
                        </Heading>
                        <CardNews24
                            cardNews221Props={cardNews24Props.cardNews221Props}
                            cardNews222Props={cardNews24Props.cardNews222Props}
                            cardNews223Props={cardNews24Props.cardNews223Props}
                            cardNews224Props={cardNews24Props.cardNews224Props}
                        />
                    </News2></Container>
                    <Container><Connect>
                        <Row><Col>
                        <Request
                            namePhoneProps={requestProps.namePhoneProps}
                            input3Props={requestProps.input3Props}
                            checkboxProps={requestProps.checkboxProps}
                            button4Props={requestProps.button4Props}
                        /></Col><Col>
                        <HowToConnect/></Col></Row>
                    </Connect></Container>
                    <Container><Footer2
                        text87={footer2Props.text87}
                        text91={footer2Props.text91}
                        text92={footer2Props.text92}
                        frame3Props={footer2Props.frame3Props}
                        frame32Props={footer2Props.frame32Props}
                    /></Container>
                </div>
            </div>
        //</ThemeProvider>
    )
        ;
}

const OverlapGroup = styled.div`
  //width: 1152px;
  //height: 411px;
  height: 100%;
  position: relative;
  margin-top: 11px;
`;

const Tariff = styled.div`
  //width: 1440px;
  //height: 718px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding: 47px 0;
  align-items: flex-start;
  gap: 39px;
  background-color: var(--domdop);
  border: 1px none;
`;

const Text95 = styled.div`
  ${IbmplexsansBoldTuna34px}
  margin-left: 4%;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const FlexRow = styled.div`
  //height: 537px;
  align-self: center;
  display: flex;
  align-items: center;
  //min-width: 1186px;
`;

const Icon24chevronLeft1 = styled.img`
  width: 47px;
  height: 47px;
  margin-bottom: 118px;
`;

const Frame18 = styled.div`
  display: flex;
  position: relative;
  margin-left: 16px;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  gap: 36px;
  border: 1px none;
`;

const Cards = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const Icon24chevronLeft2 = styled.img`
  width: 47px;
  height: 47px;
  margin-left: 17px;
  margin-bottom: 118px;
`;

const News2 = styled.div`
  display: flex;
  position: relative;
  margin-top: 48px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 36px;
  //padding: 36px 144px;
  padding: 36px 0px;
  background-color: var(--domdop);
  border: 1px none;
`;

const Heading = styled.div`
  display: flex;
  //width: 1152px;
  align-items: center;
  //gap: 862px;
  position: relative;
  border: 1px none;
`;

const Text112 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Connect = styled.div`
  display: flex;
  position: relative;
  margin-top: 48px;
  width: 100%;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
  flex-wrap: wrap;
`;

export default MainPage;
