import React from "react";
import styled from "styled-components";


function Chat() {
  return (
    <Chat1>
      <Icon32messageChatSquare1 src="/img/icon-32-message-chat-square-1@2x.svg" alt="Icon/32/message-chat-square 1" />
    </Chat1>
  );
}

const Chat1 = styled.div`
  position: absolute;
  height: 54px;
  top: 0;
  left: 80%;
  display: flex;
  padding: 0 11px;
  align-items: center;
  min-width: 54px;
  background-color: var(--accentred);
  border-radius: 100px;
  border: 1px none;
`;

const Icon32messageChatSquare1 = styled.img`
  width: 32px;
  height: 32px;
`;

export default Chat;
