import React from "react";
import styled from "styled-components";


function Pagination22(props) {
  const { className } = props;

  return (
    <Icon32Check className={`icon32-check ${className || ""}`}>
      <IconCheckMark className="icon-check_mark" src="/img/icon-24-check-1-1@2x.svg" alt="icon-check_mark" />
    </Icon32Check>
  );
}

const Icon32Check = styled.div`
  position: relative;
  min-width: 32px;
  height: 32px;
  background-color: var(--accentred);
  border-radius: 36px;
  overflow: hidden;
  border: 1px none;

  &.icon32-check.pagination {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 4px;
    background-color: var(--domsecondary);
    border-radius: 8px;
    position: unset;
    min-width: unset;
    height: unset;
    overflow: unset;
  }

  &.icon32-check.pagination-1 {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 4px;
    background-color: var(--accentblue);
    border-radius: 8px;
    position: unset;
    min-width: unset;
    height: unset;
    overflow: unset;
  }

  &.icon32-check.pagination-2 {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 4px;
    background-color: var(--domsecondary);
    border-radius: 8px;
    position: unset;
    min-width: unset;
    height: unset;
    overflow: unset;
  }

  &.icon32-check.pagination-3 {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 4px;
    background-color: var(--accentblue);
    border-radius: 8px;
    position: unset;
    min-width: unset;
    height: unset;
    overflow: unset;
  }

  &.icon32-check.pagination-4 {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 4px;
    background-color: var(--domsecondary);
    border-radius: 8px;
    position: unset;
    min-width: unset;
    height: unset;
    overflow: unset;
  }

  &.icon32-check.pagination-5 {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 10px;
    padding: 4px;
    background-color: var(--accentblue);
    border-radius: 8px;
    position: unset;
    min-width: unset;
    height: unset;
    overflow: unset;
  }
`;

const IconCheckMark = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
`;

const Icon24chevronLeft1 = styled.img`
  .icon32-check.pagination & {
    min-width: 24px;
    position: unset;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Icon24chevronRight1 = styled.img`
  .icon32-check.pagination-1 & {
    min-width: 24px;
    position: unset;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Icon24chevronLeft11 = styled.img`
  .icon32-check.pagination-2 & {
    min-width: 24px;
    position: unset;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Icon24chevronRight11 = styled.img`
  .icon32-check.pagination-3 & {
    min-width: 24px;
    position: unset;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Icon24chevronLeft12 = styled.img`
  .icon32-check.pagination-4 & {
    min-width: 24px;
    position: unset;
    width: unset;
    top: unset;
    left: unset;
  }
`;

const Icon24chevronRight12 = styled.img`
  .icon32-check.pagination-5 & {
    min-width: 24px;
    position: unset;
    width: unset;
    top: unset;
    left: unset;
  }
`;

export default Pagination22;
