import React from "react";
import CardTop from "../CardTop";
import CardBottom3 from "../CardBottom3";
import styled from "styled-components";


function Card25(props) {
  const { cardTopProps, cardBottom3Props } = props;

  return (
    <Card2>
      <CardTop
        speedProps={cardTopProps.speedProps}
        technologyProps={cardTopProps.technologyProps}
        connection3Props={cardTopProps.connection3Props}
      />
      <CardBottom3 address={cardBottom3Props.address} button2Props={cardBottom3Props.button2Props} />
    </Card2>
  );
}

const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

export default Card25;
