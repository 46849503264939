import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldLoblolly16px } from "../../styledMixins";


function Input3(props) {
  const { children } = props;

  return (
    <Input>
      <Button>
        <Button1>{children}</Button1>
      </Button>
    </Input>
  );
}

const Input = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px none;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 14px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentextralight);
`;

const Button1 = styled.div`
  ${IbmplexsansSemiBoldLoblolly16px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Input1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px none;
`;

const Button2 = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 14px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentextralight);
`;

const Button3 = styled.div`
  ${IbmplexsansSemiBoldLoblolly16px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Input2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px none;
`;

const Button4 = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 14px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentextralight);
`;

const Button5 = styled.div`
  ${IbmplexsansSemiBoldLoblolly16px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Input4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px none;
`;

const Button6 = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 14px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentextralight);
`;

const Button7 = styled.div`
  ${IbmplexsansSemiBoldLoblolly16px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Input3;
