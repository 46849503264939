import React from "react";
//import Map from "../Map";
import Heading4 from "../Heading4";
import Input from "../Input";
import Input2 from "../Input2";
import Button4 from "../Button4";
import Checkbox from "../Checkbox";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {YMaps, Map} from "react-yandex-maps";
import {useTranslation} from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function CheckConnection(props) {
    const {
        className,
        inputProps22,
        inputProps222,
        input21Props,
        input22Props,
        input23Props,
        button4Props,
        button4Props2,
    } = props;
    const {t, i18n} = useTranslation();
    return (
        <CheckConnection1 className={`check-connection ${className || ""}`}>
            <Row><Col md="auto">
                <YMaps>
                    <Map defaultState={{center: [43.635603, 51.168252], zoom: 9}}/>
                </YMaps></Col>
                {/*<Map src={inputProps22.src} className={inputProps22.className}/>*/}
                <Col><Content className="content-5">
                    <Heading4/>

                    <Inputs className="inputs">
                        <Row><Col>
                        <Form.Control className="sc-gtBfHm kZSZlT sc-hZjMBx hIdmEt " type="text"
                                      placeholder={t('checkaddress.text3')}/></Col>
                        <Col><Form.Control className="sc-kfNCuK ceAevO sc-gnGSMD kBICUW " type="text"
                                      placeholder={t('checkaddress.text4')}/></Col>
                        <Col><Form.Control className="sc-kfNCuK ceAevO sc-gnGSMD kBICUW " type="text"
                                      placeholder={t('checkaddress.text5')}/></Col>
                        <Col><Form.Control className="sc-kfNCuK ceAevO sc-gnGSMD kBICUW " type="text"
                                      placeholder={t('checkaddress.text6')}/></Col>
                        {/*<Input className={inputProps222.className} />*/}
                        {/*<Input2>{input21Props.children}</Input2>*/}
                        {/*<Input2 className={input22Props.className}>{input22Props.children}</Input2>*/}
                        {/*<Input2 className={input23Props.className}>{input23Props.children}</Input2>*/}
                        </Row>
                    </Inputs>

                    {/*<Button4>{button4Props.children}</Button4>*/}
                    {button4Props.children}
                    <Button
                        className="sc-dUipGc eECfEI sc-hsWlPz eOJevg">{button4Props.children}</Button>
                    <Form.Check
                        type="checkbox"
                        id="sdfjksdf"
                        className={button4Props2.className}
                        label={t('checkaddress.text8')}
                    />
                    {/*<Checkbox className={button4Props2.className}/>*/}
                </Content></Col>
            </Row>
        </CheckConnection1>
    );
}

const CheckConnection1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 48px;
  width: 100%;
  align-items: center;
  padding: 0px 24px 0px 0px;
  background-color: var(--domdop);
  border-radius: 16px;
  border: 1px none;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Inputs = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const CheckConnection2 = styled.div`
  .check-connection & {
    margin-top: unset;
  }
`;

const CheckConnection3 = styled.div`
  .check-connection & {
    margin-top: unset;
  }
`;

export default CheckConnection;
