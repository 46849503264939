import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldTuna20px } from "../../styledMixins";


function Speed(props) {
  const { text56 } = props;

  return (
    <Speed1>
      <IconClock src="/img/icon-32-speedometer-03-1@2x.svg" alt="icon-clock" />
      <Text24>{text56}</Text24>
    </Speed1>
  );
}

const Speed1 = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const IconClock = styled.img`
  min-width: 26px;
  height: 26px;
`;

const Text24 = styled.div`
  ${IbmplexsansSemiBoldTuna20px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Speed2 = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  border: 1px none;
`;

const IconClock1 = styled.img`
  min-width: 26px;
  height: 26px;
`;

const Text98 = styled.div`
  ${IbmplexsansSemiBoldTuna20px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default Speed;
