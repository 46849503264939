import React from "react";
import styled from "styled-components";
import { IbmplexsansSemiBoldTuna12px } from "../../styledMixins";


function Label(props) {
  const { children, className } = props;

  return (
    <Label1 className={`label ${className || ""}`}>
      <TextLabel className="text_label">{children}</TextLabel>
    </Label1>
  );
}

const Label1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 4px 8px;
  background-color: var(--accentyellowlight);
  border-radius: 16px;
  border: 1px none;

  &.label.label-1 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-3 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-4 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-5 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-6 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-7 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-8 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-9 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-10 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-11 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-12 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-13 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-14 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-15 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-16 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-17 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-18 {
    background-color: var(--accentgreenlight);
  }

  &.label.label-19 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-20 {
    background-color: var(--accentblueextralight);
  }

  &.label.label-21 {
    background-color: var(--accentblueextralight);
    justify-content: unset;
  }

  &.label.label-22 {
    background-color: var(--accentblueextralight);
    justify-content: unset;
  }

  &.label.label-23 {
    background-color: var(--accentblueextralight);
    justify-content: unset;
  }

  &.label.label-24 {
    background-color: var(--accentblueextralight);
    justify-content: unset;
  }
`;

const TextLabel = styled.div`
  ${IbmplexsansSemiBoldTuna12px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

export default Label;
