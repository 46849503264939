import React from "react";
import Technology from "../Technology";
import Connection32 from "../Connection32";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Services(props) {
  const { text27, technologyProps, connection32Props } = props;

  return (
    <Services1>
      <Technology labelProps={technologyProps.labelProps} />
      <Connection32 text16={connection32Props.text16} labelProps={connection32Props.labelProps} />
      <Text27>{text27}</Text27>
    </Services1>
  );
}

const Services1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

const Text27 = styled.p`
  ${IbmplexsansNormalTuna14px}
  width: 289px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Services2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

const WiFi = styled.p`
  ${IbmplexsansNormalTuna14px}
  width: 289px;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Services;
