import React from "react";
import styled from "styled-components";


function Icon24Empty() {
  return <Icon24Empty1></Icon24Empty1>;
}

const Icon24Empty1 = styled.div`
  min-width: 24px;
  height: 24px;
  background-color: #f0f1f3;
  border-radius: 12px;
  border: 1px solid;
  border-color: #e1e2e2;
`;

export default Icon24Empty;
