import React from "react";
import Input3 from "../Input3";
import Input4 from "../Input4";
import Checkbox from "../Checkbox";
import Button4 from "../Button4";
import styled from "styled-components";


function Request2(props) {
  const { text363, input31Props, input32Props, button4Props } = props;

  return (
    <Request>
      <Frame1>
        <Text416>{text363}</Text416>
        <Close>
          <IconClose src="/img/icon-24-x-close-1@2x.svg" alt="icon-close" />
        </Close>
      </Frame1>
      <Inputs>
        <Input3>{input31Props.children}</Input3>
        <Input3>{input32Props.children}</Input3>
        <Input4 />
        <Checkbox />
      </Inputs>
      <Button4 className={button4Props.className}>{button4Props.children}</Button4>
    </Request>
  );
}

const Request = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;
  position: relative;
  background-color: var(--dommain);
  border-radius: 24px;
  border: 1px none;
`;

const Frame1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text416 = styled.div`
  width: 330px;
  margin-top: -1px;
  font-family: var(--font-family-ibm_plex_sans);
  font-weight: 700;
  color: var(--black);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Close = styled.div`
  position: relative;
  min-width: 28px;
  height: 28px;
  background-color: var(--domsecondary);
  border-radius: 36px;
  overflow: hidden;
  border: 1px none;
`;

const IconClose = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 2px;
  left: 2px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  align-self: stretch;
  border: 1px none;
`;

export default Request2;
