import React from "react";
import styled from "styled-components";


function ImagesNews(props) {
  const { src, className } = props;

  return (
    <ImagesNews1 className={`images_news ${className || ""}`} style={{ backgroundImage: `url(${src})` }}></ImagesNews1>
  );
}

const ImagesNews1 = styled.div`
  width: 270px;
  height: 140px;
  border-radius: 8px;
  border: 1px none;
  background-size: cover;
  background-position: 50% 50%;

  &.images_news.gramoty {
    max-width: 350px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-1 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-2 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-3 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-4 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-5 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-6 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.gramoty-7 {
    min-width: 270px;
    height: 192px;
    //width: unset;
    border-radius: unset;
  }

  &.images_news.images_news-2 {
    width: 368px;
    height: 190px;
    border-radius: unset;
  }

  &.images_news.images_news-3 {
    width: 368px;
    height: 190px;
    border-radius: unset;
  }

  &.images_news.images_news-4 {
    width: 368px;
    height: 190px;
    border-radius: unset;
  }

  &.images_news.images_news-5 {
    width: 368px;
    height: 190px;
    border-radius: unset;
  }

  &.images_news.images_news-6 {
    width: 368px;
    height: 190px;
    border-radius: unset;
  }

  &.images_news.images_news-7 {
    width: 368px;
    height: 190px;
    border-radius: unset;
  }

  &.images_news.images_news-8 {
    min-width: 368px;
    height: 240px;
    width: unset;
  }

  &.images_news.images_news-9 {
    position: relative;
    top: -3389px;
    left: -16138px;
  }

  &.images_news.images_news-10 {
    position: relative;
    top: -3389px;
    left: -16432px;
  }

  &.images_news.images_news-11 {
    position: relative;
    top: -3389px;
    left: -16726px;
  }

  &.images_news.images_news-12 {
    position: relative;
    top: -3389px;
    left: -17020px;
  }
`;

export default ImagesNews;
