import React from "react";
import Speed from "../Speed";
import Technology from "../Technology";
import Connection32 from "../Connection32";
import Label2 from "../Label2";
import Button2 from "../Button2";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansMediumTuna24px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function Card22(props) {
  const { address, className, speedProps, technologyProps, connection32Props, button2Props } = props;

  return (
    <Card2 className={`card_2-1 ${className || ""}`}>
      <CardTop className="card_top-1">
        <Text28 className="text-28">ж/к “Дукат”</Text28>
        <Text29 className="text-29">
          Тарифный план на предоставление доступа к сети интернет для ж/к &#34;Дукат&#34;
        </Text29>
        <Speed text56={speedProps.text56} />
        <Services className="services-1">
          <Technology labelProps={technologyProps.labelProps} />
          <Connection32 text16={connection32Props.text16} labelProps={connection32Props.labelProps} />
        </Services>
      </CardTop>
      <CardBottom className="card_bottom-1">
        <PriceAndSale className="price-and-sale-1">
          <Label2 />
          <Address className="address-1">{address}</Address>
        </PriceAndSale>
        <Button2 className={button2Props.className}>{button2Props.children}</Button2>
      </CardBottom>
    </Card2>
  );
}

const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;

  &.card_2-1.card_2-3 {
    border-radius: 24px;
    overflow: hidden;
  }
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 311px;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 16px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const Text28 = styled.div`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text29 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  border: 1px none;
`;

const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  padding: 16px 24px 24px;
  position: relative;
  background-color: var(--domtruewhite);
  border-radius: 0px 0px 24px 24px;
  border: 1px none;
`;

const PriceAndSale = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Address = styled.div`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

export default Card22;
