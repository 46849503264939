import React from "react";
import Header from "../Header";
import Breadcrumbs from "../Breadcrumbs";
import Feature from "../Feature";
import X12 from "../X12";
import Footer4 from "../Footer4";
import styled from "styled-components";
import {IbmplexsansBoldTuna34px, IbmplexsansSemiBoldTuna16px} from "../../styledMixins";
import "./AboutCompany.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

function AboutCompany(props) {
    const {
        text156,
        spanText1,
        spanText2,
        image16,
        text157,
        rectangle86,
        text163,
        headerProps,
        breadcrumbsProps,
        feature1Props,
        feature2Props,
        feature3Props,
        feature4Props,
        feature5Props,
        x121Props,
        x122Props,
        footer4Props,
    } = props;

    return (
        <div className="container-center-horizontal">
            <div className="about-company screen">
                <Container><Header headerContentProps={headerProps.headerContentProps}/></Container>
                <Container><Breadcrumbs x3={breadcrumbsProps.x3} className={breadcrumbsProps.className}/></Container>
                <Container><Content>
                    <AboutCompany1>
                        <Row>
                            <Text156>{text156}</Text156>
                        </Row>
                        <Frame40>
                            <Row><Col>
                                <SASTelecom>
                                    <span className="ibmplexsans-semi-bold-tuna-16px">{spanText1}</span>
                                    <span className="ibmplexsans-normal-tuna-16px">{spanText2}</span>
                                </SASTelecom></Col><Col>
                                <Image16 src={image16} alt="image 16"/>
                            </Col>
                            </Row>
                        </Frame40>

                    </AboutCompany1>
                    <Preimushestva><Row>
                        {/*<FlexCol>*/}
                        <Col><Rectangle86 src={rectangle86} alt="Rectangle 86"/></Col>
                            <Col><Text157>{text157}</Text157>
                            <Features>
                                <Feature text216={feature1Props.text216}/>
                                <Feature text216={feature2Props.text216} className={feature2Props.className}/>
                                <Feature text216={feature3Props.text216}/>
                                <Feature text216={feature4Props.text216}/>
                                <Feature text216={feature5Props.text216}/>
                            </Features></Col>
                        {/*</FlexCol>*/}
                        </Row>
                    </Preimushestva>
                    <Row>
                    <AboutCompany1>
                        <Text156>{text163}</Text156>
                        <Gramoty>
                            <X12
                                imagesNews1Props={x121Props.imagesNews1Props}
                                imagesNews2Props={x121Props.imagesNews2Props}
                                imagesNews3Props={x121Props.imagesNews3Props}
                                imagesNews4Props={x121Props.imagesNews4Props}
                            />
                            <X12
                                imagesNews1Props={x122Props.imagesNews1Props}
                                imagesNews2Props={x122Props.imagesNews2Props}
                                imagesNews3Props={x122Props.imagesNews3Props}
                                imagesNews4Props={x122Props.imagesNews4Props}
                            />
                        </Gramoty>
                    </AboutCompany1>
                        </Row>
                </Content></Container>
                <Container><Footer4
                    className={footer4Props.className}
                    button32Props={footer4Props.button32Props}
                    button32Props2={footer4Props.button32Props2}
                /></Container>
            </div>
        </div>
    );
}

const Content = styled.div`
  display: flex;
  align-self: center;
  margin-top: 36px;
  flex-direction: column;
  //width: fit-content;
  //align-items: flex-start;
  gap: 64px;
  border: 1px none;
`;

const AboutCompany1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text156 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Frame40 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const SASTelecom = styled.p`
  ${IbmplexsansSemiBoldTuna16px}
  //width: 760px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

const Image16 = styled.img`
  min-width: 368px;
  height: 269px;
`;

const Preimushestva = styled.div`
  position: relative;
  //min-width: 1152px;
  //height: 312px;
  border: 1px none;
`;

const FlexCol = styled.div`
  position: relative;
  //width: 552px;
  top: -1px;
  left: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //min-height: 313px;
  gap: 19px;
`;

const Text157 = styled.div`
  ${IbmplexsansBoldTuna34px}
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Features = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  //width: 552px;
  //height: 252px;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Rectangle86 = styled.img`
  position: relative;
  //width: 564px;
  //height: 312px;
  width: 100%;
  height: auto;
  min-width: 350px;
  top: 0;
  left: 0;
`;

const Gramoty = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

export default AboutCompany;
