import styled, {css} from "styled-components";
import React, {useEffect} from "react";
import {Switch, BrowserRouter as Router, Route} from "react-router-dom";
import TariffPageOptics from "./components/TariffPageOptics";
import MainPage from "./components/MainPage";
import AboutCompany from "./components/AboutCompany";
import CheckCoverage from "./components/CheckCoverage";
import Services5 from "./components/Services5";
import Blog from "./components/Blog";
import BlogPageGPON from "./components/BlogPageGPON";
import BlogPagePriceTable from "./components/BlogPagePriceTable";
import Contacts2 from "./components/Contacts2";
import News from "./components/News";
import NewsPage from "./components/NewsPage";
import RequestPopupEnabled from "./components/RequestPopupEnabled";
import Organisms from "./components/Organisms";
import AtomsMolecules from "./components/AtomsMolecules";
import TariffPageRadio from "./components/TariffPageRadio";
import ThemeProvider from 'react-bootstrap/ThemeProvider'
import TestCom from "./components/TestCom";
import CardNews22 from "./components/CardNews22";
import Button6 from "./components/Button6";
import CardNews24 from "./components/CardNews24";
import {IbmplexsansBoldTuna34px} from "./styledMixins";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import Card29 from "./components/Card29";
import Card222 from "./components/Card222";
import Card23 from "./components/Card23";



function App() {


    const { t, i18n } = useTranslation();
const Text112 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: 100%;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const News2 = styled.div`
  display: flex;
  position: relative;
  margin-top: 48px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 36px;
  padding: 36px 132px;
  background-color: var(--domdop);
  border: 1px none;
`;
const Heading = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  //gap: 862px;
  position: relative;
  border: 1px none;
`;

const menuName1Data = {
    children: t('menu.title1'),
};
const menuName2Data = {
    children: t('menu.title2'),
};

const menuName3Data = {
    children: t('menu.title3'),
};

const menuName4Data = {
    children: t('menu.title4'),
};

const menuName5Data = {
    children: t('menu.title5'),
    className: "menu-name-2",
};

const menuName6Data = {
    children: t('menu.title6'),
};

const menu1Data = {
    menuName1Props: menuName1Data,
    menuName2Props: menuName2Data,
    menuName3Props: menuName3Data,
    menuName4Props: menuName4Data,
    menuName5Props: menuName5Data,
    menuName6Props: menuName6Data,
};

const button71Data = {
    children: t('header.text1'),
};

const button21Data = {
    children: t('header.text2'),
};

const cTAButtons1Data = {
    button7Props: button71Data,
    button2Props: button21Data,
};

const headerContent1Data = {
    menuName3Props: menu1Data,
    menuName6Props: cTAButtons1Data,
};

const header1Data = {
    headerContentProps: headerContent1Data,
};

const breadcrumbs1Data = {
    x3: t('menu.title3'),
};

const radioButtonData = {
    radioText: t('tariffs.text5'),
};

const radioAtoms2Data = {
    className: "radio_atoms-1",
};

const radioButton21Data = {
    radioText: t('tariffs.text7'),
    radioAtomsProps: radioAtoms2Data,
};

const radioAtoms3Data = {
    className: "radio_atoms-2",
};

const radioButton22Data = {
    radioText: "Дешевле",
    radioAtomsProps: radioAtoms3Data,
};

const radioAtoms4Data = {
    className: "radio_atoms-3",
};

const radioButton23Data = {
    radioText: "Дороже",
    radioAtomsProps: radioAtoms4Data,
};

const radioAtoms5Data = {
    className: "radio_atoms-4",
};

const radioButton24Data = {
    radioText: "от 100 Мбит/с",
    radioAtomsProps: radioAtoms5Data,
};

const radioAtoms6Data = {
    className: "radio_atoms-5",
};

const radioButton25Data = {
    radioText: "до 100 Мбит/с",
    radioAtomsProps: radioAtoms6Data,
};

const radioAtoms7Data = {
    className: "radio_atoms-6",
};

const radioButton26Data = {
    radioText: "до 5000 ₸/мес",
    radioAtomsProps: radioAtoms7Data,
};

const radioAtoms8Data = {
    className: "radio_atoms-7",
};

const radioButton27Data = {
    radioText: "от 5000 ₸/мес",
    radioAtomsProps: radioAtoms8Data,
};

const radioAtoms9Data = {
    className: "radio_atoms-8",
};

const radioButton28Data = {
    radioText: "Бесплатное подключение",
    radioAtomsProps: radioAtoms9Data,
};

const radioAtoms10Data = {
    className: "radio_atoms-9",
};

const radioButton29Data = {
    radioText: "Акция",
    radioAtomsProps: radioAtoms10Data,
};

const speed1Data = {
    text56: "до 500 Мбит/с",
};

const label1Data = {
    children: t('tariffs.text5'),
};

const technology1Data = {
    labelProps: label1Data,
};

const label2Data = {
    children: "БЕСПЛАТНО",
    className: "label-1",
};

const connection321Data = {
    text16: t('tariffs.text9'),
    labelProps: label2Data,
};

const services1Data = {
    text27: "Комплект оборудования предоставляется Оператором согласно Приложения №2 договора",
    technologyProps: technology1Data,
    connection32Props: connection321Data,
};

const button22Data = {
    children: t('tariffs.text8'),
    className: "button-4",
};

const cardBottom31Data = {
    address: "5 000 ₸/мес",
    button2Props: button22Data,
};

const card2Data = {
    text22: "Мунайлинский район",
    text23: "Тарифный план на предоставление доступа к сети интернет для Атамекен, Туган ел, Жалын, Шагала, Каламкас, Баскудык,  Автодром, Мангышлак, 22 квартал и ж/м Приморский",
    speedProps: speed1Data,
    servicesProps: services1Data,
    cardBottom3Props: cardBottom31Data,
};

const speed2Data = {
    text56: "до 200 Мбит/с",
};

const label3Data = {
    children: t('tariffs.text5'),
};

const technology2Data = {
    labelProps: label3Data,
};

const label4Data = {
    children: "БЕСПЛАТНО",
    className: "label-3",
};

const connection322Data = {
    text16: t('tariffs.text9'),
    labelProps: label4Data,
};

const button23Data = {
    children: t('tariffs.text8'),
    className: "button-5",
};

const card221Data = {
    address: "2 500 ₸/мес",
    speedProps: speed2Data,
    technologyProps: technology2Data,
    connection32Props: connection322Data,
    button2Props: button23Data,
};

const speed3Data = {
    text56: "до 300 Мбит/с",
};

const label5Data = {
    children: t('tariffs.text5'),
};

const technology3Data = {
    labelProps: label5Data,
};

const label6Data = {
    children: "БЕСПЛАТНО",
    className: "label-4",
};

const connection323Data = {
    text16: t('tariffs.text9'),
    labelProps: label6Data,
};

const button24Data = {
    children: t('tariffs.text8'),
    className: "button-6",
};

const card222Data = {
    address: "3 500 ₸/мес",
    speedProps: speed3Data,
    technologyProps: technology3Data,
    connection32Props: connection323Data,
    button2Props: button24Data,
};

const speed4Data = {
    text56: "до 500 Мбит/с",
};

const label7Data = {
    children: t('tariffs.text5'),
};

const technology4Data = {
    labelProps: label7Data,
};

const label8Data = {
    children: "БЕСПЛАТНО",
    className: "label-5",
};

const connection324Data = {
    text16: t('tariffs.text9'),
    labelProps: label8Data,
};

const button25Data = {
    children: t('tariffs.text8'),
    className: "button-7",
};

const card223Data = {
    address: "5 000 ₸/мес",
    className: "card_2-3",
    speedProps: speed4Data,
    technologyProps: technology4Data,
    connection32Props: connection324Data,
    button2Props: button25Data,
};

const speed5Data = {
    text56: t('tariffs.text3'),
};

const label9Data = {
    children: t('tariffs.text5'),
};

const technology5Data = {
    labelProps: label9Data,
};

const label10Data = {
    children: "БЕСПЛАТНО",
    className: "label-6",
};

const connection325Data = {
    text16: t('tariffs.text9'),
    labelProps: label10Data,
};

const services2Data = {
    text27: "Wi-Fi модем предоставляется согласно приложению к Договору №2",
    technologyProps: technology5Data,
    connection32Props: connection325Data,
};

const button26Data = {
    children: t('tariffs.text8'),
    className: "button-8",
};

const cardBottom32Data = {
    address: "4 000 ₸/мес",
    button2Props: button26Data,
};

const card231Data = {
    text63: "ж/м Рауан (Рекон)",
    text64: "Тарифный план на предоставление доступа к сети интернет для ж/м Рауан",
    speedProps: speed5Data,
    speedProps2: services2Data,
    cardBottom3Props: cardBottom32Data,
};

const speed6Data = {
    text56: t('tariffs.text3'),
};

const label11Data = {
    children: t('tariffs.text5'),
};

const technology6Data = {
    labelProps: label11Data,
};

const label12Data = {
    children: "10 000 ₸",
    className: "label-7",
};

const connection1Data = {
    text7: t('tariffs.text9'),
    labelProps: label12Data,
};

const services22Data = {
    technologyProps: technology6Data,
    connectionProps: connection1Data,
};

const button27Data = {
    children: t('tariffs.text8'),
    className: "button-9",
};

const cardBottom33Data = {
    address: "6 000 ₸/мес",
    button2Props: button27Data,
};

const card291Data = {
    text54: "г.Жанаозен, п.Тенге",
    text55: "Тарифный план на предоставление доступа к сети интернет для г.Жанаозен, п.Тенге",
    speedProps: speed6Data,
    speedProps2: services22Data,
    cardBottom3Props: cardBottom33Data,
};

const speed7Data = {
    text56: "до 30 Мбит/с",
};

const label13Data = {
    children: t('tariffs.text5'),
};

const technology7Data = {
    labelProps: label13Data,
};

const label14Data = {
    children: "10 000 ₸",
    className: "label-8",
};

const connection2Data = {
    text7: t('tariffs.text9'),
    labelProps: label14Data,
};

const services23Data = {
    technologyProps: technology7Data,
    connectionProps: connection2Data,
};

const button28Data = {
    children: t('tariffs.text8'),
    className: "button-10",
};

const cardBottom34Data = {
    address: "8 000 ₸/мес",
    button2Props: button28Data,
};

const card292Data = {
    text54: "г.Жанаозен, п.Тенге",
    text55: "Тарифный план на предоставление доступа к сети интернет для г.Жанаозен, п.Тенге",
    speedProps: speed7Data,
    speedProps2: services23Data,
    cardBottom3Props: cardBottom34Data,
};

const speed8Data = {
    text56: "до 50 Мбит/с",
};

const label15Data = {
    children: t('tariffs.text5'),
};

const technology8Data = {
    labelProps: label15Data,
};

const label16Data = {
    children: "10 000 ₸",
    className: "label-9",
};

const connection3Data = {
    text7: t('tariffs.text9'),
    labelProps: label16Data,
};

const services24Data = {
    technologyProps: technology8Data,
    connectionProps: connection3Data,
};

const button29Data = {
    children: t('tariffs.text8'),
    className: "button-11",
};

const cardBottom35Data = {
    address: "6 000 ₸/мес",
    button2Props: button29Data,
};

const card293Data = {
    text54: "г.Жанаозен",
    text55: "Тарифный план на предоставление доступа к сети интернет для г.Жанаозен, мкр. Рахат-1, мкр. Рахат-2, мкр. Рахат-3",
    speedProps: speed8Data,
    speedProps2: services24Data,
    cardBottom3Props: cardBottom35Data,
};

const speed9Data = {
    text56: "до 50 Мбит/с",
};

const label17Data = {
    children: t('tariffs.text5'),
};

const technology9Data = {
    labelProps: label17Data,
};

const label18Data = {
    children: "5 000 ₸",
    className: "label-10",
};

const connection33Data = {
    labelProps: label18Data,
};

const cardTop1Data = {
    c: "c.Бейнеу",
    text59: t('tariffs.text10'),
    speedProps: speed9Data,
    technologyProps: technology9Data,
    connection3Props: connection33Data,
};

const button210Data = {
    children: t('tariffs.text8'),
    className: "button-12",
};

const cardBottom36Data = {
    address: "6 000 ₸/мес",
    button2Props: button210Data,
};

const card251Data = {
    cardTopProps: cardTop1Data,
    cardBottom3Props: cardBottom36Data,
};

const speed10Data = {
    text56: "до 70 Мбит/с",
};

const label19Data = {
    children: t('tariffs.text5'),
};

const technology10Data = {
    labelProps: label19Data,
};

const label20Data = {
    children: "5 000 ₸",
    className: "label-11",
};

const connection34Data = {
    labelProps: label20Data,
};

const cardTop2Data = {
    speedProps: speed10Data,
    technologyProps: technology10Data,
    connection3Props: connection34Data,
};

const button211Data = {
    children: t('tariffs.text8'),
    className: "button-13",
};

const cardBottom37Data = {
    address: "6 500 ₸/мес",
    button2Props: button211Data,
};

const card252Data = {
    cardTopProps: cardTop2Data,
    cardBottom3Props: cardBottom37Data,
};

const speed11Data = {
    text56: "до 100 Мбит/с",
};

const label21Data = {
    children: t('tariffs.text5'),
};

const technology11Data = {
    labelProps: label21Data,
};

const label25Data = {
    children: "5 000 ₸",
    className: "label-12",
};

const connection35Data = {
    labelProps: label25Data,
};

const cardTop3Data = {
    speedProps: speed11Data,
    technologyProps: technology11Data,
    connection3Props: connection35Data,
};

const button212Data = {
    children: t('tariffs.text8'),
    className: "button-14",
};

const cardBottom38Data = {
    address: "8 000 ₸/мес",
    button2Props: button212Data,
};

const card253Data = {
    cardTopProps: cardTop3Data,
    cardBottom3Props: cardBottom38Data,
};

const speed12Data = {
    text56: "до 200 Мбит/с",
};

const label26Data = {
    children: t('tariffs.text5'),
};

const technology12Data = {
    labelProps: label26Data,
};

const label27Data = {
    children: "5 000 ₸",
    className: "label-13",
};

const connection36Data = {
    labelProps: label27Data,
};

const cardTop4Data = {
    speedProps: speed12Data,
    technologyProps: technology12Data,
    connection3Props: connection36Data,
};

const button213Data = {
    children: t('tariffs.text8'),
    className: "button-15",
};

const card261Data = {
    address: "10 000 ₸/мес",
    cardTopProps: cardTop4Data,
    button2Props: button213Data,
};

const speed13Data = {
    text56: "до 300 Мбит/с",
};

const label28Data = {
    children: t('tariffs.text5'),
};

const technology13Data = {
    labelProps: label28Data,
};

const label29Data = {
    children: "5 000 ₸",
    className: "label-14",
};

const connection37Data = {
    labelProps: label29Data,
};

const cardTop5Data = {
    c: "c.Батыр",
    text59: "Тарифный план на предоставление доступа к сети интернет для села Батыр",
    speedProps: speed13Data,
    technologyProps: technology13Data,
    connection3Props: connection37Data,
};

const button214Data = {
    children: t('tariffs.text8'),
    className: "button-16",
};

const card262Data = {
    address: "12 000 ₸/мес",
    cardTopProps: cardTop5Data,
    button2Props: button214Data,
};

const speed14Data = {
    text56: "до 50 Мбит/с",
};

const label30Data = {
    children: t('tariffs.text5'),
};

const technology14Data = {
    labelProps: label30Data,
};

const label31Data = {
    children: "10 000 ₸",
    className: "label-15",
};

const connection4Data = {
    text7: t('tariffs.text9'),
    labelProps: label31Data,
};

const services25Data = {
    technologyProps: technology14Data,
    connectionProps: connection4Data,
};

const button215Data = {
    children: t('tariffs.text8'),
    className: "button-17",
};

const cardBottom39Data = {
    address: "8 000 ₸/мес",
    button2Props: button215Data,
};

const card271Data = {
    speedProps: speed14Data,
    services2Props: services25Data,
    cardBottom3Props: cardBottom39Data,
};

const speed15Data = {
    text56: t('tariffs.text3'),
};

const label32Data = {
    children: t('tariffs.text5'),
};

const technology15Data = {
    labelProps: label32Data,
};

const label33Data = {
    children: "10 000 ₸",
    className: "label-16",
};

const connection5Data = {
    text7: t('tariffs.text9'),
    labelProps: label33Data,
};

const services26Data = {
    technologyProps: technology15Data,
    connectionProps: connection5Data,
};

const button216Data = {
    children: t('tariffs.text8'),
    className: "button-18",
};

const cardBottom310Data = {
    address: "6 000 ₸/мес",
    button2Props: button216Data,
};

const card272Data = {
    speedProps: speed15Data,
    services2Props: services26Data,
    cardBottom3Props: cardBottom310Data,
};

const button31Data = {
    children: t('footer.text5'),
};

const button621Data = {
    children: t('footer.text6'),
};

const frame31Data = {
    button32Props: button31Data,
    button6Props: button621Data,
};

const frame293Data = {
    className: "frame-31",
};

const frame321Data = {
    frame29Props: frame293Data,
};

const footer41Data = {
    button32Props: frame31Data,
    button32Props2: frame321Data,
};

const tariffPageOpticsData = {
    text15: t('menu.title3'),
    text16: "Технология:",
    text17: "Показывать сначала:",
    text18: "Скорость Интернета:",
    text19: "Цена:",
    text20: "Дополнительные сервисы:",
    text21: "г.Актау",
    text48: "г.Жанаозен",
    text64: "с.Бейнеу",
    text85: "с.Батыр",
    headerProps: header1Data,
    breadcrumbsProps: breadcrumbs1Data,
    radioButtonProps: radioButtonData,
    radioButton21Props: radioButton21Data,
    radioButton22Props: radioButton22Data,
    radioButton23Props: radioButton23Data,
    radioButton24Props: radioButton24Data,
    radioButton25Props: radioButton25Data,
    radioButton26Props: radioButton26Data,
    radioButton27Props: radioButton27Data,
    radioButton28Props: radioButton28Data,
    radioButton29Props: radioButton29Data,
    card2Props: card2Data,
    card221Props: card221Data,
    card222Props: card222Data,
    card223Props: card223Data,
    card23Props: card231Data,
    card291Props: card291Data,
    card292Props: card292Data,
    card293Props: card293Data,
    card251Props: card251Data,
    card252Props: card252Data,
    card253Props: card253Data,
    card261Props: card261Data,
    card262Props: card262Data,
    card271Props: card271Data,
    card272Props: card272Data,
    footer4Props: footer41Data,
};

const menuName7Data = {
    children: t('menu.title1'),
};

const menuName8Data = {
    children: t('menu.title2'),
};

const menuName9Data = {
    children: t('menu.title3'),
};

const menuName10Data = {
    children: t('menu.title4'),
};

const menuName11Data = {
    children: t('menu.title5'),
    className: "menu-name-3",
};

const menuName12Data = {
    children: t('menu.title6'),
};

const menu2Data = {
    menuName1Props: menuName7Data,
    menuName2Props: menuName8Data,
    menuName3Props: menuName9Data,
    menuName4Props: menuName10Data,
    menuName5Props: menuName11Data,
    menuName6Props: menuName12Data,
};

const button72Data = {
    children: t('header.text1'),
};

const button217Data = {
    children: t('header.text2'),
};

const cTAButtons2Data = {
    button7Props: button72Data,
    button2Props: button217Data,
};

const headerContent2Data = {
    menuName3Props: menu2Data,
    menuName6Props: cTAButtons2Data,
};

const header2Data = {
    headerContentProps: headerContent2Data,
};

const button218Data = {
    children: t('slider.text3'),
};

const mainBg1Data = {
    buttonProps: button218Data,
};

const service1Data = {
    text31: <React.Fragment>{t('service.text1')} <br/>{t('service.text2')}</React.Fragment>,
    text32: t('service.text3'),
};

const service2Data = {
    text31: t('service.text4'),
    text32: t('service.text5'),
};

const service3Data = {
    text31: t('service.text6'),
    text32: t('service.text7'),
};

const services61Data = {
    service1Props: service1Data,
    service2Props: service2Data,
    service3Props: service3Data,
};

const speed16Data = {
    text56: t('tariffs.text3'),
};

const label34Data = {
    children: t('tariffs.text5'),
};

const technology16Data = {
    labelProps: label34Data,
};

const label35Data = {
    children: "10 000 ₸",
    className: "label-17",
};

const connection6Data = {
    text7: t('tariffs.text9'),
    labelProps: label35Data,
};

const services27Data = {
    technologyProps: technology16Data,
    connectionProps: connection6Data,
};

const button219Data = {
    children: t('tariffs.text8'),
    className: "button-20",
};

const cardBottom311Data = {
    address: "6 000 ₸/мес",
    button2Props: button219Data,
};

const card294Data = {
    text54: t('tariffs.text1'),
    text55: t('tariffs.text2'),
    speedProps: speed16Data,
    speedProps2: services27Data,
    cardBottom3Props: cardBottom311Data,
};

const speed17Data = {
    text56: t('tariffs.text6'),
};

const label36Data = {
    children: t('tariffs.text7'),
    className: "label-18",
};

const technology22Data = {
    text10: "Технология",
    labelProps: label36Data,
};

const label37Data = {
    children: "45 000 ₸",
    className: "label-19",
};

const connection22Data = {
    text11: t('tariffs.text12'),
    wifi: <React.Fragment>{t('tariffs.text13')}<br/>{t('tariffs.text14')}</React.Fragment>,
    labelProps: label37Data,
};

const button220Data = {
    children: t('tariffs.text8'),
    className: "button-21",
};

const cardBottom312Data = {
    address: "5 000 ₸/мес",
    button2Props: button220Data,
};

const card2222Data = {
    c: "c.Бейнеу",
    text59: t('tariffs.text10'),
    speedProps: speed17Data,
    technology2Props: technology22Data,
    connection2Props: connection22Data,
    cardBottom3Props: cardBottom312Data,
};

const speed18Data = {
    text56: t('tariffs.text3'),
};

const label38Data = {
    children: t('tariffs.text5'),
};

const technology17Data = {
    labelProps: label38Data,
};

const label39Data = {
    children: "БЕСПЛАТНО",
    className: "label-20",
};

const connection326Data = {
    text16: t('tariffs.text9'),
    labelProps: label39Data,
};

const services3Data = {
    text27: "Wi-Fi модем предоставляется согласно приложению к Договору №2",
    technologyProps: technology17Data,
    connection32Props: connection326Data,
};

const button221Data = {
    children: t('tariffs.text8'),
    className: "button-22",
};

const cardBottom313Data = {
    address: "4 000 ₸/мес",
    button2Props: button221Data,
};

const card232Data = {
    text63: "ж/м Рауан (Рекон)",
    text64: "Тарифный план на предоставление доступа к сети интернет для ж/м Рауан",
    speedProps: speed18Data,
    speedProps2: services3Data,
    cardBottom3Props: cardBottom313Data,
};

const button32Data = {
    children: t('tariffs.text11'),
};

const map1Data = {
    src: "/img/image-16@2x.png",
    className: "",
};

const input1Data = {
    className: "",
};

const input21Data = {
    children: t('checkaddress.text4'),
};

const input22Data = {
    children: t('checkaddress.text5'),
    className: "input-5",
};

const input23Data = {
    children: t('checkaddress.text6'),
    className: "input-6",
};

const button41Data = {
    children: t('checkaddress.text7'),
};

const checkbox1Data = {
    className: "",
};

const checkConnection1Data = {
    inputProps22: map1Data,
    inputProps222: input1Data,
    input21Props: input21Data,
    input22Props: input22Data,
    input23Props: input23Data,
    button4Props: button41Data,
    button4Props2: checkbox1Data,
};

const imagesNews1Data = {
    src: "/img/image-17@2x.png",
    className: "",
};

const content1Data = {
    text21: "Сегодня в 13:58",
    text22: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
};

const cardNews221Data = {
    imagesNewsProps: imagesNews1Data,
    imagesNewsProps2: content1Data,
};

const imagesNews2Data = {
    src: "/img/image-18@2x.png",
    className: "images_news-1",
};

const content2Data = {
    text21: "Сегодня в 17:23",
    text22: "Сдана в эксплуатацию сеть в квартале 22, с. Мангышлак",
};

const cardNews222Data = {
    imagesNewsProps: imagesNews2Data,
    imagesNewsProps2: content2Data,
};

const imagesNews3Data = {
    src: "/img/image-19@2x.png",
    className: "images_news-1",
};

const content3Data = {
    text21: "19.07.2022 в 09:35",
    text22: "Жилой массив Туган ел сдан в эксплуатацию",
};

const cardNews223Data = {
    imagesNewsProps: imagesNews3Data,
    imagesNewsProps2: content3Data,
};

const imagesNews4Data = {
    src: "/img/image-20@2x.png",
    className: "images_news-1",
};

const content4Data = {
    text21: "02.07.2022 в 12:02",
    text22: "Полностью готова к эксплуатации сеть в ж/к Family Town",
};

const cardNews224Data = {
    imagesNewsProps: imagesNews4Data,
    imagesNewsProps2: content4Data,
};

const cardNews241Data = {
    cardNews221Props: cardNews221Data,
    cardNews222Props: cardNews222Data,
    cardNews223Props: cardNews223Data,
    cardNews224Props: cardNews224Data,
};

const input31Data = {
    children: "Ваше имя",
};

const input32Data = {
    children: "Телефон",
};

const namePhone1Data = {
    input31Props: input31Data,
    input32Props: input32Data,
};

const input33Data = {
    children: t('connectservice.text3'),
};

const checkbox2Data = {
    className: "checkbox-1",
};

const button42Data = {
    children: t('connectservice.text5'),
    className: "button-41",
};

const requestData = {
    namePhoneProps: namePhone1Data,
    input3Props: input33Data,
    checkboxProps: checkbox2Data,
    button4Props: button42Data,
};

const button33Data = {
    children: t('footer.text5'),
};

const button622Data = {
    children: t('footer.text6'),
};

const frame33Data = {
    button32Props: button33Data,
    button6Props: button622Data,
};

const frame296Data = {
    className: "frame-31-1",
};

const frame322Data = {
    frame29Props: frame296Data,
};

const footer2Data = {
    text87: t('footer.text7'),
    text91: t('menu.title4'),
    text92: t('menu.title6'),
    frame3Props: frame33Data,
    frame32Props: frame322Data,
};

const mainPageData = {
    text95: "Тарифы интернета",
    text112: t('menu.title4'),
    headerProps: header2Data,
    mainBgProps: mainBg1Data,
    services6Props: services61Data,
    card29Props: card294Data,
    card222Props: card2222Data,
    card23Props: card232Data,
    button3Props: button32Data,
    checkConnectionProps: checkConnection1Data,
    cardNews24Props: cardNews241Data,
    requestProps: requestData,
    footer2Props: footer2Data,
};

const menuName13Data = {
    children: t('menu.title1'),
};

const menuName14Data = {
    children: t('menu.title2'),
};

const menuName15Data = {
    children: t('menu.title3'),
};

const menuName16Data = {
    children: t('menu.title4'),
};

const menuName17Data = {
    children: t('menu.title5'),
    className: "menu-name-4",
};

const menuName18Data = {
    children: t('menu.title6'),
};

const menu3Data = {
    menuName1Props: menuName13Data,
    menuName2Props: menuName14Data,
    menuName3Props: menuName15Data,
    menuName4Props: menuName16Data,
    menuName5Props: menuName17Data,
    menuName6Props: menuName18Data,
};

const button73Data = {
    children: t('header.text1'),
};

const button222Data = {
    children: t('header.text2'),
};

const cTAButtons3Data = {
    button7Props: button73Data,
    button2Props: button222Data,
};

const headerContent3Data = {
    menuName3Props: menu3Data,
    menuName6Props: cTAButtons3Data,
};

const header3Data = {
    headerContentProps: headerContent3Data,
};

const breadcrumbs2Data = {
    x3: t('menu.title5'),
    className: "breadcrumbs-1",
};

const feature1Data = {
    text216: t('aboutcompany.text8'),
};

const feature2Data = {
    text216: t('aboutcompany.text9'),
    className: "feature-1",
};

const feature3Data = {
    text216: t('aboutcompany.text10'),
};

const feature4Data = {
    text216: t('aboutcompany.text11'),
};

const feature5Data = {
    text216: t('aboutcompany.text12'),
};

const imagesNews5Data = {
    src: "/img/1@2x.png",
    className: "gramoty",
};

const imagesNews6Data = {
    src: "/img/2@2x.png",
    className: "gramoty-1",
};

const imagesNews7Data = {
    src: "/img/2@2x.png",
    className: "gramoty-2",
};

const imagesNews8Data = {
    src: "/img/4@2x.png",
    className: "gramoty-3",
};

const x122Data = {
    imagesNews1Props: imagesNews5Data,
    imagesNews2Props: imagesNews6Data,
    imagesNews3Props: imagesNews7Data,
    imagesNews4Props: imagesNews8Data,
};

const imagesNews9Data = {
    src: "/img/5@2x.png",
    className: "gramoty-4",
};

const imagesNews10Data = {
    src: "/img/6@2x.png",
    className: "gramoty-5",
};

const imagesNews11Data = {
    src: "/img/7@2x.png",
    className: "gramoty-6",
};

const imagesNews12Data = {
    src: "/img/8@2x.png",
    className: "gramoty-7",
};

const x123Data = {
    imagesNews1Props: imagesNews9Data,
    imagesNews2Props: imagesNews10Data,
    imagesNews3Props: imagesNews11Data,
    imagesNews4Props: imagesNews12Data,
};

const button34Data = {
    children: t('footer.text5'),
};

const button623Data = {
    children: t('footer.text6'),
};

const frame34Data = {
    button32Props: button34Data,
    button6Props: button623Data,
};

const frame299Data = {
    className: "frame-31-2",
};

const frame323Data = {
    frame29Props: frame299Data,
};

const footer42Data = {
    className: "footer-1",
    button32Props: frame34Data,
    button32Props2: frame323Data,
};

const aboutCompanyData = {
    text156: t('menu.title5'),
    spanText1: "«SAS-Telecom» ",
    spanText2: <React.Fragment>- {t('aboutcompany.text1')}<br/><br/>{t('aboutcompany.text2')}<br/><br/>{t('aboutcompany.text3')}<br/>{t('aboutcompany.text4')}<br/>{t('aboutcompany.text5')}</React.Fragment>,
    image16: "/img/image-16-3@2x.png",
    text157: t('aboutcompany.text6'),
    rectangle86: "/img/rectangle-86@1x.png",
    text163: t('aboutcompany.text7'),
    headerProps: header3Data,
    breadcrumbsProps: breadcrumbs2Data,
    feature1Props: feature1Data,
    feature2Props: feature2Data,
    feature3Props: feature3Data,
    feature4Props: feature4Data,
    feature5Props: feature5Data,
    x121Props: x122Data,
    x122Props: x123Data,
    footer4Props: footer42Data,
};

const menuName19Data = {
    children: t('menu.title1'),
};

const menuName20Data = {
    children: t('menu.title2'),
};

const menuName21Data = {
    children: t('menu.title3'),
};

const menuName22Data = {
    children: t('menu.title4'),
};

const menuName23Data = {
    children: t('menu.title5'),
    className: "menu-name-5",
};

const menuName24Data = {
    children: t('menu.title6'),
};

const menu4Data = {
    menuName1Props: menuName19Data,
    menuName2Props: menuName20Data,
    menuName3Props: menuName21Data,
    menuName4Props: menuName22Data,
    menuName5Props: menuName23Data,
    menuName6Props: menuName24Data,
};

const button74Data = {
    children: t('header.text1'),
};

const button223Data = {
    children: t('header.text2'),
};

const cTAButtons4Data = {
    button7Props: button74Data,
    button2Props: button223Data,
};

const headerContent4Data = {
    menuName3Props: menu4Data,
    menuName6Props: cTAButtons4Data,
};

const header4Data = {
    headerContentProps: headerContent4Data,
};

const breadcrumbs3Data = {
    x3: "Карта покрытия",
    className: "breadcrumbs-2",
};

const input5Data = {
    className: "input-1",
};

const input24Data = {
    children: t('checkaddress.text4'),
    className: "input-7",
};

const input25Data = {
    children: t('checkaddress.text5'),
    className: "input-8",
};

const input26Data = {
    children: t('checkaddress.text6'),
    className: "input-9",
};

const button43Data = {
    children: t('checkaddress.text7'),
};

const button35Data = {
    children: t('footer.text5'),
};

const button624Data = {
    children: t('footer.text6'),
};

const frame35Data = {
    button32Props: button35Data,
    button6Props: button624Data,
};

const frame2912Data = {
    className: "frame-31-3",
};

const frame324Data = {
    frame29Props: frame2912Data,
};

const footer43Data = {
    className: "footer-2",
    button32Props: frame35Data,
    button32Props2: frame324Data,
};

const checkCoverageData = {
    text178: "Карта покрытия",
    text179: t('checkaddress.text2'),
    map: "/img/image-16-2@1x.png",
    headerProps: header4Data,
    breadcrumbsProps: breadcrumbs3Data,
    inputProps: input5Data,
    input21Props: input24Data,
    input22Props: input25Data,
    input23Props: input26Data,
    button4Props: button43Data,
    footer4Props: footer43Data,
};

const menuName25Data = {
    children: t('menu.title1'),
};

const menuName26Data = {
    children: t('menu.title2'),
};

const menuName27Data = {
    children: t('menu.title3'),
};

const menuName28Data = {
    children: t('menu.title4'),
};

const menuName29Data = {
    children: t('menu.title5'),
    className: "menu-name-6",
};

const menuName30Data = {
    children: t('menu.title6'),
};

const menu5Data = {
    menuName1Props: menuName25Data,
    menuName2Props: menuName26Data,
    menuName3Props: menuName27Data,
    menuName4Props: menuName28Data,
    menuName5Props: menuName29Data,
    menuName6Props: menuName30Data,
};

const button75Data = {
    children: t('header.text1'),
};

const button224Data = {
    children: t('header.text2'),
};

const cTAButtons5Data = {
    button7Props: button75Data,
    button2Props: button224Data,
};

const headerContent5Data = {
    menuName3Props: menu5Data,
    menuName6Props: cTAButtons5Data,
};

const header5Data = {
    headerContentProps: headerContent5Data,
};

const breadcrumbs4Data = {
    x3: t('menu.title2'),
    className: "breadcrumbs-3",
};

const feature22Data = {
    text155: t('service.text21'),
};

const feature23Data = {
    text155: t('service.text22'),
};

const feature24Data = {
    text155: t('service.text23'),
};

const feature25Data = {
    text155: t('service.text24'),
};

const feature26Data = {
    text155: t('service.text25'),
};

const feature27Data = {
    text155: t('service.text26'),
    className: "feature-4",
};

const button36Data = {
    children: t('footer.text5'),
};

const button625Data = {
    children: t('footer.text6'),
};

const frame36Data = {
    button32Props: button36Data,
    button6Props: button625Data,
};

const frame2915Data = {
    className: "frame-31-4",
};

const frame325Data = {
    frame29Props: frame2915Data,
};

const footer44Data = {
    className: "footer-3",
    button32Props: frame36Data,
    button32Props2: frame325Data,
};

const services5Data = {
    text194: t('service.text8'),
    sasTelecom: <React.Fragment>{t('service.text9')} <br/><br/>{t('service.text10')} <br/> <br/>{t('service.text11')}</React.Fragment>,
    text195: t('service.text12'),
    sastelecom20042010: <React.Fragment>{t('service.text13')} {t('service.text20')} <br/> <br/>{t('service.text14')}</React.Fragment>,
    blueOpticFiberWithEthernetCables1: "/img/blue-optic-fiber-with-ethernet-cables-1@1x.png",
    text199: t('service.text15'),
    dataCenterWithServerRacksCorridorRo: "/img/data-center-with-server-racks-corridor-room-3d-render-digital-da@1x.png",
    text201: t('service.text16'),
    text204: t('service.text17'),
    spanText1: t('service.text18'),
    spanText2: t('service.text19'),
    headerProps: header5Data,
    breadcrumbsProps: breadcrumbs4Data,
    feature21Props: feature22Data,
    feature22Props: feature23Data,
    feature23Props: feature24Data,
    feature24Props: feature25Data,
    feature25Props: feature26Data,
    feature26Props: feature27Data,
    footer4Props: footer44Data,
};

const menuName31Data = {
    children: t('menu.title1'),
};

const menuName32Data = {
    children: t('menu.title2'),
};

const menuName33Data = {
    children: t('menu.title3'),
};

const menuName34Data = {
    children: t('menu.title4'),
};

const menuName35Data = {
    children: t('menu.title5'),
    className: "menu-name-7",
};

const menuName36Data = {
    children: t('menu.title6'),
};

const menu6Data = {
    menuName1Props: menuName31Data,
    menuName2Props: menuName32Data,
    menuName3Props: menuName33Data,
    menuName4Props: menuName34Data,
    menuName5Props: menuName35Data,
    menuName6Props: menuName36Data,
};

const button76Data = {
    children: t('header.text1'),
};

const button225Data = {
    children: t('header.text2'),
};

const cTAButtons6Data = {
    button7Props: button76Data,
    button2Props: button225Data,
};

const headerContent6Data = {
    menuName3Props: menu6Data,
    menuName6Props: cTAButtons6Data,
};

const header6Data = {
    headerContentProps: headerContent6Data,
};

const breadcrumbs5Data = {
    x3: "Блог",
    className: "breadcrumbs-4",
};

const imagesNews13Data = {
    src: "/img/image-19-1@2x.png",
    className: "images_news-2",
};

const image1Data = {
    imagesNewsProps: imagesNews13Data,
};

const cardNews1Data = {
    text54: "Тарификация вызовов специалиста",
    imageProps: image1Data,
};

const imagesNews14Data = {
    src: "/img/image-19-1@2x.png",
    className: "images_news-3",
};

const image2Data = {
    imagesNewsProps: imagesNews14Data,
};

const cardNews31Data = {
    text55: "Не работает Интернет или низкая скорость?",
    imageProps: image2Data,
};

const imagesNews15Data = {
    src: "/img/image-19-1@2x.png",
    className: "images_news-4",
};

const image3Data = {
    imagesNewsProps: imagesNews15Data,
};

const cardNews32Data = {
    text55: "От чего зависит низкая скорость интернета?",
    imageProps: image3Data,
};

const imagesNews16Data = {
    src: "/img/image-19-1@2x.png",
    className: "images_news-5",
};

const image4Data = {
    imagesNewsProps: imagesNews16Data,
};

const cardNews41Data = {
    text57: "Описание оборудования",
    imageProps: image4Data,
};

const imagesNews17Data = {
    src: "/img/image-19-1@2x.png",
    className: "images_news-6",
};

const image5Data = {
    imagesNewsProps: imagesNews17Data,
};

const cardNews42Data = {
    text57: "Что такое GPON и зачем он нужен в вашем доме?",
    imageProps: image5Data,
};

const imagesNews18Data = {
    src: "/img/image-19-1@2x.png",
    className: "images_news-7",
};

const image6Data = {
    imagesNewsProps: imagesNews18Data,
};

const cardNews2Data = {
    text54: "Как платить за интернет через терминал?",
    imageProps: image6Data,
};

const pagination2212Data = {
    className: "pagination",
};

const pagination31Data = {
    children: "1",
};

const pagination32Data = {
    children: "2",
};

const pagination33Data = {
    children: "...",
};

const pagination34Data = {
    children: "12",
};

const pagination2213Data = {
    className: "pagination-1",
};

const pagination1Data = {
    pagination31Props: pagination2212Data,
    pagination31Props2: pagination31Data,
    pagination32Props: pagination32Data,
    pagination33Props: pagination33Data,
    pagination34Props: pagination34Data,
    pagination2Props: pagination2213Data,
};

const button37Data = {
    children: t('footer.text5'),
};

const button626Data = {
    children: t('footer.text6'),
};

const frame37Data = {
    button32Props: button37Data,
    button6Props: button626Data,
};

const frame2918Data = {
    className: "frame-31-5",
};

const frame326Data = {
    frame29Props: frame2918Data,
};

const footer45Data = {
    button32Props: frame37Data,
    button32Props2: frame326Data,
};

const blogData = {
    text219: "Блог",
    headerProps: header6Data,
    breadcrumbsProps: breadcrumbs5Data,
    cardNews1Props: cardNews1Data,
    cardNews31Props: cardNews31Data,
    cardNews32Props: cardNews32Data,
    cardNews41Props: cardNews41Data,
    cardNews42Props: cardNews42Data,
    cardNews2Props: cardNews2Data,
    paginationProps: pagination1Data,
    footer4Props: footer45Data,
};

const menuName37Data = {
    children: t('menu.title1'),
};

const menuName38Data = {
    children: t('menu.title2'),
};

const menuName39Data = {
    children: t('menu.title3'),
};

const menuName40Data = {
    children: t('menu.title4'),
};

const menuName41Data = {
    children: t('menu.title5'),
    className: "menu-name-8",
};

const menuName42Data = {
    children: t('menu.title6'),
};

const menu7Data = {
    menuName1Props: menuName37Data,
    menuName2Props: menuName38Data,
    menuName3Props: menuName39Data,
    menuName4Props: menuName40Data,
    menuName5Props: menuName41Data,
    menuName6Props: menuName42Data,
};

const button77Data = {
    children: t('header.text1'),
};

const button226Data = {
    children: t('header.text2'),
};

const cTAButtons7Data = {
    button7Props: button77Data,
    button2Props: button226Data,
};

const headerContent7Data = {
    menuName3Props: menu7Data,
    menuName6Props: cTAButtons7Data,
};

const header7Data = {
    headerContentProps: headerContent7Data,
};

const breadcrumbs22Data = {
    x1: t('menu.title1'),
    x2: "Блог",
    x3: "Что такое GPON",
};

const x21Data = {
    gpon1: "Так что же такое GPON ?",
    gpon2: <React.Fragment>GPON – это оптоволоконный кабель, совершивший революцию в сфере коммуникаций. Скорость
        передачи данных по такому каналу достигает 1 Гбит/с. Кабель проводится прямо в дом: сразу после этого
        гарантируются надежный сигнал и высокая скорость доступа в любое время суток. <br/><br/>Домашний интернет на
        суперскорости по проводу или Wi-Fi, цифровое ТВ с интерактивными функциями управления эфиром, крутые возможности
        домашнего телефона, online-видеонаблюдение за квартирой и охранная сигнализация – с GPON все это можно
        подключить прямо из дома в любой момент.</React.Fragment>,
};

const x22Data = {
    gpon1: "Какое оборудование нужно, чтобы подключить GPON и сколько это стоит?",
    gpon2: <React.Fragment>Хорошая новость: оптический модем ONT, через который предоставляются все цифровые услуги,
        компания SAS Telecom предоставит каждому клиенту. В модем встроен скоростной модуль Wi-Fi – он отвечает за
        беспроводную сеть: это значит, что вам не нужно будет тянуть лишние провода по квартире и вы сможете подключать
        одновременно несколько гаджетов. <br/> <br/>Чтобы провести в квартиру GPON, нужно просто оставить заявку на
        сайте или позвонить в наш офис. Вам быстро установят и настроят модем ONT, и можно сразу же подключать интернет,
        интерактивное телевидение или другие услуги. В дальнейшем, вы сможете самостоятельно заказать дополнительные
        функции через Личный кабинет или по телефону. <br/><br/>А если вам будет необходимо на время или насовсем
        отключить услуги, то не потребуется никаких усилий – разве что придется набрать номер нашего
        офиса.</React.Fragment>,
    className: "x3-5",
};

const button38Data = {
    children: t('footer.text5'),
    className: "button-28",
};

const button39Data = {
    children: t('footer.text5'),
};

const button627Data = {
    children: t('footer.text6'),
};

const frame38Data = {
    button32Props: button39Data,
    button6Props: button627Data,
};

const frame2921Data = {
    className: "frame-31-6",
};

const frame327Data = {
    frame29Props: frame2921Data,
};

const footer46Data = {
    className: "footer-5",
    button32Props: frame38Data,
    button32Props2: frame327Data,
};

const blogPageGPONData = {
    gpon: "Что такое GPON и зачем он нужен в вашем доме?",
    text_Label: <React.Fragment>Высокоскоростной интернет, домашний телефон с суперфункциями, интерактивное телевидение,
        видеонаблюдение и охранная сигнализация – GPON перевернул наши представления технологиях. Очередь за вами! <br/>
        <br/>Говорят, что получить все и сразу невозможно. Но в век технологий пришло время отказаться от всех
        стереотипов. Прокачать свой дом и получить максимум комфорта можно, если воспользоваться новыми технологиями.
        Такими как GPON от SAS Telecom.</React.Fragment>,
    onu1: "/img/onu-1@2x.png",
    headerProps: header7Data,
    breadcrumbs2Props: breadcrumbs22Data,
    x21Props: x21Data,
    x22Props: x22Data,
    button3Props: button38Data,
    footer4Props: footer46Data,
};

const menuName43Data = {
    children: t('menu.title1'),
};

const menuName44Data = {
    children: t('menu.title2'),
};

const menuName45Data = {
    children: t('menu.title3'),
};

const menuName46Data = {
    children: t('menu.title4'),
};

const menuName47Data = {
    children: t('menu.title5'),
    className: "menu-name-9",
};

const menuName48Data = {
    children: t('menu.title6'),
};

const menu8Data = {
    menuName1Props: menuName43Data,
    menuName2Props: menuName44Data,
    menuName3Props: menuName45Data,
    menuName4Props: menuName46Data,
    menuName5Props: menuName47Data,
    menuName6Props: menuName48Data,
};

const button78Data = {
    children: t('header.text1'),
};

const button227Data = {
    children: t('header.text2'),
};

const cTAButtons8Data = {
    button7Props: button78Data,
    button2Props: button227Data,
};

const headerContent8Data = {
    menuName3Props: menu8Data,
    menuName6Props: cTAButtons8Data,
};

const header8Data = {
    headerContentProps: headerContent8Data,
};

const breadcrumbs23Data = {
    x1: t('menu.title1'),
    x2: "Блог",
    x3: "Тарификация вызовов специалиста",
    className: "breadcrumbs-8",
};

const row1Data = {
    text253: "№",
    text254: "Наименование",
    text255: "Стоимость",
};

const row2Data = {
    text253: "1",
    text254: "Замена коннектора",
    text255: "2 000 тг",
    className: "row-1",
};

const row3Data = {
    text253: "2",
    text254: "Замена оптического WiFi роутера",
    text255: "	20 000 тг",
    className: "row-2",
};

const row4Data = {
    text253: "3",
    text254: "Замена модема (без WiFi)",
    text255: "15 000 тг",
    className: "row-3",
};

const row5Data = {
    text253: "4",
    text254: "Замена антенны",
    text255: "89 000 тг",
    className: "row-4",
};

const row6Data = {
    text253: "5",
    text254: "Замена блок питания антенны",
    text255: "17 000 тг",
    className: "row-5",
};

const row7Data = {
    text253: "6",
    text254: "Перенастройка модема",
    text255: "	3 000 тг",
    className: "row-6",
};

const row8Data = {
    text253: "7",
    text254: "Замена блока питания роутера",
    text255: "3 000 тг",
    className: "row-7",
};

const row9Data = {
    text253: "8",
    text254: "Замена оптического кабеля",
    text255: "	10 000 тг",
    className: "row-8",
};

const row10Data = {
    text253: "9",
    text254: "Замена Ethernet кабеля",
    text255: "5 000 тг",
    className: "row-9",
};

const row11Data = {
    text253: "10",
    text254: "Замена пароля",
    text255: "	1 000 тг",
    className: "row-10",
};

const row12Data = {
    text253: "11",
    text254: "Ложный вызов",
    text255: "	1 000 тг",
    className: "row-11",
};

const row13Data = {
    text253: "12",
    text254: "Активация лицевого счета абонента",
    text255: "500 тг",
    className: "row-12",
};

const button310Data = {
    children: t('footer.text5'),
};

const button628Data = {
    children: t('footer.text6'),
};

const frame39Data = {
    button32Props: button310Data,
    button6Props: button628Data,
};

const frame2924Data = {
    className: "frame-31-7",
};

const frame328Data = {
    frame29Props: frame2924Data,
};

const footer47Data = {
    className: "footer-6",
    button32Props: frame39Data,
    button32Props2: frame328Data,
};

const blogPagePriceTableData = {
    text254: "Тарификация вызовов специалиста",
    text255: "Ознакомьтесь с расценками за работу специалиста по вызову",
    text256: "Таблица тарификации вызовов специалиста",
    spanText1: "* Срок выполнения заявки по устранению неисправности в течении ",
    spanText2: "3-х рабочих дней ",
    spanText3: "с момента подачи заявки",
    text270: "Заявку можете подать по следующим телефонным номерам:",
    spanText4: "Атамекен:",
    spanText5: "    +7 (777) 632-19-19, +7 (701) 532-19-19",
    spanText6: "Жанаозен:",
    spanText7: "   +7 (771) 587-19-19",
    spanText8: "Бейнеу:",
    spanText9: "         +7 (705) 466-24-19",
    spanText10: "Шымкент:",
    spanText11: "    +7 (705) 783-06-80",
    spanText12: "Все работы по устранению неисправности вне территории абонента – ",
    spanText13: "бесплатно",
    spanText14: <React.Fragment><br/><br/>В стоимость подключения входит:<br/>монтаж кабеля<br/>расходные материалы<br/>установка
        и настройка роутера<br/>объяснения основ работы в сети</React.Fragment>,
    headerProps: header8Data,
    breadcrumbs2Props: breadcrumbs23Data,
    row1Props: row1Data,
    row2Props: row2Data,
    row3Props: row3Data,
    row4Props: row4Data,
    row5Props: row5Data,
    row6Props: row6Data,
    row7Props: row7Data,
    row8Props: row8Data,
    row9Props: row9Data,
    row10Props: row10Data,
    row11Props: row11Data,
    row12Props: row12Data,
    row13Props: row13Data,
    footer4Props: footer47Data,
};

const menuName49Data = {
    children: t('menu.title1'),
};

const menuName50Data = {
    children: t('menu.title2'),
};

const menuName51Data = {
    children: t('menu.title3'),
};

const menuName52Data = {
    children: t('menu.title4'),
};

const menuName53Data = {
    children: t('menu.title5'),
    className: "menu-name-10",
};

const menuName54Data = {
    children: t('menu.title6'),
};

const menu9Data = {
    menuName1Props: menuName49Data,
    menuName2Props: menuName50Data,
    menuName3Props: menuName51Data,
    menuName4Props: menuName52Data,
    menuName5Props: menuName53Data,
    menuName6Props: menuName54Data,
};

const button79Data = {
    children: t('header.text1'),
};

const button228Data = {
    children: t('header.text2'),
};

const cTAButtons9Data = {
    button7Props: button79Data,
    button2Props: button228Data,
};

const headerContent9Data = {
    menuName3Props: menu9Data,
    menuName6Props: cTAButtons9Data,
};

const header9Data = {
    headerContentProps: headerContent9Data,
};

const breadcrumbs6Data = {
    x3: t('menu.title6'),
    className: "breadcrumbs-5",
};

const frame721Data = {
    children: "+7 (771) 587-19-19",
};

const frame71Data = {
    text253: "Жанаозен:",
    frame72Props: frame721Data,
};

const frame722Data = {
    children: "+7 (705) 466-24-19",
};

const frame73Data = {
    text253: "Бейнеу:",
    frame72Props: frame722Data,
};

const frame81Data = {
    x0900: "09:00-18:00",
};

const frame723Data = {
    children: "+7 (729) 220-40-60",
    className: "frame-10-1",
};

const frame82Data = {
    x0900: "10:00-18:00",
};

const content2222Data = {
    frame72Props: frame723Data,
    frame8Props: frame82Data,
};

const frame724Data = {
    children: "+7 (771) 587-19-19",
    className: "frame-10-2",
};

const frame83Data = {
    x0900: "09:00-18:00",
};

const content2223Data = {
    frame72Props: frame724Data,
    frame8Props: frame83Data,
};

const frame725Data = {
    children: "+7 (705) 783-06-80",
    className: "frame-10-3",
};

const frame84Data = {
    x0900: "09:00-18:00",
};

const content2224Data = {
    frame72Props: frame725Data,
    frame8Props: frame84Data,
};

const button311Data = {
    children: t('footer.text5'),
};

const button629Data = {
    children: t('footer.text6'),
};

const frame310Data = {
    button32Props: button311Data,
    button6Props: button629Data,
};

const frame2927Data = {
    className: "frame-31-8",
};

const frame329Data = {
    frame29Props: frame2927Data,
};

const footer48Data = {
    button32Props: frame310Data,
    button32Props2: frame329Data,
};

const contacts22Data = {
    text287: t('menu.title6'),
    text288: "Техническая техподдержка",
    whatsapp: "Актау (WhatsApp):",
    phone1: "+7 (777) 632-19-19",
    text289: "Актау:",
    phone2: "+7 (701) 532-19-19",
    phone3: "+7 (729) 220-40-60",
    phone4: "+7 (729) 250-35-22",
    eMail: "E-mail:",
    sas_0606MailRu: "sas_0606@mail.ru",
    text292: "Офис №1",
    text293: "г.Актау, жилой массив Приморский, ул.Жемчужиная, дом 42",
    text294: "Телефон:",
    phone5: "+7 (729) 250-35-22",
    phone6: "+7 (777) 632-19-19",
    image231: "/img/image-23@1x.png",
    text300: "Офис №2",
    text301: "Мунайлинский район, с. Атамекен, ул. Наурыз, дом 404",
    image232: "/img/image-23@1x.png",
    text308: "Офис №3",
    text309: "г. Жанаозен, мкр. Рахат-3, ул.Кокесем уч.9",
    image233: "/img/image-23@1x.png",
    text316: "Офис №4",
    text317: "Город Шымкент, ул Ш.Калдаякова 2/5",
    image234: "/img/image-23@1x.png",
    headerProps: header9Data,
    breadcrumbsProps: breadcrumbs6Data,
    frame71Props: frame71Data,
    frame72Props: frame73Data,
    frame8Props: frame81Data,
    content2221Props: content2222Data,
    content2222Props: content2223Data,
    content2223Props: content2224Data,
    footer4Props: footer48Data,
};

const menuName55Data = {
    children: t('menu.title1'),
};

const menuName56Data = {
    children: t('menu.title2'),
};

const menuName57Data = {
    children: t('menu.title3'),
};

const menuName58Data = {
    children: t('menu.title4'),
};

const menuName59Data = {
    children: t('menu.title5'),
    className: "menu-name-11",
};

const menuName60Data = {
    children: t('menu.title6'),
};

const menu10Data = {
    menuName1Props: menuName55Data,
    menuName2Props: menuName56Data,
    menuName3Props: menuName57Data,
    menuName4Props: menuName58Data,
    menuName5Props: menuName59Data,
    menuName6Props: menuName60Data,
};

const button710Data = {
    children: t('header.text1'),
};

const button229Data = {
    children: t('header.text2'),
};

const cTAButtons10Data = {
    button7Props: button710Data,
    button2Props: button229Data,
};

const headerContent10Data = {
    menuName3Props: menu10Data,
    menuName6Props: cTAButtons10Data,
};

const header10Data = {
    headerContentProps: headerContent10Data,
};

const breadcrumbs7Data = {
    x3: t('menu.title4'),
    className: "breadcrumbs-6",
};

const imagesNews19Data = {
    src: "/img/image-17@2x.png",
    className: "",
};

const content5Data = {
    text21: "Сегодня в 13:58",
    text22: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
};

const cardNews225Data = {
    imagesNewsProps: imagesNews19Data,
    imagesNewsProps2: content5Data,
};

const imagesNews20Data = {
    src: "/img/image-18@2x.png",
    className: "images_news-1",
};

const content6Data = {
    text21: "Сегодня в 17:23",
    text22: "Сдана в эксплуатацию сеть в квартале 22, с. Мангышлак",
};

const cardNews226Data = {
    imagesNewsProps: imagesNews20Data,
    imagesNewsProps2: content6Data,
};

const imagesNews21Data = {
    src: "/img/image-19@2x.png",
    className: "images_news-1",
};

const content7Data = {
    text21: "19.07.2022 в 09:35",
    text22: "Жилой массив Туган ел сдан в эксплуатацию",
};

const cardNews227Data = {
    imagesNewsProps: imagesNews21Data,
    imagesNewsProps2: content7Data,
};

const imagesNews22Data = {
    src: "/img/image-20@2x.png",
    className: "images_news-1",
};

const content8Data = {
    text21: "02.07.2022 в 12:02",
    text22: "Полностью готова к эксплуатации сеть в ж/к Family Town",
};

const cardNews228Data = {
    imagesNewsProps: imagesNews22Data,
    imagesNewsProps2: content8Data,
};

const cardNews242Data = {
    cardNews221Props: cardNews225Data,
    cardNews222Props: cardNews226Data,
    cardNews223Props: cardNews227Data,
    cardNews224Props: cardNews228Data,
};

const imagesNews23Data = {
    src: "/img/image-17@2x.png",
    className: "",
};

const content9Data = {
    text21: "Сегодня в 13:58",
    text22: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
};

const cardNews229Data = {
    imagesNewsProps: imagesNews23Data,
    imagesNewsProps2: content9Data,
};

const imagesNews24Data = {
    src: "/img/image-18@2x.png",
    className: "images_news-1",
};

const content10Data = {
    text21: "Сегодня в 17:23",
    text22: "Сдана в эксплуатацию сеть в квартале 22, с. Мангышлак",
};

const cardNews2210Data = {
    imagesNewsProps: imagesNews24Data,
    imagesNewsProps2: content10Data,
};

const imagesNews25Data = {
    src: "/img/image-19@2x.png",
    className: "images_news-1",
};

const content11Data = {
    text21: "19.07.2022 в 09:35",
    text22: "Жилой массив Туган ел сдан в эксплуатацию",
};

const cardNews2211Data = {
    imagesNewsProps: imagesNews25Data,
    imagesNewsProps2: content11Data,
};

const imagesNews26Data = {
    src: "/img/image-20@2x.png",
    className: "images_news-1",
};

const content12Data = {
    text21: "02.07.2022 в 12:02",
    text22: "Полностью готова к эксплуатации сеть в ж/к Family Town",
};

const cardNews2212Data = {
    imagesNewsProps: imagesNews26Data,
    imagesNewsProps2: content12Data,
};

const cardNews243Data = {
    cardNews221Props: cardNews229Data,
    cardNews222Props: cardNews2210Data,
    cardNews223Props: cardNews2211Data,
    cardNews224Props: cardNews2212Data,
};

const imagesNews27Data = {
    src: "/img/image-17@2x.png",
    className: "",
};

const content13Data = {
    text21: "Сегодня в 13:58",
    text22: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
};

const cardNews2213Data = {
    imagesNewsProps: imagesNews27Data,
    imagesNewsProps2: content13Data,
};

const imagesNews28Data = {
    src: "/img/image-18@2x.png",
    className: "images_news-1",
};

const content14Data = {
    text21: "Сегодня в 17:23",
    text22: "Сдана в эксплуатацию сеть в квартале 22, с. Мангышлак",
};

const cardNews2214Data = {
    imagesNewsProps: imagesNews28Data,
    imagesNewsProps2: content14Data,
};

const imagesNews29Data = {
    src: "/img/image-19@2x.png",
    className: "images_news-1",
};

const content15Data = {
    text21: "19.07.2022 в 09:35",
    text22: "Жилой массив Туган ел сдан в эксплуатацию",
};

const cardNews2215Data = {
    imagesNewsProps: imagesNews29Data,
    imagesNewsProps2: content15Data,
};

const imagesNews30Data = {
    src: "/img/image-20@2x.png",
    className: "images_news-1",
};

const content16Data = {
    text21: "02.07.2022 в 12:02",
    text22: "Полностью готова к эксплуатации сеть в ж/к Family Town",
};

const cardNews2216Data = {
    imagesNewsProps: imagesNews30Data,
    imagesNewsProps2: content16Data,
};

const cardNews244Data = {
    cardNews221Props: cardNews2213Data,
    cardNews222Props: cardNews2214Data,
    cardNews223Props: cardNews2215Data,
    cardNews224Props: cardNews2216Data,
};

const pagination2214Data = {
    className: "pagination-2",
};

const pagination35Data = {
    children: "1",
};

const pagination36Data = {
    children: "2",
};

const pagination37Data = {
    children: "...",
};

const pagination38Data = {
    children: "12",
};

const pagination2215Data = {
    className: "pagination-3",
};

const pagination2Data = {
    pagination31Props: pagination2214Data,
    pagination31Props2: pagination35Data,
    pagination32Props: pagination36Data,
    pagination33Props: pagination37Data,
    pagination34Props: pagination38Data,
    pagination2Props: pagination2215Data,
};

const button312Data = {
    children: t('footer.text5'),
};

const button6210Data = {
    children: t('footer.text6'),
};

const frame311Data = {
    button32Props: button312Data,
    button6Props: button6210Data,
};

const frame2930Data = {
    className: "frame-31-9",
};

const frame3210Data = {
    frame29Props: frame2930Data,
};

const footer49Data = {
    button32Props: frame311Data,
    button32Props2: frame3210Data,
};

const newsData = {
    text338: t('menu.title4'),
    headerProps: header10Data,
    breadcrumbsProps: breadcrumbs7Data,
    cardNews241Props: cardNews242Data,
    cardNews242Props: cardNews243Data,
    cardNews243Props: cardNews244Data,
    paginationProps: pagination2Data,
    footer4Props: footer49Data,
};

const menuName61Data = {
    children: t('menu.title1'),
};

const menuName62Data = {
    children: t('menu.title2'),
};

const menuName63Data = {
    children: t('menu.title3'),
};

const menuName64Data = {
    children: t('menu.title4'),
};

const menuName65Data = {
    children: t('menu.title5'),
    className: "menu-name-12",
};

const menuName66Data = {
    children: t('menu.title6'),
};

const menu11Data = {
    menuName1Props: menuName61Data,
    menuName2Props: menuName62Data,
    menuName3Props: menuName63Data,
    menuName4Props: menuName64Data,
    menuName5Props: menuName65Data,
    menuName6Props: menuName66Data,
};

const button711Data = {
    children: t('header.text1'),
};

const button230Data = {
    children: t('header.text2'),
};

const cTAButtons11Data = {
    button7Props: button711Data,
    button2Props: button230Data,
};

const headerContent11Data = {
    menuName3Props: menu11Data,
    menuName6Props: cTAButtons11Data,
};

const header11Data = {
    headerContentProps: headerContent11Data,
};

const breadcrumbs24Data = {
    x1: t('menu.title1'),
    x2: t('menu.title4'),
    x3: "Жилой массив Рахат",
};

const imagesNews31Data = {
    src: "/img/image-17-1@2x.png",
    className: "images_news-8",
};

const input34Data = {
    children: "Ваше имя",
};

const input35Data = {
    children: "Телефон",
};

const button44Data = {
    children: t('connectservice.text5'),
    className: "button-43",
};

const button313Data = {
    children: t('footer.text5'),
};

const button6211Data = {
    children: t('footer.text6'),
};

const frame312Data = {
    button32Props: button313Data,
    button6Props: button6211Data,
};

const frame2933Data = {
    className: "frame-31-10",
};

const frame3211Data = {
    frame29Props: frame2933Data,
};

const footer410Data = {
    className: "footer-7",
    button32Props: frame312Data,
    button32Props2: frame3211Data,
};

const newsPageData = {
    text375: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
    spanText1: <React.Fragment>С радостью спешим сообщить, что готовы к подключению абонентов, проживающих в жилом
        массиве Рахат-2,3 (г.Жанаозен) к сети Интернет по оптоволоконной технологии GPON.<br/><br/>Специально для
        жителей массива, которые желают подключиться к сети интернет по оптоволоконной технологии GPON мы разработали
        выгодную линейку тарифов, которые не оставят равнодушними наших абонентов. </React.Fragment>,
    spanText2: "Подробнее по тарифам",
    spanText3: "Подать заявку на подключение жители массива заполнив онлайн форму ниже или позвонив по одному из",
    spanText4: " ",
    spanText5: "наших контактных номеров",
    text377: "Заявка на подключение",
    headerProps: header11Data,
    breadcrumbs2Props: breadcrumbs24Data,
    imagesNewsProps: imagesNews31Data,
    input31Props: input34Data,
    input32Props: input35Data,
    button4Props: button44Data,
    footer4Props: footer410Data,
};

const button231Data = {
    children: "Подключить тариф",
};

const mainBg2Data = {
    className: "main_bg-1",
    buttonProps: button231Data,
};

const checkbox32Data = {
    checkboxText: "Только Интернет",
};

const checkbox33Data = {
    checkboxText: "Быстрее 100 Мбит/с",
    className: "checkbox-8",
};

const checkbox34Data = {
    checkboxText: "Цена до 5000 ₸/мес",
    className: "checkbox-9",
};

const label40Data = {
    children: "70 000 тг",
    className: "label-21",
};

const frame151Data = {
    text6: <React.Fragment>Подключение по <br/>радиканалу + антенна и модем</React.Fragment>,
    labelProps: label40Data,
};

const label41Data = {
    children: "10 000 тг",
    className: "label-22",
};

const frame152Data = {
    text6: <React.Fragment>Подключение по <br/>оптической линии</React.Fragment>,
    className: "frame-16",
    labelProps: label41Data,
};

const frame17Data = {
    frame151Props: frame151Data,
    frame152Props: frame152Data,
};

const button232Data = {
    children: t('tariffs.text8'),
    className: "button-23",
};

const cardBottom1Data = {
    text8: "до 20 000 ₸/мес",
    buttonProps: button232Data,
};

const cardOldData = {
    text192: "Жанаозен",
    text193: "Тарифные планы на предоставление доступа к сети интернет для Жанаозен по радио каналу",
    icon32Speedometer031: "/img/icon-24-chevron-left-1@2x.png",
    text194: "до 50 Мбит/с",
    frame17Props: frame17Data,
    cardBottomProps: cardBottom1Data,
};

const label45Data = {
    children: "10 000 тг",
    className: "label-23",
};

const frame1522Data = {
    labelProps: label45Data,
};

const button233Data = {
    children: t('tariffs.text8'),
    className: "button-24",
};

const cardBottom2Data = {
    text8: "6 000 ₸/мес",
    buttonProps: button233Data,
};

const cardOld2Data = {
    frame152Props: frame1522Data,
    cardBottomProps: cardBottom2Data,
};

const label46Data = {
    children: "БЕСПЛАТНО",
    className: "label-24",
};

const frame153Data = {
    labelProps: label46Data,
};

const button234Data = {
    children: t('tariffs.text8'),
    className: "button-25",
};

const cardBottom4Data = {
    text8: "5000 ₸/мес",
    buttonProps: button234Data,
};

const cardOld3Data = {
    frame153Props: frame153Data,
    cardBottomProps: cardBottom4Data,
};

const button314Data = {
    children: t('tariffs.text11'),
    className: "button-29",
};

const service4Data = {
    text31: <React.Fragment>Высокоскоростной <br/>Интернет</React.Fragment>,
    text32: "Доступ в сеть Интернет по волоконно-оптическим линиям связи на скоростях до 1000 Мбит/сек.",
};

const service5Data = {
    text31: t('service.text15'),
    text32: t('service.text5'),
};

const service6Data = {
    text31: t('service.text6'),
    text32: t('service.text7'),
};

const services62Data = {
    className: "services-7",
    service1Props: service4Data,
    service2Props: service5Data,
    service3Props: service6Data,
};

const menuName67Data = {
    children: t('menu.title1'),
};

const menuName68Data = {
    children: t('menu.title2'),
};

const menuName69Data = {
    children: t('menu.title3'),
};

const menuName70Data = {
    children: t('menu.title4'),
};

const menuName71Data = {
    children: t('menu.title5'),
    className: "menu-name-13",
};

const menuName72Data = {
    children: t('menu.title6'),
};

const menu12Data = {
    menuName1Props: menuName67Data,
    menuName2Props: menuName68Data,
    menuName3Props: menuName69Data,
    menuName4Props: menuName70Data,
    menuName5Props: menuName71Data,
    menuName6Props: menuName72Data,
};

const button712Data = {
    children: t('header.text1'),
};

const button235Data = {
    children: t('header.text2'),
};

const cTAButtons12Data = {
    button7Props: button712Data,
    button2Props: button235Data,
};

const headerContent12Data = {
    menuName3Props: menu12Data,
    menuName6Props: cTAButtons12Data,
};

const header12Data = {
    className: "header-2",
    headerContentProps: headerContent12Data,
};

const map2Data = {
    src: "/img/icon-24-chevron-left-1@2x.png",
    className: "map-1",
};

const input6Data = {
    className: "input-2",
};

const input27Data = {
    children: t('checkaddress.text4'),
};

const input28Data = {
    children: t('checkaddress.text5'),
    className: "input-11",
};

const input29Data = {
    children: t('checkaddress.text6'),
    className: "input-12",
};

const button45Data = {
    children: t('checkaddress.text7'),
};

const checkbox4Data = {
    className: "checkbox-3",
};

const checkConnection2Data = {
    className: "check-connection-1",
    inputProps22: map2Data,
    inputProps222: input6Data,
    input21Props: input27Data,
    input22Props: input28Data,
    input23Props: input29Data,
    button4Props: button45Data,
    button4Props2: checkbox4Data,
};

const button63Data = {
    className: "button-48",
};

const imagesNews32Data = {
    src: "/img/icon-24-chevron-left-1@2x.png",
    className: "images_news-9",
};

const content17Data = {
    text21: "Сегодня в 13:58",
    text22: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
};

const imagesNews33Data = {
    src: "/img/icon-24-chevron-left-1@2x.png",
    className: "images_news-10",
};

const content18Data = {
    text21: "Сегодня в 17:23",
    text22: "Сдана в эксплуатацию сеть в квартале 22, с. Мангышлак",
};

const imagesNews34Data = {
    src: "/img/icon-24-chevron-left-1@2x.png",
    className: "images_news-11",
};

const content19Data = {
    text21: "19.07.2022 в 09:35",
    text22: "Жилой массив Туган ел сдан в эксплуатацию",
};

const imagesNews35Data = {
    src: "/img/icon-24-chevron-left-1@2x.png",
    className: "images_news-12",
};

const content20Data = {
    text21: "02.07.2022 в 12:02",
    text22: "Полностью готова к эксплуатации сеть в ж/к Family Town",
};

const cardNews232Data = {
    content1Props5: imagesNews32Data,
    content1Props2: content17Data,
    content1Props52: imagesNews33Data,
    content2Props: content18Data,
    content1Props53: imagesNews34Data,
    content3Props: content19Data,
    content1Props54: imagesNews35Data,
    content4Props: content20Data,
};

const input36Data = {
    children: "Ваше имя",
};

const input37Data = {
    children: "Телефон",
};

const namePhone2Data = {
    input31Props: input36Data,
    input32Props: input37Data,
};

const input43Data = {
    className: "input-18",
};

const input38Data = {
    children: t('connectservice.text3'),
};

const checkbox5Data = {
    className: "checkbox-4",
};

const button46Data = {
    children: t('connectservice.text5'),
    className: "button-44",
};

const requestPopupEnabledData = {
    icon24ChevronLeft1: "/img/icon-24-chevron-left-1@2x.png",
    icon24ChevronLeft2: "/img/icon-24-chevron-left-1@2x.png",
    text379: t("mainpage.text1"),
    popup_Request: "/img/popup-request@1x.png",
    text397: t('menu.title4'),
    mainBgProps: mainBg2Data,
    checkbox31Props: checkbox32Data,
    checkbox32Props: checkbox33Data,
    checkbox33Props: checkbox34Data,
    cardOldProps: cardOldData,
    cardOld2Props: cardOld2Data,
    cardOld3Props: cardOld3Data,
    button3Props: button314Data,
    services6Props: services62Data,
    headerProps: header12Data,
    checkConnectionProps: checkConnection2Data,
    button6Props: button63Data,
    cardNews232Props: cardNews232Data,
    namePhoneProps: namePhone2Data,
    input4Props: input43Data,
    input3Props: input38Data,
    checkboxProps: checkbox5Data,
    button4Props: button46Data,
};

const button315Data = {
    children: t('footer.text5'),
};

const button6212Data = {
    children: t('footer.text6'),
};

const frame313Data = {
    button32Props: button315Data,
    button6Props: button6212Data,
};

const frame2936Data = {
    className: "frame-31-11",
};

const frame3212Data = {
    frame29Props: frame2936Data,
};

const footer3Data = {
    text439: t('footer.text7'),
    text443: t('menu.title4'),
    text444: "Блог",
    text445: t('menu.title6'),
    frame3Props: frame313Data,
    frame32Props: frame3212Data,
};

const input39Data = {
    children: t('connectservice.text7'),
};

const input310Data = {
    children: "Телефон",
};

const button47Data = {
    children: t('tariffs.text8'),
    className: "button-45",
};

const request2Data = {
    text363: "Заявка на подключение",
    input31Props: input39Data,
    input32Props: input310Data,
    button4Props: button47Data,
};

const map3Data = {
    src: "/img/image-16@2x.png",
    className: "",
};

const input7Data = {
    className: "",
};

const input210Data = {
    children: t('checkaddress.text4'),
};

const input211Data = {
    children: t('checkaddress.text5'),
    className: "input-13",
};

const input212Data = {
    children: t('checkaddress.text6'),
    className: "input-14",
};

const button48Data = {
    children: t('checkaddress.text7'),
};

const checkbox7Data = {
    className: "",
};

const checkConnection3Data = {
    className: "check-connection-2",
    inputProps22: map3Data,
    inputProps222: input7Data,
    input21Props: input210Data,
    input22Props: input211Data,
    input23Props: input212Data,
    button4Props: button48Data,
    button4Props2: checkbox7Data,
};

const imagesNews36Data = {
    src: "/img/image-17@2x.png",
    className: "",
};

const content21Data = {
    text21: "Сегодня в 13:58",
    text22: "Жилой массив Рахат - 2 (г.Жанаозен) сдан в эксплуатацию",
};

const cardNews2217Data = {
    imagesNewsProps: imagesNews36Data,
    imagesNewsProps2: content21Data,
};

const imagesNews37Data = {
    src: "/img/image-18@2x.png",
    className: "images_news-1",
};

const content23Data = {
    text21: "Сегодня в 17:23",
    text22: "Сдана в эксплуатацию сеть в квартале 22, с. Мангышлак",
};

const cardNews2218Data = {
    imagesNewsProps: imagesNews37Data,
    imagesNewsProps2: content23Data,
};

const imagesNews38Data = {
    src: "/img/image-19@2x.png",
    className: "images_news-1",
};

const content24Data = {
    text21: "19.07.2022 в 09:35",
    text22: "Жилой массив Туган ел сдан в эксплуатацию",
};

const cardNews2219Data = {
    imagesNewsProps: imagesNews38Data,
    imagesNewsProps2: content24Data,
};

const imagesNews39Data = {
    src: "/img/image-20@2x.png",
    className: "images_news-1",
};

const content25Data = {
    text21: "02.07.2022 в 12:02",
    text22: "Полностью готова к эксплуатации сеть в ж/к Family Town",
};

const cardNews2220Data = {
    imagesNewsProps: imagesNews39Data,
    imagesNewsProps2: content25Data,
};

const cardNews245Data = {
    cardNews221Props: cardNews2217Data,
    cardNews222Props: cardNews2218Data,
    cardNews223Props: cardNews2219Data,
    cardNews224Props: cardNews2220Data,
};

const breadcrumbs25Data = {
    x1: "Страница 1",
    x2: "Страница 2",
    x3: "Страница 3",
    className: "breadcrumbs-10",
};

const button6213Data = {
    children: t('footer.text6'),
    className: "button-34",
};

const button316Data = {
    children: t('footer.text5'),
    className: "button-30",
};

const frame2939Data = {
    className: "frame-31-12",
};

const frame3213Data = {
    frame29Props: frame2939Data,
};

const footerMobileData = {
    button6Props: button6213Data,
    button32Props: button316Data,
    frame32Props: frame3213Data,
};

const language13Data = {
    className: "language-3",
};

const headerMobileData = {
    sas_0606MailRu: "sas_0606@mail.ru",
};

const organismsData = {
    footerDesktop: "Footer Desktop",
    text455: "Необходимо указать ссылку на инстаграм",
    httpsWwwInstagra: "https://www.instagram.com/sastelecom.kz/",
    popups: "Popups",
    popup_Request1: "/img/popup-request@1x.png",
    popup_Request2: "/img/popup-request-1@1x.png",
    checkCoverage: "Check coverage",
    news: "News",
    services: "Services",
    breadcrumb: "Breadcrumb",
    text454: "Грамоты",
    footerMobile: "Footer Mobile",
    sliders: "Sliders",
    communication: "Communication",
    connectService: "Connect service",
    cardContent: "Card content",
    language1: "Русский",
    icon24Globe: "/img/icon-24-globe@2x.png",
    text417: "Казахский",
    icon24Check12: "/img/icon-24-globe@2x.png",
    language2: "Русский",
    text418: "Казахский",
    headerDesktop: "Header Desktop",
    headerMobile: "Header Mobile",
    cards: "Cards",
    footer3Props: footer3Data,
    request2Props: request2Data,
    checkConnectionProps: checkConnection3Data,
    cardNews24Props: cardNews245Data,
    breadcrumbs2Props: breadcrumbs25Data,
    footerMobileProps: footerMobileData,
    languageProps: language13Data,
    headerMobileProps: headerMobileData,
};

const inputs1Data = {
    inputs: "Inputs",
};

const inputs2Data = {
    inputs: "Buttons",
    className: "buttons",
};

const pagination2217Data = {
    className: "pagination-4",
};

const pagination39Data = {
    children: "1",
};

const pagination310Data = {
    children: "2",
};

const pagination311Data = {
    children: "...",
};

const pagination312Data = {
    children: "12",
};

const pagination2218Data = {
    className: "pagination-5",
};

const pagination4Data = {
    pagination31Props: pagination2217Data,
    pagination31Props2: pagination39Data,
    pagination32Props: pagination310Data,
    pagination33Props: pagination311Data,
    pagination34Props: pagination312Data,
    pagination2Props: pagination2218Data,
};

const atomsMoleculesData = {
    icons: "Icons",
    checkboxes: "Checkboxes",
    radioButtons: "Radio buttons",
    labels: "Labels",
    pagination1: "Pagination",
    tabs1: "Tabs",
    inputs1Props: inputs1Data,
    inputs2Props: inputs2Data,
    paginationProps: pagination4Data,
};


const radioButton1Data = {
    radioText: t('tariffs.text5'),
};

const radioButton3Data = {
    radioText: "Дешевле",
};

const radioButton4Data = {
    radioText: "Дороже",
};

const priceSort1Data = {
    radioButton1Props: radioButton3Data,
    radioButton2Props: radioButton4Data,
};

const radioButton7Data = {
    radioText: "до 5000 ₸/мес",
};

const radioButton8Data = {
    radioText: "от 5000 ₸/мес",
};

const price1Data = {
    radioButton1Props: radioButton7Data,
    radioButton2Props: radioButton8Data,
};

const radioButton9Data = {
    radioText: "Бесплатное подключение",
};

const radioButton10Data = {
    radioText: "Акция",
};

const addServices1Data = {
    radioButton1Props: radioButton9Data,
    radioButton2Props: radioButton10Data,
};
const button8Data = {
    children: t('tariffs.text8'),
    className: "button-6",
};
const cardBottom321Data = {
    address: "5 000 ₸/мес",
    button2Props: button8Data,
};
const card2221Data = {
    technology2Props: cardTop1Data,
    cardBottom3Props: cardBottom321Data,
};


const button12Data = {
    children: t('tariffs.text8'),
    className: "button-10",
};

const cardBottom323Data = {
    address: "6 000 ₸/мес",
    button2Props: button12Data,
};

const card2223Data = {
    technology2Props: cardTop5Data,
    cardBottom3Props: cardBottom323Data,
};

const cardTop6Data = {
    c: "c.Батыр",
    text59: "Тарифный план на предоставление доступа к сети интернет для села Батыр",
    speed3Props: speed9Data,
    technology22Props: technology9Data,
    connection22Props: connection33Data,
};

const cardTop7Data = {
    c: "c.Тущыбек",
    text59: "Тарифный план на предоставление доступа к сети интернет для села Тущыбек",
    speed3Props: speed9Data,
    technology22Props: technology9Data,
    connection22Props: connection33Data,
};

const cardTop8Data = {
    c: "c.Тущыбек",
    text59: "Тарифный план на предоставление доступа к сети интернет для села Тущыбек",
    speed3Props: speed9Data,
    technology22Props: technology9Data,
    connection22Props: connection33Data,
};

const cardBottom324Data = {
    address: "8 000 ₸/мес",
    button2Props: button12Data,
};

const card2224Data = {
    technology2Props: cardTop6Data,
    cardBottom3Props: cardBottom324Data,
};

const cardBottom325Data = {
    address: "6 000 ₸/мес",
    button2Props: button12Data,
};
const card2225Data = {
    technology2Props: cardTop7Data,
    cardBottom3Props: cardBottom325Data,
};

const cardBottom326Data = {
    address: "8 000 ₸/мес",
    button2Props: button12Data,
};
const card2226Data = {
    technology2Props: cardTop8Data,
    cardBottom3Props: cardBottom326Data,
};


const card241Data = {
    speed3Props: speed3Data,
    technology22Props: technology22Data,
    connection3Props: connection3Data,
    cardBottom32Props: cardBottom32Data,
};

const card242Data = {
    className: "card_2-4",
    speed3Props: speed3Data,
    technology22Props: technology22Data,
    connection3Props: connection33Data,
    cardBottom32Props: cardBottom321Data,
};

const x51Data = {
    card222Props: card222Data,
    card251Props: card251Data,
    card252Props: card252Data,
};

const x52Data = {
    card222Props: card222Data,
    card251Props: card253Data,
    card252Props: card252Data,
};

const tariffPageRadioData = {
    text54: "Технология:",
    headerProps: header2Data,
    breadcrumbsProps: breadcrumbs1Data,
    radioButtonProps: radioButton1Data,
    radioButton2Props: radioButton21Data,
    priceSortProps: priceSort1Data,
    speedProps: speed1Data,
    priceProps: price1Data,
    addServicesProps: addServices1Data,
    card2221Props: card2221Data,
    card221Props: card221Data,
    card222Props: card223Data,
    card2222Props: card2222Data,
    card2223Props: card2223Data,
    card2224Props: card2224Data,
    card2225Props: card2225Data,
    card2226Props: card2226Data,
    card231Props: card231Data,
    card232Props: card232Data,
    card241Props: card241Data,
    card242Props: card242Data,
    x51Props: x51Data,
    x52Props: x52Data,
    footerProps: footer41Data,
};

    return (
        <ThemeProvider
            breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'fluid']}
            minBreakpoint="xl"
        >

            <Router>
                <Switch>
                    <Route path="/tariff-page-optics">
                        <TariffPageOptics {...tariffPageOpticsData} />
                    </Route>
                    <Route path="/tariff-page-radio">
                        <TariffPageRadio {...tariffPageOpticsData} />
                    </Route>
                    <Route path="/:path(|main-page)">
                        <MainPage {...mainPageData} />
                    </Route>
                    <Route path="/about-company">
                        <AboutCompany {...aboutCompanyData} />
                    </Route>
                    <Route path="/check-coverage">
                        <CheckCoverage {...checkCoverageData} />
                    </Route>
                    <Route path="/services">
                        <Services5 {...services5Data} />
                    </Route>
                    <Route path="/blog">
                        <Blog {...blogData} />
                    </Route>
                    <Route path="/blog-page-gpon">
                        <BlogPageGPON {...blogPageGPONData} />
                    </Route>
                    <Route path="/blog-page-price-table">
                        <BlogPagePriceTable {...blogPagePriceTableData} />
                    </Route>
                    <Route path="/contacts">
                        <Contacts2 {...contacts22Data} />
                    </Route>
                    <Route path="/news">
                        <News {...newsData} />
                    </Route>
                    <Route path="/news-page">
                        <NewsPage {...newsPageData} />
                    </Route>
                    <Route path="/request-popup-enabled">
                        <RequestPopupEnabled {...requestPopupEnabledData} />
                    </Route>
                    <Route path="/organisms">
                        <Organisms {...organismsData} />
                    </Route>
                    <Route path="/atoms-molecules">
                        <AtomsMolecules {...atomsMoleculesData} />
                    </Route>
                    <Route path="/test">

                    {/*    <News2>*/}


                    {/*    <Heading>*/}
                    {/*        <Text112>{mainPageData.text112}</Text112>*/}
                    {/*        <Button6/>*/}
                    {/*    </Heading>*/}

                    {/*    <CardNews24*/}
                    {/*        cardNews221Props={mainPageData.cardNews24Props.cardNews221Props}*/}
                    {/*        cardNews222Props={mainPageData.cardNews24Props.cardNews222Props}*/}
                    {/*        cardNews223Props={mainPageData.cardNews24Props.cardNews223Props}*/}
                    {/*        cardNews224Props={mainPageData.cardNews24Props.cardNews224Props}*/}
                    {/*    />*/}

                    {/*</News2>*/}
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    );
}

export default App;


