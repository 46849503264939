import React from "react";
import Language from "../Language";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky14px } from "../../styledMixins";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Select() {
  return (
    <Select1>
      <Location>
        <Icon24markerPin011 src="/img/icon-24-marker-pin-01-1@2x.svg" alt="Icon/24/marker-pin-01 1" />
        <Text14>Ақтау</Text14>
      </Location>
      <Language />
    </Select1>
  );
}

const Select1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin011 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text14 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location1 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0111 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text141 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location2 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0112 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text155 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location3 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0113 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text177 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location4 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0114 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text193 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select6 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location5 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0115 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text218 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location6 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0116 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text239 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select8 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location7 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0117 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text253 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location8 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0118 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text286 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select10 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location9 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin0119 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text337 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location10 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin01110 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text374 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

const Select12 = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  border: 1px none;
`;

const Location11 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const Icon24markerPin01111 = styled.img`
  min-width: 20px;
  height: 20px;
`;

const Text415 = styled.div`
  ${IbmplexsansMediumPaleSky14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

export default Select;
