import React from "react";
import Frame72 from "../Frame72";
import Frame8 from "../Frame8";
import styled from "styled-components";
import { IbmplexsansMediumPaleSky16px } from "../../styledMixins";


function Content222(props) {
  const { frame72Props, frame8Props } = props;

  return (
    <Content>
      <Frame5>
        <Text302>Телефон:</Text302>
        <Frame72 className={frame72Props.className}>{frame72Props.children}</Frame72>
      </Frame5>
      <Frame8 x0900={frame8Props.x0900} />
    </Content>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  border: 1px none;
`;

const Frame5 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text302 = styled.div`
  ${IbmplexsansMediumPaleSky16px}
  width: 148px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Content222;
