import React from "react";
import styled from "styled-components";
import { IbmplexsansMediumLoblolly16px } from "../../styledMixins";


function Input4(props) {
  const { className } = props;

  return (
    <Input className={`input-16 ${className || ""}`}>
      <Button className="button-51">
        <Button1 className="button-52">Населенный пункт</Button1>
        <Icon24chevronDown1
          className="icon24chevron-down-1"
          src="/img/icon-24-chevron-down-1@2x.svg"
          alt="Icon/24/chevron-down 1"
        />
      </Button>
    </Input>
  );
}

const Input = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border: 1px none;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 12px 22px;
  align-self: stretch;
  background-color: var(--dommain);
  border-radius: 16px;
  border: 1px solid;
  border-color: var(--contentextralight);
`;

const Button1 = styled.div`
  ${IbmplexsansMediumLoblolly16px}
  flex: 1;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Icon24chevronDown1 = styled.img`
  min-width: 24px;
  height: 24px;
`;

const Icon24chevronDown11 = styled.img`
  .input-16.input-18 & {
    margin-top: -3771px;
    margin-left: -16242px;
  }
`;

export default Input4;
