import React from "react";
import Label from "../Label";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Frame153(props) {
  const { labelProps } = props;

  return (
    <Frame15>
      <Text393>Подключение</Text393>
      <Label className={labelProps.className}>{labelProps.children}</Label>
    </Frame15>
  );
}

const Frame15 = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text393 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default Frame153;
