import React from "react";
import Heading2 from "../Heading2";
import NamePhone from "../NamePhone";
import Input4 from "../Input4";
import Input3 from "../Input3";
import Checkbox from "../Checkbox";
import Button4 from "../Button4";
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
function Request(props) {
    const {namePhoneProps, input3Props, checkboxProps, button4Props} = props;
    const { t, i18n } = useTranslation();
    return (
        <Request1>
            <Heading2/>
            <Inputs>
                <Inputs1>
                    <NamePhone input31Props={namePhoneProps.input31Props} input32Props={namePhoneProps.input32Props}/>

                    <TariffAddress>

                        {/*<Input4/>*/}
                        {/*<Form.Select className="sc-byhhpF jbiyCH" aria-label="Default select example">*/}
                        {/*    <option>Населенный пункт</option>*/}
                        {/*    <option value="1">Актау</option>*/}
                        {/*    <option value="2">Шымкент</option>*/}
                        {/*</Form.Select>*/}
                        <Form.Control className="sc-byhhpF jbiyCH" type="text" placeholder={t('connectservice.text1')}/>
                        <Form.Control className="sc-byhhpF jbiyCH" type="text" placeholder={t('connectservice.text3')}/>

                        {/*<Input3>{input3Props.children}</Input3>*/}
                    </TariffAddress>
                </Inputs1>
            </Inputs>
            <CTA>
                {/*<Checkbox className={checkboxProps.className}/>*/}
                <Form.Check
                    type="checkbox"
                    id="sdfjksdf"
                    className={checkboxProps.className}
                    label={t('connectservice.text4')}
                />
                {/*<Button4 className={button4Props.className}>{button4Props.children}</Button4>*/}
                <Button className="sc-dUipGc eECfEI sc-hsWlPz eOJevg">{button4Props.children}</Button>
            </CTA>
        </Request1>
    );
}

const Request1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  position: relative;
  background-color: var(--domdop);
  border-radius: 24px;
  border: 1px none;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border: 1px none;
`;

const Inputs1 = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  border: 1px none;
  flex-wrap: wrap;
`;

const TariffAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 1;
  border: 1px none;
`;

const CTA = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  align-self: stretch;
  border: 1px none;
  flex-wrap: wrap;
`;

export default Request;

<style>


</style>