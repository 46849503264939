import React from "react";
import Pagination22 from "../Pagination22";
import styled from "styled-components";
import { IbmplexsansNormalTuna16px } from "../../styledMixins";


function Feature2(props) {
  const { text155, className } = props;

  return (
    <Feature className={`feature-3 ${className || ""}`}>
      <Pagination22 />
      <Text196 className="text-196">{text155}</Text196>
    </Feature>
  );
}

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 4px 0px;
  position: relative;
  align-self: stretch;
  border: 1px none;

  &.feature-3.feature-4 {
    //width: 760px;
    margin-bottom: -68px;
    align-self: unset;
  }
`;

const Text196 = styled.p`
  ${IbmplexsansNormalTuna16px}
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
`;

export default Feature2;
