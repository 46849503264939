import React from "react";
import Content22 from "../Content22";
import styled from "styled-components";
import { IbmplexsansSemiBoldTuna16px } from "../../styledMixins";
import {useTranslation} from "react-i18next";

function Contacts() {
  const { t, i18n } = useTranslation();
  return (
    <Contacts1>
      <Text8>{t('footer.text8')}</Text8>
      <Content22 />
    </Contacts1>
  );
}

const Contacts1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text8 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text135 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts3 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text149 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts4 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text171 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts5 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text187 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts6 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text212 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts7 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text233 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts8 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text247 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts9 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text280 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts10 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text331 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts11 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text368 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Contacts12 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  border: 1px none;
`;

const Text446 = styled.div`
  ${IbmplexsansSemiBoldTuna16px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Contacts;
