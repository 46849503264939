import React from "react";
import Frame15 from "../Frame15";
import styled from "styled-components";


function Frame17(props) {
  const { frame151Props, frame152Props } = props;

  return (
    <Frame171>
      <Frame15 text6={frame151Props.text6} labelProps={frame151Props.labelProps} />
      <Frame15 text6={frame152Props.text6} className={frame152Props.className} labelProps={frame152Props.labelProps} />
    </Frame171>
  );
}

const Frame171 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

export default Frame17;
