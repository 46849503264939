import React from "react";
import Label from "../Label";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Technology2(props) {
  const { text10, labelProps } = props;

  return (
    <Technology>
      <Text103>{text10}</Text103>
      <Label className={labelProps.className}>{labelProps.children}</Label>
    </Technology>
  );
}

const Technology = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text103 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 117px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default Technology2;
