import React from "react";
import Frame153 from "../Frame153";
import CardBottom from "../CardBottom";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px, IbmplexsansMediumTuna20px, IbmplexsansBoldTuna24px } from "../../styledMixins";


function CardOld3(props) {
  const { frame153Props, cardBottomProps } = props;

  return (
    <CardOld>
      <CardTop>
        <Text390>Атамекен, Коктем, Туган ел, Арман, Автодром</Text390>
        <Text391>
          Надежный доступа к сети интернет для жилых массивов Атамекен, Каламкас, Коктем, Туган ел, Арман.
        </Text391>
        <Speed>
          <Icon32speedometer031 src="/img/icon-24-chevron-left-1@2x.png" alt="Icon/32/speedometer-03 1" />
          <Text392>до 500 Мбит/с</Text392>
        </Speed>
        <Frame153 labelProps={frame153Props.labelProps} />
      </CardTop>
      <CardBottom text8={cardBottomProps.text8} buttonProps={cardBottomProps.buttonProps} />
    </CardOld>
  );
}

const CardOld = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  position: relative;
  border: 1px none;
  box-shadow: 0px 4px 10px #2b36421f;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 24px 12px;
  position: relative;
  background-color: var(--domextralight);
  border-radius: 24px 24px 0px 0px;
  overflow: hidden;
  border: 1px none;
`;

const Text390 = styled.p`
  ${IbmplexsansBoldTuna24px}
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
`;

const Text391 = styled.p`
  ${IbmplexsansNormalTuna14px}
  align-self: stretch;
  letter-spacing: 0;
  line-height: 18px;
`;

const Speed = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 11px;
  align-self: stretch;
  background-color: var(--contentextralight);
  border-radius: 16px;
  border: 1px none;
`;

const Icon32speedometer031 = styled.img`
  min-width: 26px;
  height: 26px;
  margin-top: -2504px;
  margin-left: -16928px;
`;

const Text392 = styled.div`
  ${IbmplexsansMediumTuna20px}
  width: 231px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default CardOld3;
