import React from "react";
import styled from "styled-components";
import { IbmplexsansNormalTuna16px, IbmplexsansBoldTuna24px } from "../../styledMixins";
import {useTranslation} from "react-i18next";

function Heading4() {
  const { t, i18n } = useTranslation();
  return (
    <Heading>
      <Text110>{t('checkaddress.text1')}</Text110>
      <Text111>{t('checkaddress.text2')}</Text111>
    </Heading>
  );
}

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text110 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  //white-space: nowrap;
`;

const Text111 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  //white-space: nowrap;
`;

const Heading1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text395 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text396 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Heading2 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  border: 1px none;
`;

const Text430 = styled.div`
  ${IbmplexsansBoldTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Text431 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Heading4;
