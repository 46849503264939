import React from "react";
import Label from "../Label";
import styled from "styled-components";
import { IbmplexsansNormalTuna14px } from "../../styledMixins";


function Connection3(props) {
  const { labelProps } = props;

  return (
    <Connection>
      <AdditionalService>
        <Text68>Подключение</Text68>
        <Label className={labelProps.className}>{labelProps.children}</Label>
      </AdditionalService>
    </Connection>
  );
}

const Connection = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  border: 1px none;
`;

const AdditionalService = styled.div`
  display: flex;
  width: 289px;
  align-items: center;
  gap: 8px;
  position: relative;
  border: 1px none;
`;

const Text68 = styled.div`
  ${IbmplexsansNormalTuna14px}
  width: 223px;
  letter-spacing: 0;
  line-height: 18px;
  white-space: nowrap;
`;

export default Connection3;
