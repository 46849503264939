import React from "react";
import Header from "../Header";
import Breadcrumbs2 from "../Breadcrumbs2";
import Row from "../Row";
import Footer4 from "../Footer4";
import styled from "styled-components";
import {
  IbmplexsansBoldTuna34px,
  IbmplexsansMediumTuna24px,
  IbmplexsansNormalPaleSky14px,
  IbmplexsansMediumWhite16px,
  IbmplexsansNormalTuna16px,
} from "../../styledMixins";
import "./BlogPagePriceTable.css";

function BlogPagePriceTable(props) {
  const {
    text254,
    text255,
    text256,
    spanText1,
    spanText2,
    spanText3,
    text270,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    headerProps,
    breadcrumbs2Props,
    row1Props,
    row2Props,
    row3Props,
    row4Props,
    row5Props,
    row6Props,
    row7Props,
    row8Props,
    row9Props,
    row10Props,
    row11Props,
    row12Props,
    row13Props,
    footer4Props,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="blog-page-price-table screen">
        <Header headerContentProps={headerProps.headerContentProps} />
        <Breadcrumbs2
          x1={breadcrumbs2Props.x1}
          x2={breadcrumbs2Props.x2}
          x3={breadcrumbs2Props.x3}
          className={breadcrumbs2Props.className}
        />
        <Content>
          <Text254>{text254}</Text254>
          <Content1>
            <Content1>
              <Text255>{text255}</Text255>
              <TablePrice>
                <FlexCol>
                  <Text256>{text256}</Text256>
                  <Table>
                    <Row text253={row1Props.text253} text254={row1Props.text254} text255={row1Props.text255} />
                    <Divier></Divier>
                    <Row
                      text253={row2Props.text253}
                      text254={row2Props.text254}
                      text255={row2Props.text255}
                      className={row2Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row3Props.text253}
                      text254={row3Props.text254}
                      text255={row3Props.text255}
                      className={row3Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row4Props.text253}
                      text254={row4Props.text254}
                      text255={row4Props.text255}
                      className={row4Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row5Props.text253}
                      text254={row5Props.text254}
                      text255={row5Props.text255}
                      className={row5Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row6Props.text253}
                      text254={row6Props.text254}
                      text255={row6Props.text255}
                      className={row6Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row7Props.text253}
                      text254={row7Props.text254}
                      text255={row7Props.text255}
                      className={row7Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row8Props.text253}
                      text254={row8Props.text254}
                      text255={row8Props.text255}
                      className={row8Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row9Props.text253}
                      text254={row9Props.text254}
                      text255={row9Props.text255}
                      className={row9Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row10Props.text253}
                      text254={row10Props.text254}
                      text255={row10Props.text255}
                      className={row10Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row11Props.text253}
                      text254={row11Props.text254}
                      text255={row11Props.text255}
                      className={row11Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row12Props.text253}
                      text254={row12Props.text254}
                      text255={row12Props.text255}
                      className={row12Props.className}
                    />
                    <Divier></Divier>
                    <Row
                      text253={row13Props.text253}
                      text254={row13Props.text254}
                      text255={row13Props.text255}
                      className={row13Props.className}
                    />
                    <Divier></Divier>
                  </Table>
                  <Text269>
                    <Span07>{spanText1}</Span07>
                    <Span17>{spanText2}</Span17>
                    <Span07>{spanText3}</Span07>
                  </Text269>
                </FlexCol>
              </TablePrice>
            </Content1>
            <TextContent>
              <RequestPhones>
                <Text270>{text270}</Text270>
                <Content2>
                  <Text271>
                    <span className="ibmplexsans-medium-pale-sky-16px">{spanText4}</span>
                    <span className="ibmplexsans-medium-tuna-16px">{spanText5}</span>
                  </Text271>
                  <Phone>
                    <span className="ibmplexsans-medium-pale-sky-16px">{spanText6}</span>
                    <span className="ibmplexsans-medium-tuna-16px">{spanText7}</span>
                  </Phone>
                  <Phone>
                    <span className="ibmplexsans-medium-pale-sky-16px">{spanText8}</span>
                    <span className="ibmplexsans-medium-tuna-16px">{spanText9}</span>
                  </Phone>
                  <Phone>
                    <span className="ibmplexsans-medium-pale-sky-16px">{spanText10}</span>
                    <span className="ibmplexsans-medium-tuna-16px">{spanText11}</span>
                  </Phone>
                </Content2>
              </RequestPhones>
              <Text272>
                <span className="ibmplexsans-normal-tuna-16px">{spanText12}</span>
                <Span112>{spanText13}</Span112>
                <span className="ibmplexsans-normal-tuna-16px">{spanText14}</span>
              </Text272>
            </TextContent>
          </Content1>
        </Content>
        <Footer4
          className={footer4Props.className}
          button32Props={footer4Props.button32Props}
          button32Props2={footer4Props.button32Props2}
        />
      </div>
    </div>
  );
}

const Content = styled.div`
  display: flex;
  margin-top: 36px;
  margin-left: 144px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text254 = styled.div`
  ${IbmplexsansBoldTuna34px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 42px;
  white-space: nowrap;
`;

const Content1 = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 24px;
  border: 1px none;
`;

const Text255 = styled.p`
  ${IbmplexsansNormalTuna16px}
  width: 662px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const TablePrice = styled.div`
  min-width: 743px;
  height: 614px;
  border: 1px none;
`;

const FlexCol = styled.div`
  position: relative;
  width: 743px;
  top: -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 614px;
`;

const Text256 = styled.div`
  ${IbmplexsansMediumTuna24px}
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Table = styled.div`
  display: flex;
  position: relative;
  margin-top: 18px;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  border: 1px none;
`;

const Divier = styled.div`
  align-self: stretch;
  min-width: 524px;
  height: 1px;
  background-color: var(--domextralight);
  border: 1px none;
`;

const Text269 = styled.p`
  ${IbmplexsansNormalPaleSky14px}
  min-height: 18px;
  margin-top: 15px;
  letter-spacing: 0;
  line-height: 14px;
`;

const Span07 = styled.span`
  ${IbmplexsansNormalPaleSky14px}
  line-height: 18px;
`;

const Span17 = styled.span`
  font-family: var(--font-family-ibm_plex_sans);
  font-weight: 700;
  color: var(--contentsecondary);
  font-size: var(--font-size-s);
`;

const TextContent = styled.div`
  position: relative;
  min-width: 713px;
  height: 327px;
  border: 1px none;
`;

const RequestPhones = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px none;
`;

const Text270 = styled.p`
  ${IbmplexsansMediumTuna24px}
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
`;

const Content2 = styled.div`
  ${IbmplexsansMediumWhite16px}
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  gap: 8px;
  border: 1px none;
`;

const Text271 = styled.p`
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Phone = styled.p`
  width: fit-content;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Text272 = styled.p`
  ${IbmplexsansNormalTuna16px}
  position: absolute;
  width: 713px;
  top: 186px;
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
`;

const Span112 = styled.span`
  font-family: var(--font-family-ibm_plex_sans);
  font-weight: 700;
  color: var(--contentprimary);
  font-size: var(--font-size-m);
`;

export default BlogPagePriceTable;
